import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import SettingsSideMenu from './components/SettingsSideMenu'
import useWindowSize from '../../Hooks/useWindowSize';
import { Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
const SettingsLayout = () => {
	const size = useWindowSize();

	return (
		<>
			{/* ======================  Meta Tags ======================  */}
			<Helmet>
				<title>autoaudit.ai &#10072; Settings Page </title>
			</Helmet>
			{/* ======================  Meta Tags ======================  */}
			<div className="settingsHeader">Settings</div>
			<div className="settingsParti"
			>
				<SettingsSideMenu />

				<div className="settingsOptions" style={{ height: '100%' }}

				>
					<Outlet />
				</div>
			</div>
		</>
	)
}

export default SettingsLayout