import React from "react";

export default function ModalHeader({ header, onClick }) {
  return (
    <div className="modal_header">
      <div className="modal_head">
        <span className="modal_title">{header}</span>
      </div>
      {  (
        <div className="modal_close" onClick={onClick}>
          <span className="close_button">&times;</span>
        </div>
      )}
    </div>
  );
}
