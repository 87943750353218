import React from "react";
import Bg from "../../ErrorPage/add_clients.svg";

export default function EmptyMemberList({ toggler }) {
  return (
    <section id="empty_member" className="empty_list">
      <div className="empty_content">
        <div>
          <img
            className="empty_content_pict"
            src={Bg}
            alt="404 Error"
            title="Add New Clients"
          />
        </div>
      </div>
    </section>
  );
}
