import React from "react";

function ClientIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.09036 22.3978L6.10959 21.5731L6.09125 21.5726L6.07291 21.573L6.09036 22.3978ZM6.57566 14.1702L6.46207 14.9874L7.2072 15.0909L7.37888 14.3585L6.57566 14.1702ZM16.9689 14.1335L16.167 14.3275L16.314 14.935L16.9386 14.9579L16.9689 14.1335ZM16.7338 22.398L16.7496 21.5732L16.7336 21.5729L16.7175 21.5732L16.7338 22.398ZM6.07291 21.573C6.04911 21.5735 6.02522 21.5738 6.00127 21.5738V23.2238C6.03685 23.2238 6.07237 23.2234 6.10781 23.2227L6.07291 21.573ZM6.00127 21.5738C4.17371 21.5738 2.69219 20.0923 2.69219 18.2647H1.04219C1.04219 21.0035 3.26244 23.2238 6.00127 23.2238V21.5738ZM2.69219 18.2647C2.69219 16.4372 4.17371 14.9556 6.00127 14.9556V13.3056C3.26244 13.3056 1.04219 15.5259 1.04219 18.2647H2.69219ZM6.00127 14.9556C6.15807 14.9556 6.31187 14.9665 6.46207 14.9874L6.68924 13.3531C6.46399 13.3218 6.23428 13.3056 6.00127 13.3056V14.9556ZM7.37888 14.3585C7.84671 12.3627 9.63921 10.8772 11.7766 10.8772V9.22725C8.85588 9.22725 6.41119 11.257 5.77243 13.9819L7.37888 14.3585ZM11.7766 10.8772C13.9029 10.8772 15.6879 12.3473 16.167 14.3275L17.7707 13.9395C17.1167 11.236 14.6823 9.22725 11.7766 9.22725V10.8772ZM16.9386 14.9579C18.7083 15.0229 20.1236 16.4787 20.1236 18.2647H21.7736C21.7736 15.5877 19.6527 13.4064 16.9991 13.309L16.9386 14.9579ZM20.1236 18.2647C20.1236 20.0923 18.642 21.5738 16.8145 21.5738V23.2238C19.5533 23.2238 21.7736 21.0035 21.7736 18.2647H20.1236ZM16.8145 21.5738C16.7928 21.5738 16.7711 21.5736 16.7496 21.5732L16.718 23.2229C16.7501 23.2235 16.7822 23.2238 16.8145 23.2238V21.5738ZM16.7175 21.5732C16.7087 21.5733 16.6999 21.5734 16.691 21.5734V23.2234C16.7107 23.2234 16.7304 23.2232 16.75 23.2229L16.7175 21.5732ZM16.691 21.5734H6.14095V23.2234H16.691V21.5734ZM6.14095 21.5734C6.13046 21.5734 6.12001 21.5733 6.10959 21.5731L6.07114 23.2226C6.09435 23.2232 6.11762 23.2234 6.14095 23.2234V21.5734Z"
            fill="currentColor"
          />
          <path
            d="M17.3516 3.51196C20.9276 4.01156 23.5244 6.67822 23.9473 10.2651"
            stroke="currentColor"
            strokeWidth="1.60243"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.4336 6.03491C19.0576 6.39923 20.965 8.35999 21.2751 10.9919"
            stroke="currentColor"
            strokeWidth="1.60243"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2305 8.94727C17.5385 9.13326 18.4962 10.1059 18.6512 11.4258"
            stroke="currentColor"
            strokeWidth="1.60243"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default ClientIcon;
