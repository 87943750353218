import React from "react";
import { funcDate, funcTime } from "../../../util/helper";

const EmailDetailedView = ({ size, another }) => {
	// with resize event listener
	return (
		<div className="mailDisplay">
			<div className="displayEmail">
				<div>
					<div>
						<div className="DisplayEmailTitle">
							<div className="displayPic">
								<p className="profilePic">{another?.from?.charAt(0)}</p>
							</div>
							<div className="emailreceiveDetails">
								<div className="mailDetails">
									<div className="mailSenderName">
										<p className="mail_from">{another?.from}</p>
										<p className="mail_to">To : {another?.to}</p>
									</div>
									<div className="mailSenderTime">
										<p className="mail_to"> {funcDate(another?.date)} </p>
										<p className="mail_to">{funcTime(another?.date)} </p>
									</div>
								</div>
							</div>
						</div>

						<div className="EmailSubject">
							<p className="email_sub">{another?.subject}</p>
						</div>

						<div className="emailContent">
							<div className="disMsg">
								<div
									dangerouslySetInnerHTML={{
										__html: another?.html,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailDetailedView;
