import React, { useLayoutEffect } from 'react'
import Modal from '../../../components/Modal'
import ModalHeader from '../../../components/ModalHeader'
import ModalBody from '../../../components/ModalBody'
import { useGenerateTFACreds } from '../../../Hooks/tanqueryHooks'
import { useSelector } from 'react-redux'
import Loader from '../../../components/Loader'

const TFAModal = ({ cancelModal, confirmBox, setConfirmBox, toggleMutate, isTogglePending }) => {
    const { token } = useSelector(state => state.auth);
    const { mutate, isPending, isSuccess, isError, data } = useGenerateTFACreds();
    useLayoutEffect(() => {
        mutate({ token });
    }, [mutate, token])

    const handleConfirm = () => {
        // console.log("handleConfirm triggered");
        toggleMutate({ token, data: { status: true } }, { onSuccess: handleSuccessOnConfirm })

    }
    const handleSuccessOnConfirm = () => {
        setConfirmBox(false);
        cancelModal();
    }
    if (isPending) {
        return <Loader />
    }
    if (isSuccess) {

        const { url, key } = data;
        return (

            <Modal>
                <ModalHeader
                    header="Enable Two Factor Authentication"
                    onClick={cancelModal}
                />
                <ModalBody modal_grid="client_body_one">
                    {isTogglePending && <Loader />}
                    <div className="user_content_here">
                        <div className="autoGenerate">
                            <div className="butGenerate">
                            </div>

                            <div className="secretKey">
                                <p className="pTag">Secret Key : </p>
                                {isPending && <p className="pTagContent">Loading....</p>}
                                {isSuccess && <p className="pTagContent">{key}</p>}
                                {isError && <p className="pTagContent">Error...</p>}
                            </div>
                            <div className="qrKey">
                                <div className={"url"}>
                                    <img src={url} alt="url" />
                                </div>
                            </div>
                        </div>
                        <div className="user_instruction">
                            <div>
                                <p className="textPassCri">TFA Instructions : </p>
                                <ul className="instruc">
                                    <li>
                                        If TF Authentication is enabled, you have two options:
                                    </li>
                                    <li>
                                        Utilize the Authentication extension in your browser.
                                    </li>
                                    <li>Use any mobile Authenticator App. </li>
                                    <li>
                                        Your browser extension or mobile Authenticator app will
                                        provide a 6-digit Secret key. This 6-digit key is used
                                        after login.
                                    </li>
                                </ul>
                                <br></br>
                            </div>
                        </div>
                    </div>
                    <div className="confirm_box">
                        <label className={!confirmBox ? 'box_pick' : 'box_pick box_picks'} htmlFor="two_factor">
                            <input
                                type="checkbox"
                                id="two_factor"
                                checked={confirmBox}
                                onChange={() => setConfirmBox(!confirmBox)}
                            />

                            <span className="box_title">
                                Did you scan the QR Code or copy the Secret Key?
                            </span>
                        </label>
                    </div>


                    <div className="modal_footer right_side">
                        <div className="footer_align">
                            {

                                (
                                    <button
                                        type="submit"
                                        className={`${!confirmBox
                                            ? "modal_btn btn_confirm confirmBox"
                                            : "modal_btn btn_confirm"
                                            }`}
                                        onClick={handleConfirm}
                                        disabled={!confirmBox}
                                    >
                                        {" "}
                                        Confirm{" "}
                                    </button>
                                )}

                            <button
                                onClick={cancelModal}
                                type="button"
                                className="modal_btn btn_close"
                            >
                                {" "}
                                Cancel{" "}
                            </button>
                        </div>
                    </div>{" "}
                </ModalBody>
            </Modal>
        )
    }
}

export default TFAModal