import { React, useEffect, useRef, useState } from "react";
import { BiBell, BiLogOutCircle } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import CustomAvatar from "../components/CustomAvatar";
import { setLogout } from "../slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import logo from "../asset/logo/AutoauditDark.svg";
import $ from "jquery";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { useLogger } from "../Hooks/tanqueryHooks";
import EllipsisLoader from "../components/EllipsisLoader";
function Headerbar() {
	const navigate = useNavigate();
	const [logOutMenu, setLogoutMenu] = useState(false);
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);
	// eslint-disable-next-line
	const [arraySet, setArraySet] = useState([]);
	const [temp, setTemp] = useState(false);
	const { members } = useSelector((state) => state.members);
	const { mutate, isPending } = useLogger();
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (!$(event.target).closest(".header_login").length) {
				setLogoutMenu(false);
			}
		};

		$(document).on("click", handleClickOutside);

		return () => {
			$(document).off("click", handleClickOutside);
		};
		// eslint-disable-next-line
	}, []);

	const handleSuccess = () => {
		dispatch(setLogout());
		navigate("/");
	}
	const onLogout = () => {
		mutate({ token: authState.token, data: { activity: "User Logout", skiplog: true } }, {
			onSuccess: handleSuccess
		})
		// apiCaller({
		// 	url: "logger",
		// 	data: { activity: "User Logout", skiplog: true },
		// 	token: authState.token,
		// }).catch(() => {
		// 	console.log("logger error");
		// });

	};


	// let inactivityTime = function () {
	// 	let time;
	// 	window.onload = resetTimer;
	// 	document.onmousemove = resetTimer;
	// 	document.onkeypress = resetTimer;
	// 	function resetTimer() {
	// 		clearTimeout(time);
	// 		time = setTimeout(onLogout, 900000);
	// 	}
	// };
	// window.onload = function () {
	// 	inactivityTime();
	// };

	useEffect(() => {
		if (members.length > 0) {
			if (temp === false) {
				setTemp(true);
				setArraySet(members);
			}
		}
		// eslint-disable-next-line
	}, [members]);

	const callNavigate = () => {
		authState.role === "superadmin"
			? navigate("/dashboard/adminList", { activity: "Home page" })
			: navigate("/dashboard/clientList", { activity: "Home page" });

		// window.location.reload(true);
	};

	return (
		<>
			<div className="header_bar">
				<div className="padd_set">
					<div onClick={callNavigate} className="header_logo">
						<p className="logo_name">
							<img
								style={{ width: 190 }}
								src={logo}
								className="logo"
								alt="autoaudit.ai"
								title="autoaudit.ai"
							/>{" "}
						</p>
					</div>

					<div className="header_login">
						<div className="login_name">
							<div className="notify_sec" style={{ visibility: "hidden" }}>
								<span className="unseen"></span>
								<BiBell className="notify" />
							</div>{" "}
							<button
								tabIndex="0"
								className="user_login_menu"
								onClick={() => setLogoutMenu((prev) => !prev)}
							>
								<CustomAvatar name={authState.name} />
							</button>
							<div
								className={
									logOutMenu === true
										? "login_settings login--active"
										: "login_settings"
								}
							>
								<ul className="login_list">
									<li tabIndex="0">
										<div className="user_1">
											{/* <span className="sclid"></span> */}
											<div className="icons_list">
												<AiOutlineUser className="login_icons" />
											</div>
											<div className="user_icon">
												<p className="login_welcome"></p>
												<p className="login_user_name">{authState.name}</p>
											</div>
										</div>
									</li>
									<li
										role="button"
										aria-pressed="true"
										onClick={onLogout}
									>
										<div className="user_1">
											<div className="icons_list">
												<BiLogOutCircle className="login_icons" />
											</div>
											{/* <div className="user_icon">
												<p className="logout" onClick={onLogout}></p>
											</div> */}
											{/* <button
												className="remove_btn_default user_icon"
												onClick={onLogout}
											>
												<span className="logout">Logout</span>
											</button> */}
											<button
												tabIndex="0"
												className="remove_btn_default user_icon logout"
												disabled={isPending}
											>
												Logout
											</button>
											{
												isPending && <EllipsisLoader />
											}

										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Headerbar;
