import React from "react";

function ClientIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.8967 20.6463C18.8967 17.6162 15.8835 15.1584 12.1632 15.1584C8.44293 15.1584 5.42969 17.6162 5.42969 20.6463"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.9379 11.8112C14.2023 12.5468 13.2047 12.96 12.1645 12.96C11.1242 12.96 10.1266 12.5468 9.39099 11.8112C8.65542 11.0756 8.24219 10.078 8.24219 9.03774C8.24219 7.99749 8.65542 6.99985 9.39099 6.26428C10.1266 5.52872 11.1242 5.11548 12.1645 5.11548C13.2047 5.11548 14.2023 5.52872 14.9379 6.26428C15.6735 6.99985 16.0867 7.99749 16.0867 9.03774C16.0867 10.078 15.6735 11.0756 14.9379 11.8112Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default ClientIcon;
