export const mpinverified = {
	title: "Success!",
	text: "M-Pin verified successfully",
	icon: "success",
	button: false,
	timer: 2000,
};
export const mpinverifyFailed = {
	title: "Alert!",
	text: "Please Enter a valid M-PIN",
	icon: "info",
	button: false,
	timer: 2000,
};
export const clientAdded = {
	title: "Success!",
	text: "Client added successfully! \n App link has been sent to your client's email ID.",
	icon: "success",
	button: false,
	timer: 2000,
};
export const clientAddedFailed = {
	title: "Alert!",
	text: "Phone number or email is already linked to an existing Admin account",
	icon: "info",
	button: false,
	timer: 2000,
};
export const mblNotSync = {
	title: "Mobile not Sync",
	text: "Mobile not synced! Kindly inform your client.",
	icon: "warning",
	button: false,
	timer: 2000,
};

export const netWorkError = {
	title: "Warning!",
	text: "Kindly Check Your Internet Connection!...",
	icon: "warning",
	button: false,
	timer: 2000,
};

export const clientDeleted = {
	title: "Success!",
	text: "Client Deleted Successfully",
	icon: "success",
	button: false,
	timer: 2000,
};
export const clientDeletedFailed = {
	title: "Warning!",
	text: "Client not delete",
	icon: "warning",
	button: false,
	timer: 2000,
};
export const serverError = {
	title: "Warning!",
	text: "Internal Server Error",
	icon: "warning",
	button: false,
	timer: 2000,
};

export const msgGenerator = (msg) => ({
	title: "Warning!",
	text: msg,
	icon: "warning",
	button: false,
	timer: 3000,
});

export const adminExists = {
	title: "Alert!",
	text: "An admin is already exists with this email or phone",
	icon: "info",
	button: false,
	timer: 2000,
};
export const adminAdded = {
	title: "Success!",
	text: "Admin added successfully!",
	icon: "success",
	button: false,
	timer: 2000,
};

export const adminDeleted = {
	title: "Success!",
	text: "Admin Deleted Successfully",
	icon: "success",
	button: false,
	timer: 2000,
};
export const adminDeletionFailed = {
	title: "Warning!",
	text: "Admin not deleted",
	icon: "warning",
	button: false,
	timer: 2000,
};

export const errorMessageGenerator = (msg) => ({
	title: "Warning!",
	text: msg || "Unable to reach the server at the moment",
	icon: "warning",
	button: false,
	timer: 4000,
});

export const successMessageGenerator = (msg) => ({
	title: "Success!",
	text: msg,
	icon: "success",
	button: false,
	timer: 2000,
});

export const errorMessageGeneratorWithConfirmation = (msg) => ({
	title: "Warning!",
	text: msg || "Unable to reach the server at the moment",
	icon: "warning",
	button: true
	// timer: 4000,
});