import React from "react";
// import ActivityLog from "./ActivityLog";
import '../../../asset/chatBox/charbox.css'
import { useMatch, useParams } from "react-router-dom";
import ActivityAdminList from './ActivityAdminList'
import ActivityClientList from "./ActivityClientList";

function ActivityList({ selectList }) {
	const isAdminListRendered = useMatch('/dashboard/activity/adminList');
	const isClientListRendered = useMatch('/dashboard/activity/clientList');
	const { id } = useParams()
	return (
		<div className="activity_list_container">
			<div className="reset_header_top"> 
				<p className="reset_header">					
					{isAdminListRendered && "Admins List"}
					{isClientListRendered && "Clients List"}
				</p>
			</div>

			{isAdminListRendered && <ActivityAdminList id={id} />}
			{isClientListRendered && <ActivityClientList id={id} />}
		</div>
	);
}

export default ActivityList;
