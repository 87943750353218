// import { CiHome, CiSettings } from "react-icons/ci";
// import home from "../asset/images/home.svg"
// import settings from "../asset/images/settings.svg"
// import activity from "../asset/images/activity.svg"

export const SideBarData = (role) => ([
	{
		id: 1,
		image: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3.5 9.98908C3.5 9.68049 3.64247 9.38919 3.88606 9.19973L11.8861 2.97751C12.2472 2.69665 12.7528 2.69665 13.1139 2.97751L21.1139 9.19973C21.3575 9.38919 21.5 9.68049 21.5 9.98908V20.5C21.5 21.0304 21.2893 21.5391 20.9142 21.9142C20.5391 22.2893 20.0304 22.5 19.5 22.5H5.5C4.96957 22.5 4.46086 22.2893 4.08579 21.9142C3.71071 21.5391 3.5 21.0304 3.5 20.5V9.98908Z"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.0049 22.5V15.1846C10.0049 14.6323 10.4526 14.1846 11.0049 14.1846H13.9941C14.5464 14.1846 14.9941 14.6323 14.9941 15.1846V22.5"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		header: "Home",
		routeName: "Home page",
		path: role === "superadmin" ? "adminList" : "clientList",
	},
	{
		id: 2,
		image: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.9951 13.5117C11.7908 13.5117 12.5538 13.1956 13.1164 12.633C13.679 12.0704 13.9951 11.3074 13.9951 10.5117C13.9951 9.71607 13.679 8.95301 13.1164 8.3904C12.5538 7.82779 11.7908 7.51172 10.9951 7.51172C10.1995 7.51172 9.43641 7.82779 8.8738 8.3904C8.31119 8.95301 7.99512 9.71607 7.99512 10.5117C7.99512 11.3074 8.31119 12.0704 8.8738 12.633C9.43641 13.1956 10.1995 13.5117 10.9951 13.5117Z"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M0.995117 11.3912V9.63117C0.995117 8.59117 1.84512 7.73117 2.89512 7.73117C4.70512 7.73117 5.44512 6.45117 4.53512 4.88117C4.01512 3.98117 4.32512 2.81117 5.23512 2.29117L6.96512 1.30117C7.75512 0.831167 8.77512 1.11117 9.24512 1.90117L9.35512 2.09117C10.2551 3.66117 11.7351 3.66117 12.6451 2.09117L12.7551 1.90117C13.2251 1.11117 14.2451 0.831167 15.0351 1.30117L16.7651 2.29117C17.6751 2.81117 17.9851 3.98117 17.4651 4.88117C16.5551 6.45117 17.2951 7.73117 19.1051 7.73117C20.1451 7.73117 21.0051 8.58117 21.0051 9.63117V11.3912C21.0051 12.4312 20.1551 13.2912 19.1051 13.2912C17.2951 13.2912 16.5551 14.5712 17.4651 16.1412C17.9851 17.0512 17.6751 18.2112 16.7651 18.7312L15.0351 19.7212C14.2451 20.1912 13.2251 19.9112 12.7551 19.1212L12.6451 18.9312C11.7451 17.3612 10.2651 17.3612 9.35512 18.9312L9.24512 19.1212C8.77512 19.9112 7.75512 20.1912 6.96512 19.7212L5.23512 18.7312C4.79922 18.4802 4.4807 18.0665 4.34946 17.5809C4.21823 17.0954 4.285 16.5776 4.53512 16.1412C5.44512 14.5712 4.70512 13.2912 2.89512 13.2912C1.84512 13.2912 0.995117 12.4312 0.995117 11.3912Z"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		header: "Settings",
		routeName: "Settings page",
		path: "settings",
	},
	{
		id: 3,
		image: (
			<svg
				width="25"
				height="25"
				viewBox="0 0 25 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					x="2.59375"
					y="2.59375"
					width="20.8125"
					height="20.8125"
					rx="3.65625"
					stroke="currentColor"
					strokeWidth="1.6875"
				/>
				<path
					d="M7.09155 15.9058L10.7678 11.7928C10.9814 11.5538 11.3512 11.5413 11.5804 11.7654L14.3899 14.5118C14.6236 14.7403 15.0021 14.7221 15.213 14.4724L18.9084 10.0947"
					stroke="currentColor"
					strokeWidth="1.6875"
					strokeLinecap="round"
				/>
			</svg>
		),
		header: "Activity",
		routeName: "Activity page",
		path: "activity",
	},
]);
