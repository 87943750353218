import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SideBarData } from "./sidemenu_icons";
import { useSelector } from "react-redux";

function Sidemenu() {
	const authState = useSelector(state => state.auth)
	const memoizedSideBarData = useMemo(() => SideBarData(authState.role), [authState.role])
	const location = useLocation();
  const keywords = ["adminList", "clientList", "chat", "sms", "email"];
	const pathNameArray = location.pathname.split('/');
	const isKeywordInUrl = keywords.includes(pathNameArray[2]);
	return (
		<>
			<nav className="sidemenu">
				<ul className="sidemenu_list">
					{memoizedSideBarData.map((item, pos) => {
            const isActive = isKeywordInUrl && pos === 0;
            return (
              <li key={item.id}>
                <NavLink
                  to={item.path}
                  state={{ activity: item.routeName }}
                  className={({ isActive: navLinkIsActive }) => 
                    navLinkIsActive || isActive ? "sidebar_nav side_menu side_menu--active" : "sidebar_nav side_menu"
                  }
                >
                  {item.image}
                  <p className="sidemenu_name">{item.header}</p>
                </NavLink>
              </li>
            );
          })}
				</ul>
			</nav>
		</>
	);
}

export default Sidemenu;
/* <Link
	to={item.path}
	state={{ activity: item.routeName }}
	className="sidebar_nav"
>
	<div
		className={
			sidemenuActive === item.id
				? "side_menu side_menu--active"
				: "side_menu"
		}
		onClick={() => {
			setsidemenuActive(item.id);
			removeItem("UserActivity");
			// sessionStorage.removeItem("UserActivity");
			setItem({ openActivityLog: false });
		}}
		title={item.header}
	>
		{item.image}
		<p className="sidemenu_name">{item.header}</p>
	</div>
</Link> */