import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../../asset/chatBox/emailbox.css";
import NoMail from "../../../asset/logo/no_mail.svg";
import useWindowSize from "../../../Hooks/useWindowSize";
import { useSelector } from "react-redux";
// import { useTanQuery } from "../../../Hooks/useTanQuery";
import EmailList from "./EmailList";
import EmailDetailedView from "./EmailDetailedView";
import MessageLoader from "../components/MessageLoader";
// import createSocketService from "../../../services/socketService";
import { useQueryClient } from "@tanstack/react-query";
import { useSyncEmail } from "../../../Hooks/tanqueryHooks";
function Email() {
	const queryClient = useQueryClient()
	// const { token } = useSelector(state => state.auth)
	const size = useWindowSize();
	var { id } = useParams();
	const [another, setAnother] = useState();
	// const { socket } = createSocketService(token)

	const [clientID, setClientID] = useState("");

	// const queryOptions = {
	// 	name: "emailQuery",
	// 	queryData: {
	// 		endPoint: "emailSync",

	// 		data: { id },
	// 	},
	// };
	const {
		isLoading,
		isSuccess,
		data: emailData,
		isError,
		refetch,
		isPending
	} = useSyncEmail({ id })
	// const {
	// 	isLoading,
	// 	isSuccess,
	// 	data: emailData,
	// 	isError,
	// 	refetch,
	// } = useTanQuery(queryOptions);
	useEffect(() => {
		refetch()
	}, [refetch])
	const hanldeEmailSync = () => {
		queryClient.invalidateQueries([])
	}
	// useEffect(() => {
	// 	socket?.on('')
	// }, [])
	// console.log(emailData, isLoading, isError);
	const tempfilter = (id, data) => {
		setAnother(data);
		setClientID(id);
	};

	const title = (
		<Helmet>
			<title>autoaudit.ai &#10072; Mail Page </title>
		</Helmet>
	);
	if (isLoading || isPending) {
		return (
			<>
				{/* ======================  Meta Tags ======================  */}
				{title}
				{/* ======================  Meta Tags ======================  */}
				<MessageLoader size={size} sizeToReduce={315} />
			</>
		);
	}
	if (
		isError ||
		!emailData?.status ||
		(isSuccess && emailData?.data?.length === 0)
	) {
		return (
			<>
				{/* ======================  Meta Tags ======================  */}
				{title}
				{/* ======================  Meta Tags ======================  */}
				<div className="clientNotSync">
					<img
						src={NoMail}
						alt="Mail Not Synced"
						title="Mail not Synced"
						className="no_mail"
					/>
					{(isError || !emailData?.status) && <p>Client email not synced...</p>}
					{isSuccess && emailData?.data?.length === 0 && (
						<p>Client email data not found...</p>
					)}
				</div>
			</>
		);
	}

	if (isSuccess) {
		const { data = [] } = emailData;
		const defaultClientId = data[0]?._id;
		const defaultContent = data[0]?.emailContent[0];
		return (
			<>
				{/* ======================  Meta Tags ======================  */}
				{title}
				{/* ======================  Meta Tags ======================  */}
				<div>
					<div className="emailBox">
						<EmailList
							data={data}
							tempfilter={tempfilter}
							clientID={clientID ? clientID : defaultClientId}
							size={size}
						/>
						<EmailDetailedView
							size={size}
							// On the initial render the another is undefined at that time give the first mail objcet to render as
							another={another ? another : defaultContent}
						/>
					</div>
				</div>
			</>
		);
	}
}
export default Email;
