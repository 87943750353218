import React from "react";
import CustomAvatar from "../../../components/CustomAvatar";

const Header = ({ name }) => {
	return (
		<div className="login_user">
			<div className="client_circle">
				<CustomAvatar name={name} />
			</div>

			<div className="company_header">
				<p className="login_username">
					<i>Welcome</i>
				</p>

				<p className="log_person">{name} </p>
			</div>
		</div>
	);
};

export default Header;
