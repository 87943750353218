import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import { Helmet } from "react-helmet";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Modal from "../../components/Modal";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../components/ModalBody";
import swal from "sweetalert";
import "react-phone-number-input/style.css";
import { Field, Form, Formik } from "formik";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { addAdminSchema } from "../../validation/Validation";
// import { useTanQuery } from "../../Hooks/useTanQuery";
import InfoHeaderForAdmin from "./components/InfoHeaderForAdmin";
import AdminList from "./AdminList";
import { useAddAdmin, useListAdmin } from "../../Hooks/tanqueryHooks";
import {
	serverError,
	adminAdded,
	errorMessageGenerator,
} from "../../util/swalConfigs";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { motion } from "framer-motion";
import ListHeader from "./components/ClientListHeader";
function AdminPage() {
	const [toggleModal, setToggleModal] = useState(false);
	// eslint-disable-next-line
	const [searchCls, setSearchCls] = useState(false);
	const authState = useSelector((state) => state.auth);
	const [show, setShow] = useState(true);
	const [initialFormValues, setInitialFormValues] = useState({
		name: "",
		email: "",
		phone: "",
	});
	const [searchParams, setSearchParams] = useState({
		ownerId: authState._id,
		pageNumber: 1,
		search: "",
	});
	const {
		isLoading,
		isError,
		isSuccess,
		data: responseData,
		refetch,
		isPending: isListPending,
	} = useListAdmin(searchParams);

	const handleChangeSearch = useCallback((event) => {
		setSearchParams((prev) => ({
			...prev,
			search: event.target.value,
		}));
	}, []);
	useEffect(() => {
		refetch()
	}, [searchParams, refetch])
	useEffect(() => {
		const handleClickOutside = (event) => {
			setSearchCls(false);
		};
		$(document).on("click", handleClickOutside);
		return () => {
			$(document).off("click", handleClickOutside);
		};
	}, []);

	const handleSuccessiveAdding = () => {
		swal(adminAdded);
		toggler();
	};
	const handleErrorAdding = ({ response }) => {


		let config;
		let status = response?.status;
		switch (status) {
			case 500:
				config = serverError;
				break;
			default:
				config = errorMessageGenerator(
					response?.data?.message || "Admin added failed"
				);
				break;
		}
		toggler();
		swal(config);
	};
	const { mutate, isPending } = useAddAdmin({
		handleSuccessiveAdding,
		handleErrorAdding,
	});
	const addAdminFunction = async (values) => {
		const { name, email, phone, password } = values;
		var strCaps = name.charAt(0).toUpperCase() + name.slice(1);
		const data = {
			email: email.toLowerCase(),
			ownerId: authState._id,
			phone: phone,
			name: strCaps,
			password: password,
		};
		mutate({ data, token: authState.token });
	};

	const toggler = useCallback(() => {
		setShow(true);
		setToggleModal((prev) => !prev);
		setInitialFormValues({
			name: "",
			email: "",
			phone: "",
			password: "",
		});
	}, []);
	return (
		<>
			{/* ======================  Meta Tags ======================  */}
			<Helmet>
				<title>autoaudit.ai &#10072; AdminList Page </title>
			</Helmet>
			{/* ======================  Meta Tags ======================  */}

			<>
				<InfoHeaderForAdmin
					isLoading={isLoading}
					value={searchParams.search}
					totalCount={responseData?.data?.totalCount}
					toggler={toggler}
					handleChangeSearch={handleChangeSearch}
				/>
				<ListHeader />
				<section id="contact_lists"
					initial={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.4 }}
				>
					<AdminList
						token={authState?.token}
						toggler={toggler}
						isLoading={isLoading}
						isError={isError}
						isSuccess={isSuccess}
						data={responseData?.data}
						isPending={isListPending}
						statusCode={responseData?.status}
					/>
				</section>
			</>

			{toggleModal && (
				<LoadingOverlayWrapper spinner active={isPending}>
					<Formik
						initialValues={initialFormValues}
						validationSchema={addAdminSchema}
						onSubmit={addAdminFunction}
					>
						{({
							values,
							handleChange,
							handleBlur,
							errors,
							touched,
							setFieldError,
							setFieldTouched,
							setFieldValue,
						}) => (
							<Form>
								<Modal>
									<ModalHeader header="Add Admin" onClick={toggler} />
									<ModalBody>
										<div className="parent_div">
											<div>
												<div className="login_option">
													<div>
														<label className="input_label" name="Name">
															<span>
																Name <span style={{ color: "red" }}>*</span>
															</span>
														</label>
														<Field
															name="name"
															placeholder="Admin's name"
															maxLength="40"
															disabled={isPending}
															className="input_type"
															autoComplete="off"
															autoFocus
														/>
														<p className="error">
															{errors.name && touched.name ? errors.name : ""}
														</p>
														<label className="input_label" name="phone">
															<span>
																Phone Number
																<span style={{ color: "red" }}> *</span>
															</span>
														</label>
													</div>

													<div>
														<PhoneInput
															placeholder="Admin's phone number"
															defaultCountry="IN"
															id="phoneId"
															className="phoneInput"
															disabled={isPending}
															autoComplete="off"
															value={values.phone} // Pass the Formik form field value to PhoneInput
															onChange={(phoneValue) => {
																handleChange({
																	target: {
																		name: "phone",
																		value: phoneValue, // Update the Formik form field value
																	},
																});
															}}
															onBlur={() => {
																// Manually trigger onBlur validation and set field error
																handleBlur("phone");
																if (
																	values.phone ||
																	!isValidPhoneNumber(values.phone ?? "")
																) {
																	setFieldError("phone", errors.phone);
																	setFieldTouched("phone", true);
																}
															}}
														/>
														<p className="error">
															{errors.phone && touched.phone
																? errors.phone
																: ""}
														</p>
													</div>

													<div>
														<label className="input_label" name="email">
															<span>
																Email <span style={{ color: "red" }}>*</span>
															</span>
														</label>

														<Field
															name="email"
															placeholder="Admin's email address"
															maxLength="60"
															className="input_type"
															disabled={isPending}
															autoComplete="off"
															onChange={(event) => {
																const uppercaseValue =
																	event.target.value.toLowerCase();
																setFieldValue("email", uppercaseValue);
															}}
														/>
														<p className="error">
															{errors.email && touched.email
																? errors.email
																: ""}
														</p>
													</div>
													<div>
														<label className="input_label" name="password">
															<span>
																Password <span style={{ color: "red" }}>*</span>
															</span>
														</label>

														<Field
															name="password"
															placeholder="Admin's password"
															type="text"
															id={!show ? "text" : "password"}
															disabled={isPending}
															maxLength="16"
															minLength="8"
															className="input_type"
															autoComplete="off"
															value={values.password}
														/>
														<span
															onClick={() => setShow(!show)}
															className="iconEyeAdmin"
														>
															{show && <FiEyeOff />}
															{!show && <FiEye />}
														</span>
														<p className="error password_error">
															{errors.password && touched.password
																? errors.password
																: ""}
														</p>
													</div>
												</div>
											</div>
										</div>
									</ModalBody>
									<div className="modal_footer right_side">
										<div className="footer_align">
											<button
												disabled={isPending}
												type="submit"
												className="modal_btn btn_confirm"
											>
												{" "}
												Save{" "}
											</button>
											<button
												disabled={isPending}
												onClick={toggler}
												type="button"
												className="modal_btn btn_close"
											>
												{" "}
												Cancel{" "}
											</button>
										</div>
									</div>{" "}
								</Modal>
							</Form>
						)}
					</Formik>
				</LoadingOverlayWrapper>
			)}
		</>
	);
}

export default AdminPage;
