import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import RippleNotify from "../../components/RippleNotify";
import { Tooltip } from "react-tooltip";
import "../../asset/contact/contact.css";
import { useDispatch } from "react-redux";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import ModalBody from "../../components/ModalBody";
import ModalHeader from "../../components/ModalHeader";
import ModalFooter from "../../components/ModalFooter";
import CustomAvatar from "../../components/CustomAvatar";
import { setCurrentMember } from "../../slice/memberSlice";
import AssignAdminDetails from "./AssignAdminDetails";
import apiCaller from "../../util/apiCaller";
import { io } from "socket.io-client";
import {
	clientDeleted,
	clientDeletedFailed,
	errorMessageGenerator,
	successMessageGenerator,
} from "../../util/swalConfigs";
import { useUserDelete, useClientToggleBlock } from "../../Hooks/tanqueryHooks";
import { getItem } from "../../util/helper";

// icons added
import ShareIcon from "../../components/icons/ShareIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import OfflineIcon from "../../components/icons/OfflineIcon";
import BlockIcon from "../../components/icons/BlockIcon";
import MsgIcon from "../../components/icons/MsgIcon";
import ClientSync from "../../components/icons/ClientSync";
import MailIcon from "../../components/icons/MailIcon";
import MobIcon from "../../components/icons/MobIcon";




export default function Contact({
	token,
	_id,
	name,
	phone,
	email,
	adminVerified,
	mpinStatus,
	data,
	funCall,
	blocked,
	ynSync,
	newSms,
	ynEmailSyncStatus,
	activeStatus,
	ownerId,
	setListOfContactsHeight
}) {
	// console.log(mpinStatus);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const role = getItem("role");
	const [adminModal, setAdminModal] = useState(false);
	const [delClient, setDelClient] = useState(false);
	const [blockModal, setBlockModal] = useState(false);
	const [socket, setSocket] = useState(null);
	const onClick = () => {
		dispatch(setCurrentMember({ _id }));
	};
	const handleSuccess = (res) => {
		const msg = res?.message;
		swal(successMessageGenerator(msg));
	};
	const handleError = (error) => {
		const msg =
			error?.response?.data?.message ??
			"Error occurred while change the block status.";
		swal(errorMessageGenerator(msg));
	};
	const { mutate, isPending } = useClientToggleBlock({
		handleSuccess,
		handleError,
	});
	// console.log("STatus", blocked);

	// const displayChart = (e, id) => {
	// 	adminVerified ? navigate(`/dashboard/charts/${id}`) : console.log();
	// 	e.stopPropagation();
	// };

	// //SOCKET API Fectch
	// useEffect(() => {
	// 	const newSocket = io(process.env.REACT_APP_SOCKET_URL);
	// 	setSocket(() => newSocket);

	// 	// Clean up the socket connection when the component unmounts
	// 	return () => {
	// 		newSocket.disconnect();
	// 	};
	// }, []);

	const userUnBlocked = (e) => {
		// setBlockModal(true)
		const data = {
			type: blocked ? "unBlock" : "Block",
			clientId: _id,
		};

		const blockClientStatusSocket = {
			type: blocked ? "unBlock" : "Block",
			clientId: _id,
			token: `Bearer ${getItem("auth")}`,
		};
		// socket?.emit("UserBlockStatus", blockClientStatusSocket);
		// socket?.on("UserBlockStatus", (value) => { setUserBlock(v)});
		mutate({ data, token });
		e.stopPropagation();
		setBlockModal(false);
	};

	const transfer = (
		mpinStatus,
		id,
		adminVerfified,
		name,
		email,
		blocked,
		activeStatus
	) => {
		data(mpinStatus, id, adminVerfified, name, email, blocked, activeStatus);
	};
	// Edited by Nijine - start
	const displayChat = (event) => {
		// console.log("chat displayed");
		navigate(`/dashboard/chat/chat/${_id}`);
		event.stopPropagation();
	};

	const displayEmail = (event) => {
		// console.log("email displayed");
		navigate(`/dashboard/chat/email/${_id}`);
		event.stopPropagation();
	};
	// Edited by Nijine - end

	const deleteModal = (event) => {
		setDelClient(!delClient);
		event.stopPropagation();
	};

	const blkModal = (event) => {
		setBlockModal(!blockModal);
		event.stopPropagation();
	};
	const handleSuccessiveDelete = () => {
		swal(clientDeleted);
		setDelClient(false);
	};
	const handleFailedDelete = () => {
		swal(clientDeletedFailed);
	};

	const deleteClient = useUserDelete({
		handleSuccessiveDelete,
		handleFailedDelete,
	});
	const DeleteClient = (e) => {
		deleteClient.mutate({ _id, token });
		e.stopPropagation();
	};

	const cancelModal = (e) => {
		e.stopPropagation();
		setDelClient(false);
		setBlockModal(false);
	};

	const changeAdminModal = (e) => {
		setAdminModal(!adminModal);
		e.stopPropagation();
	};

	const buttonConfigDelete = [
		{
			disabled: deleteClient.isPending,
			text: "Confirm",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[], [DeleteClient]],
			datavalue: "1",
			form: "clientAddForm",
		},
		{
			text: "Cancel",
			className: "modal_btn btn_close",
			onClick: [[], [cancelModal]],
			datavalue: "1",
			close: true,
			form: "clientAddForm",
		},
	];

	const buttonConfigBlock = [
		{
			disabled: isPending,
			text: "Confirm",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[], [userUnBlocked]],
			datavalue: "1",
			form: "clientAddForm",
		},
		{
			disabled: isPending,
			text: "Cancel",
			className: "modal_btn btn_close",
			onClick: [[], [cancelModal]],
			datavalue: "1",
			close: true,
			form: "clientAddForm",
		},
	];

	// measure `list of contacts div height and pass it to the parent component
	useEffect(() => {
		const handleHeight = () => {
			setListOfContactsHeight(document.querySelector('.list_of_contacts').clientHeight)
		}
		window.addEventListener('resize', handleHeight)
		handleHeight()
		return () => window.removeEventListener('resize', handleHeight)
	}, [setListOfContactsHeight])


	return (
		<>
			<div
				className="list_of_contacts"
				onClick={() =>
					transfer(
						mpinStatus,
						_id,
						adminVerified,
						name,
						email,
						blocked,
						activeStatus
					)
				}
			>
				<div className="client_circle">
					<CustomAvatar name={name} />
				</div>
				<div
					className="member_info"
					onClick={(e) => {
						onClick(e);
					}}
				>
					<div className="uer_name_view">
						<Tooltip
							id={name}
							place="top"
							effect="solid"
							style={{
								backgroundColor: "var(--primarycolor)",
								borderRadius: "20px",
							}}
						/>
						<p
							data-tooltip-id={name}
							data-tooltip-content={name}
							className="user_name"
						>
							{name}
						</p>
					</div>
					<div className="user_phone">
						<div className="mobile_user">
							<MobIcon
								svgClass="total_icon"
								spanClass="mobile_icon"
							/>
							<span data-tooltip-id={phone} data-tooltip-content={phone}>
								{phone}
							</span>
							<Tooltip
								id={phone}
								place="top"
								effect="solid"
								style={{
									backgroundColor: "var(--primarycolor)",
									borderRadius: "20px",
								}}
							/>
						</div>
					</div>
					<div className="user_email">
						<div className="mobile_user">
							<MailIcon
								svgClass="total_icon"
								spanClass="mobile_icon"
							/>
							<Tooltip
								id={email}
								place="top"
								effect="solid"
								style={{
									backgroundColor: "var(--primarycolor)",
									borderRadius: "20px",
								}}
							/>

							<span
								data-tooltip-id={email}
								data-tooltip-content={email}
								className=""
							>
								{email}
							</span>
						</div>
					</div>
				</div>
				<div className="user_chatlist user-align-details">
					{mpinStatus ? (
						adminVerified ? null : (
							<>
								<ClientSync
									svgClass="total_icon"
									spanClass="show_icons mpin_span"
									TooltipId="mpin_not_Sync"
									ToolContent="M-Pin Not Received"
								/>
								<Tooltip id="mpin_not_Sync" place="top" effect="solid" />
							</>
						)
					) : (
						<>
							<ClientSync
								svgClass="total_icon"
								spanClass="show_icons delete_span"
								TooltipId="mobNotSync"
								ToolContent="Client not sync"
							/>

							<Tooltip id="mobNotSync" place="top" effect="solid" />
						</>
					)}
					{/* <span
            className={
              mpinStatus 
                ? adminVerified
                  ? "user_status_mobile_sync"
                  : "user_status_mobile_not_sync"
                : "user_status_mPin_not_received"
            }
          >
            {mpinStatus? (
              adminVerified ? (
                <CiCloudOn
                  className="chat_icon"
                  data-tooltip-id="mobSync"
                  data-tooltip-content="Client is Sync"
                />
              ) : (
                <CiCloudRainbow
                  className="chat_icon_for_Mpin"
                  data-tooltip-id="mpin_not_Sync"
                  data-tooltip-content="M-Pin Not Received"
                />
              )
            ) : (
              <CiCloudOff
                className="chat_icon_not_sync"
                data-tooltip-id="mobNotSync"
                data-tooltip-content="Client Not Sync"
              />
            )}
          </span>
          <Tooltip id="mobSync" place="top" effect="solid" />
          <Tooltip id="mpin_not_Sync" place="top" effect="solid" />
          <Tooltip id="mobNotSync" place="top" effect="solid" /> */}

					{mpinStatus && adminVerified && (
						<>
							{ynSync ? (
								<>

									<MsgIcon
										svgClass="total_icon"
										spanClass="show_icons client_span"
										TooltipId="newMsg"
										ToolContent="New messages"
										onClick={displayChat}
									/>
									{newSms && <RippleNotify />}
									<Tooltip
										id="newMsg"
										place="top"
										effect="solid"
										style={{
											backgroundColor: "var(--primarycolor)",
											borderRadius: "20px",
										}}
									/>
								</>
							) : (
								<>
									<MsgIcon
										svgClass="total_icon"
										spanClass="show_icons delete_span"
										TooltipId="msgNotSync"
										ToolContent="Messages not Sync"
									/>
									<Tooltip
										id="msgNotSync"
										place="top"
										effect="solid"
									/>
								</>
							)}

							{ynEmailSyncStatus ? (
								<>
									<MailIcon
										svgClass="total_icon"
										spanClass="show_icons client_span"
										TooltipId="newEmail"
										ToolContent="New mails"
										onClick={displayEmail}
									/>

									<Tooltip
										id="newEmail"
										place="top"
										effect="solid"
										style={{
											backgroundColor: "var(--primarycolor)",
											borderRadius: "20px",
										}}
									/>
								</>
							) : (
								<>
									<MailIcon
										svgClass="total_icon"
										spanClass="show_icons delete_span"
										TooltipId="mobNotSync"
										ToolContent="Email not sync"
									/>
									<Tooltip id="mobNotSync" place="top" effect="solid" />
								</>
							)}
						</>
					)}
					{/* <span className="user_chat">
				<PiChartPieSliceThin
					className="chat_icon"
					data-tooltip-id="new_Chart"
					data-tooltip-content="Charts"
					onClick={(e) => displayChart(e, _id)}
				/>
			</span>
			<Tooltip
				id="new_Chart"
				place="top"
				effect="solid"
				style={{
					backgroundColor: "var(--primarycolor)",
					borderRadius: "20px",
				}}
		    /> */}
					{activeStatus ? (
						<>
							<OfflineIcon
								svgClass="total_icon"
								spanClass="show_icons client_span"
								TooltipId="user_Active"
								ToolContent="Online"
							/>

						</>
					) : (
						<>
							<OfflineIcon
								svgClass="total_icon"
								spanClass="show_icons delete_span"
								TooltipId="user_InActive"
								ToolContent="Offline"
							/>
						</>
					)}
					<Tooltip
						id="user_Active"
						place="top"
						effect="solid"
						style={{
							backgroundColor: "var(--primarycolor)",
							borderRadius: "20px",
						}}
					/>
					<Tooltip
						id="user_InActive"
						place="top"
						effect="solid"
						style={{ backgroundColor: "#f87a7a", borderRadius: "20px" }}
					/>
					{blocked ? (
						<>
							<BlockIcon
								svgClass="total_icon"
								spanClass="show_icons client_span"
								TooltipId="user_unblocked"
								ToolContent="Click here to Un-Block the User"
								onClick={blkModal}
							/>
							<Tooltip id="user_unblocked" place="top" effect="solid" />
						</>
					) : (
						<>
							<BlockIcon
								svgClass="total_icon"
								spanClass="show_icons delete_span"
								TooltipId="user_block"
								ToolContent="Click here to Block the User"
								onClick={blkModal}
							/>
							<Tooltip id="user_block" place="top" effect="solid" />
						</>
					)}

					{role === "superadmin" ? (
						<>
							{" "}
							<ShareIcon
								svgClass="total_icon"
								spanClass="show_icons client_span"
								TooltipId="changeAdmin"
								ToolContent="Share Client"
								onClick={changeAdminModal}
							/>
							<Tooltip
								id="changeAdmin"
								place="top"
								effect="solid"
								style={{
									backgroundColor: "var(--primarycolor)",
									borderRadius: "20px",
								}}
							/>


						</>
					) : null}

					<DeleteIcon
						svgClass="total_icon"
						spanClass="show_icons delete_span"
						TooltipId="newTrash"
						ToolContent="Delete"
						onClick={deleteModal}
					/>
					<Tooltip
						id="newTrash"
						place="top"
						effect="solid"
						className="tooltip-danger"
					/>

				</div>
			</div>
			{delClient ? (
				<Modal>
					<ModalHeader header="Are you sure?" onClick={cancelModal} />
					<ModalBody modal_grid="client_body_one">
						<div className="client_delete">
							<p className="deleteHeader">
								Do you really want to delete these records?
							</p>
							<table className="delete_table">
								<tr>
									<td className="delRec">Name</td>
									<td className="delReCol">:</td>
									<td>{name}</td>
								</tr>
								<tr>
									<td className="delRec">Email</td>
									<td className="delReCol">:</td>
									<td>{email}</td>
								</tr>
							</table>
						</div>
						<div></div>
					</ModalBody>
					<ModalFooter buttonConfig={buttonConfigDelete}></ModalFooter>
				</Modal>
			) : (
				<></>
			)}
			{adminModal ? (
				<AssignAdminDetails
					_id={_id}
					funCall={funCall}
					name={name}
					email={email}
					changeAdminModal={changeAdminModal}
				/>
			) : (
				<></>
			)}
			{blockModal ? (
				<>
					<Modal>
						<ModalHeader header="Are you sure?" onClick={cancelModal} />
						<ModalBody modal_grid="client_body_one">
							<div className="client_delete">
								<p className="deleteHeader">
									{`Do you really want to ${blocked ? `Un-Block` : `Block`
										} ${name}'s  record?`}
								</p>
								<table className="delete_table">
									<tbody>
										<tr>
											<td className="delRec">Name</td>
											<td className="delReCol">:</td>
											<td>{name}</td>
										</tr>
										<tr>
											<td className="delRec">Email</td>
											<td className="delReCol">:</td>
											<td>{email}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</ModalBody>

						<ModalFooter buttonConfig={buttonConfigBlock}></ModalFooter>
					</Modal>
				</>
			) : (
				<></>
			)}
		</>
	);
}
