import { useMutation, useQuery } from "@tanstack/react-query";
import {
	deleteClient,
	listClient,
	addClient,
	getAdmin,
	deleteAdmin,
	listAdmin,
	addAdmin,
	toggleBlockClient,
	adminVerfyMpin,
	adminLogin,
	getAdminsCount,
	verifyToken,
	getAllThread,
	getAllMessages,
	resetAdminPassword,
	syncEmail,
	getTFA,
	generateTFACreds,
	toggleTFAStatus,
	logger
} from "../util/queryFunctions";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// Hooks for Client
export const useListClient = (data) => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ["list-client"],
		queryFn: () => listClient(data, authState.token),
		enabled: !!authState.token,
	});
};

export const useAddClient = (callbacks) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["add-client"],
		mutationFn: addClient,
		onError: callbacks.handleErrorAdding,
		onSuccess: () => {

			queryClient.invalidateQueries(["list-client"]);
			callbacks.handleSuccessiveAdding();
		},
	});
};

export const useUserDelete = (callbacks) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["delete-client"],
		mutationFn: deleteClient,
		onSuccess: () => {
			callbacks.handleSuccessiveDelete();
		},
		onMutate: async (id) => {
			await queryClient.cancelQueries("list-client");
			const previousQueryData = queryClient.getQueryData("list-client");
			queryClient.setQueryData("list-client", (oldData) => {
				const newClientList = (oldData?.response ?? []).filter(
					(client) => client._id !== id
				);
				return {
					...oldData,
					response: newClientList,
				};
			});
			return previousQueryData;
		},
		onError: (_error, _variables, context) => {
			callbacks.handleFailedDelete();
			queryClient.setQueryData("list-client", context.previousQueryData);
		},
		onSettled: () => {
			queryClient.invalidateQueries("list-client");
		},
	});
};

export const useClientToggleBlock = (callbacks) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: "toggle-block-client",
		mutationFn: toggleBlockClient,
		onError: callbacks.handleError,
		onSuccess: callbacks.handleSuccess,
		onSettled: () => {
			queryClient.invalidateQueries("list-client");
		},
	});
};

// Hooks for Admin
export const useAddAdmin = (callbacks) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["add-admin"],
		mutationFn: addAdmin,
		onError: callbacks.handleErrorAdding,
		onSuccess: () => {
			queryClient.invalidateQueries("list-admin");
			callbacks.handleSuccessiveAdding();
		},
	});
};
export const useGetAdmin = (id) => {
	const authState = useSelector((state) => state.auth);

	return useQuery({
		queryKey: ["get-admin"],
		queryFn: () => getAdmin(id, authState.token),
		enabled: !!authState.token,
	});
};


export const useListAdmin = (data) => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ["list-admin"],
		queryFn: () => listAdmin(data, authState.token),
		enabled: !!authState.token,
	});
};

// export const useListMutationAdmin = (data) => {
// 	const authState = useSelector((state) => state.auth);
// 	const mutation= useMutation({
// 		mutationKey: ["list-admin"],
// 		mutationFn: () => listAdmin(data, authState.token),
// 		enabled: !!authState.token,
// 	});
// 	// console.log(mutation)
// 	return mutation;
// };

export const useDeleteAdmin = (callbacks) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: "delete-admin",
		mutationFn: deleteAdmin,
		onSuccess: () => {
			callbacks.handleSuccessiveDelete();
		},
		onError: callbacks.handleErrorDelete,
		onSettled: () => {
			queryClient.invalidateQueries("list-admin");
			callbacks.handleSettled();
		},
	});
};

export const useLogin = (callbacks) => {
	return useMutation({
		mutationKey: ["login-admin"],
		mutationFn: adminLogin,
		onSuccess: callbacks.handleSuccess,
		onError: callbacks.handleError,
		retry: 3,

	});
};

export const useAdminVerifyMpin = (callbacks) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["verify-mpin"],
		mutationFn: adminVerfyMpin,
		onSuccess: () => {
			callbacks.handleSuccessiveVerification().then(() => { queryClient.invalidateQueries("list-client") })
		},
		onError: callbacks.handleErroriveVerification,
	});
};
export const useGetAdminsCount = (data) => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ["list-admin", data],
		queryFn: () => getAdminsCount(data, authState.token),
		enabled: !!authState.token,
	});
};

export const useTokenVerify = (callbacks) => {
	const location = useLocation();
	const authState = useSelector((state) => state.auth);

	return useQuery({
		queryKey: ["verify-token", location.pathname],
		queryFn: () => verifyToken(authState.token),
		enabled: !!authState.token,

	});
};

// Query hooks for sms and email
export const useSyncSms = (data) => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ["sync-sms"],
		queryFn: () => getAllThread(data, authState.token),
		enabled: !!authState.token,
	});
};

export const useGetAllThread = (data) => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ["get-all-thread"],
		queryFn: () => getAllThread(data, authState.token),
		enabled: !!authState.token,
	});
};

export const useGetAllMessages = (callbacks) => {
	const { activeThread } = useSelector(state => state.reactiveParams)
	const queryClient = useQueryClient()
	// data["thread_id"] = activeThread;
	const { token } = useSelector((state) => state.auth);
	return useMutation({
		mutationKey: ["get-all-messages", activeThread],
		mutationFn: (variable) => getAllMessages(variable, token),
		onSuccess: () => {
			queryClient.invalidateQueries(["get-all-thread"])
		},
		onError: (error) => {
			// console.log(error);
		},

		// enabled: !!activeThread && !!token
	});
};
// export const useGetAllMessages = (data) => {
// 	const { activeThread } = useSelector(state => state.reactiveParams)
// 	data["thread_id"] = activeThread;
// 	const { token } = useSelector((state) => state.auth);
// 	return useQuery({
// 		queryKey: ["get-all-messages", activeThread],
// 		queryFn: () => getAllMessages(data, token),
// 		enabled: !!activeThread && !!token
// 	});
// };
export const useSyncEmail = (data) => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ["sync-email"],
		queryFn: () => syncEmail(data, authState.token),
		enabled: !!authState.token,
	});
};

export const useResetPassword = (callbacks) => {
	return useMutation({
		mutationKey: ["reset-password"],
		mutationFn: resetAdminPassword,
		onSuccess: callbacks.handleSuccess,
		onError: callbacks.handleError,
	});
}

/**
 * Custom hook to fetch TFA status using useQuery.
 * 
 * @returns {Object} The query result containing TFA status.
 */
export const useTFAStatusChecker = () => {
	const authState = useSelector((state) => state.auth);
	return useQuery({
		queryKey: ['get-tfa'],
		queryFn: () => getTFA(authState.token),
		enabled: !!authState.token,
	})
}

export const useGenerateTFACreds = () => {
	return useMutation({
		mutationKey: ['generate-tfa-creds'],
		mutationFn: generateTFACreds,
	})
}

export const useToggleTFAStatus = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ['toggle-tfa-status'],
		mutationFn: toggleTFAStatus,
		onSuccess: () => {
			// console.log('invalidation called');
			queryClient.invalidateQueries(['get-tfa'])
		}
	})
}

export const useLogger = () => {
	return useMutation({
		mutationKey: ['logger'],
		mutationFn: logger,
	})
}