import axios from "axios";
// import { reverseGeoCoding, timeAndDate } from "./helper";
import { getItem, timeAndDate } from "./helper";
import { locationProvider } from "./geoLocation";

const apiCaller = async ({
	url,
	data = null,
	headers,
	method = "POST",
	token = null,
	params = null,
}) => {
	try {
		const location = await locationProvider();
		const locationDetails = JSON.stringify(location);
		return axios({
			method: method,
			url: `${process.env.REACT_APP_API_URL}/user/${url}`,
			...(data && { data }),
			...(params && { params }),
			responseType: "json",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getItem("auth") ?? ""}`,
				...headers,
				...timeAndDate(),
				locationDetails,
			},
		});
	} catch (error) {
		// console.log(error);
	}
	// const locationDetails = JSON.stringify(await locationProvider());


};

// ClientList component's function

export default apiCaller;
