import React, { useState, useEffect, useRef } from "react";
import "../../asset/chatBox/resetpasswordoptions.css";
import { MdOutlineContentCopy } from "react-icons/md";
import ResetPassword from "../settings/ResetPassword";
import swal from "sweetalert";
import { Tooltip } from "react-tooltip";
import Buttons from "../../components/Buttons";
import apiCaller from "../../util/apiCaller";

function ResetPasswordOptions({
	adminUserId,
	getValuefromResetAdminPassword,
	adminName,
}) {
	const [selectedButton, setSelectedButton] = useState("button1");
	const [childValue, setChildValue] = useState("");
	const [autoPass, setAutoPass] = useState("");
	const [closeValue, setCloseValue] = useState(false);
	const [copyConent, setCopyContent] = useState(true);
	const [displayAutoPassword, setDisplayAutoPassword] = useState(false);
	const paragraphRef = useRef(null);

	const handleRadioChange = async (value) => {
		setSelectedButton(value);
	};

	const autoGenerate = async () => {
		// console.log("auto Generate");
		setDisplayAutoPassword(true);
		// const data = {
		// 	_id: adminUserId,
		// 	activity: "Password udpated",
		// };
		try {
			const responseChat = await apiCaller({
				url: "createPassword",
				method: "GET",
			});
			let displayData = responseChat.data.response;
			setAutoPass(displayData);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const manualGenerate = async (childValue) => {
		// console.log(childValue);
		const data = {
			_id: adminUserId,
			activity: "Password udpated",
			newpassword: childValue,
		};
		// console.log("object", data)
		try {
			const responseChat = await apiCaller({
				url: "updatePassword",
				data,
			});
			let displayData = responseChat.data.status;
			if (displayData === true) {
				swal({
					title: "Success!",
					text: "Admin Password Reset Successfully",
					icon: "success",
					button: false,
					timer: 2000,
				});
			}
			cancelFunction();
		} catch (error) {
			// console.error("Error:", error);
			let displayData = error.status;
			if (displayData === false) {
				swal({
					title: "Warning!",
					text: error,
					icon: "warning",
					button: false,
					timer: 2000,
				});
			} else {
				swal({
					title: "Warning!",
					text: error,
					icon: "warning",
					button: false,
					timer: 2000,
				});
			}
		}
	};

	const withoutCopy = () => {
		if (copyConent) {
			setChildValue(autoPass);
			swal({
				title: "Warning",
				text: "Dont Forget to copy the Auto Generated Password",
				icon: "info",
				button: false,
				timer: 2000,
			});
		} else {
			manualGenerate(autoPass);
		}
	};

	const handleChildValueChange = (data, closeData) => {
		setChildValue(data);
		// console.log("closeData", closeData)

		// console.log("closeValue", closeValue);
		// console.log("data", data)
		getValuefromResetAdminPassword();
		cancelFunction();

		setCloseValue(closeData);
		// console.log("object")
		if (data !== childValue) {
			// console.log("Data from child:", data, closeData);
			manualGenerate(data);
		}
	};

	useEffect(() => {
		// // console.log("dadadadadad", closeValue)
		// console.log("Child Value Pass", childValue)
		setTimeout(() => {
			setCopyContent(true);
		}, 7000);
	}, [closeValue, copyConent, childValue]);

	const copyToClipboard = () => {
		// Create a range object
		const range = document.createRange();
		// Select the text content of the paragraph
		range.selectNode(paragraphRef.current);

		// Create a selection object
		const selection = window.getSelection();
		// Remove any existing selections
		selection.removeAllRanges();
		// Add the new range to the selection
		selection.addRange(range);

		// Execute the copy command
		document.execCommand("copy");

		// Clear the selection
		selection.removeAllRanges();
		setCopyContent(false);
	};

	useEffect(() => {
		const fetchData = async () => { };
		fetchData();
	}, [adminUserId, selectedButton]);

	const cancelFunction = () => {
		// setCloseValue(!closeValue)
		// console.log("CancelFunction", closeValue);
		getValuefromResetAdminPassword();
	};

	const resetFormButtonConfig = [
		{
			text: "Sure",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[], [autoGenerate]],
		},
		{
			text: "Cancel",
			id: "cancel",
			className: "modal_btn btn_close",
			onClick: [[], [cancelFunction]],
		},
	];

	const resetPassFormButtonConfig = [
		{
			text: "Confirm",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[], [withoutCopy]],
		},
		{
			text: "Cancel",
			id: "cancel",
			className: "modal_btn btn_close",
			onClick: [[], [cancelFunction]],
		},
	];

	return (
		<div>
			<div>
				<div className="chart-flex-contain">
					<label className="input_label">
						<input
							type="radio"
							value="button1"
							checked={selectedButton === "button1"}
							id="radio-button1" // Use a unique id for each radio button
							name="radio-colors"
							onChange={() => handleRadioChange("button1")}
						/>
						Auto Generate Password
					</label>

					<label className="input_label">
						<input
							type="radio"
							value="button2"
							checked={selectedButton === "button2"}
							id="radio-button2" // Use a unique id for each radio button
							name="radio-colors"
							onChange={() => handleRadioChange("button2")}
						/>
						Enter Manual Password
					</label>
				</div>

				<div>
					{selectedButton === "button1" && (
						<div className="password-generator">
							{/* {displayAutoPassword ? (
								<>

								</>
							) : ( */}
							<>
								<div className="reset-title">
									{displayAutoPassword ? (
										<p>
											Are You Sure, You want Confirm this Password for{" "}
											{adminName}?
										</p>
									) : (
										<p>Are You Sure, You want Reset {adminName}'s Password?</p>
									)}
								</div>
								<div>
									{displayAutoPassword ? (
										<>
											<div className="auto-password-generator">
												<div>
													<p>Auto Generated Password </p>
												</div>
												<div className="copy-password-text">
													<p className="copy-password" ref={paragraphRef}>
														{autoPass}
													</p>
													<MdOutlineContentCopy
														onClick={copyToClipboard}
														className="copy-clip"
														data-tooltip-id="copy-reset-password"
														data-tooltip-content={
															copyConent
																? "Copy the Password"
																: "Password Copied"
														}
													/>
												</div>
											</div>
											<Tooltip
												id="copy-reset-password"
												place="top"
												effect="solid"
											/>
											<div className="rstBut-for-reset">
												{resetPassFormButtonConfig.map((config, index) => (
													<Buttons key={index} {...config} />
												))}
											</div>
										</>
									) : (
										<>
											<div className="copy-password-text-hide"></div>
											<div className="rstBut-for-reset">
												{resetFormButtonConfig.map((config, index) => (
													<Buttons key={index} {...config} />
												))}
											</div>
										</>
									)}
								</div>
							</>
							{/* )} */}
						</div>
					)}
					{selectedButton === "button2" && (
						<div className="password-generator">
							<div>
								<ResetPassword
									resetPassword={false}
									onChildValueChange={handleChildValueChange}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ResetPasswordOptions;
