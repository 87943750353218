import React from "react";
import { BrowserRouter } from "react-router-dom";
import Allpages from "./pages/Allpages";
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ContextProvider } from "./util/SharedFunctionsContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import swal from "sweetalert";
import { store } from "./store/dataStore";
import { setLogout } from "./slice/authSlice";

const handleTooManyRequest = (error) => {
	const status = error?.response?.status;
	if (status === 429) {
		swal({
			title: "Alert!",
			text: `Request limit reached, please try after one minute `,
			icon: "warning",
			button: "continue to login",
			// timer: 2000,
		}).then(() => {
			store.dispatch(setLogout());
		})
	} else if (status === 401 || status === 403) {
		swal({
			title: "Alert!",
			text: `Session has expired, please login to continue`,
			icon: "warning",
			button: "login",
			// timer: 2000,
		}).then(() => {
			store.dispatch(setLogout());
		})
	}
}
const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error, query) => {
			handleTooManyRequest(error)
		},
	}),
	mutationCache: new MutationCache({
		onError: (error) => {
			handleTooManyRequest(error)
		}
	}),
	defaultOptions: {
		queries: {
			retry: false,
			retryOnMount: false,
			refetchOnWindowFocus: false,
		},
	},
});
// import "./util/envConfig";
function App() {
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<ContextProvider>
					<BrowserRouter>
						<Allpages />
					</BrowserRouter>
				</ContextProvider>
				<ReactQueryDevtools initialIsOpen={false} position={"right"} />
			</QueryClientProvider>
		</>
	);
}

export default App;
