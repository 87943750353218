import React, { useEffect } from "react";
import InfoBadge from "../../admin/components/InfoBadge";
import { useGetAdminsCount } from "../../../Hooks/tanqueryHooks";
const InfoHeaderForAdmin = React.memo(
	// ({ totalCount, searchCls, clsFunc, toggler, handleChangeSearch }) => {
	({ totalCount, toggler, handleChangeSearch, value }) => {
		const { isLoading, isError, isSuccess, data, refetch } =
			useGetAdminsCount();

		useEffect(() => {
			refetch();
		}, [refetch]);
		if (isLoading) {
			return (
				<div className="no_contacts">
					<div className="contact_count">
						<InfoBadge name="Loading..." />
					</div>

					<div className="memberlist_right">
						<InfoBadge key="search" name="Loading..." />

						<div className="card_button">
							<button className="primary_button" onClick={toggler}>
								+ Add Admin
							</button>
						</div>
					</div>
				</div>
			);
		}
		if (isError) {
			return (
				<div className="no_contacts">
					<div className="contact_count">
						<InfoBadge name="Unable to load..." />
					</div>

					<div className="memberlist_right">
						<InfoBadge key="search" name="Unable to load..." />

						<div className="card_button">
							<button className="primary_button" onClick={toggler}>
								+ Add Admin
							</button>
						</div>
					</div>
				</div>
			);
		}
		if (isSuccess) {
			return (
				<div className="no_contacts">
					<div className="contact_count">
						<InfoBadge name="Admins" value={data?.response?.adminCounts} />
					</div>

					<div className="memberlist_right">
						{data?.response?.adminCounts > 0 && (
							<div className="header_search">
								<input
									value={value}
									type="text"
									className="search_box"
									placeholder="Search your admin here"
									id="search_box"
									onChange={handleChangeSearch}
									autoComplete="off"
								/>

								{/* {searchCls && (
							<div className="search_close" onClick={clsFunc}></div>
						)} */}
							</div>
						)}

						<div className="card_button">
							<button className="primary_button" onClick={toggler}>
								+ Add Admin
							</button>
						</div>
					</div>
				</div>
			);
		}
	}
);

export default InfoHeaderForAdmin;
