import React, { useState } from 'react'
import { useListClient } from '../../../Hooks/tanqueryHooks';
import ActivityListTable from './ActivityListTable';
import { useNavigate } from 'react-router-dom';

const ActivityClientList = ({ id }) => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({
        ownerId: id,
        pageNumber: 1,
        search: "",
        limit: 20,

    });
    const {
        data,
        isSuccess,
        isLoading,
        isError,
        isPending
    } = useListClient(searchParams);
    const bodyContent = (data) => {
        return (
            <div className="activity-email" onClick={() => navigate(`/dashboard/activity/adminList/${data._id}`)}>
                {" "}
                {data.email}{" "}
            </div>
        );
    };
    if (isLoading || isPending) {
        return "Loading...";
    }
    if (isError) {
        return "Error..."
    }
    if (isSuccess) {
        return (
            <>
                <ActivityListTable data={data?.searchResults} body={bodyContent} />
            </>
        )
    }

}

export default ActivityClientList