import { createSlice } from "@reduxjs/toolkit";
import { getItem } from "../util/helper";
// eslint-disable-next-line
const { password } = getItem("user") || false;
const initialState = {
	loginForm: {
		value: {
			email: "",
			password: "",
		},
		isValid: {
			email: false,
			password: false,
		},
		errorMsg: {
			email: "",
			password: "",
		},
	},
	clientAddForm: {
		value: {
			email: "",
			// phone: "",
			name: "",
		},
		isValid: {
			email: false,
			// phone: false,
			name: false,
		},
		errorMsg: {
			email: "",
			// phone: "",
			name: "",
		},
	},
	OTPForm: {
		value: {},
		isValid: {
			mpin1: false,
			mpin2: false,
			mpin3: false,
			mpin4: false,
			mpin5: false,
			mpin6: false,
		},
		errorMsg: {},
	},

	oldPassworddata: {
		value: {
			currentPassword: "",
		},
		isValid: {
			currentPassword: false,
		},
		errorMsg: {
			currentPassword: "",
		},
	},

	passwordResetForm: {
		value: {
			// currentPassword: password,
			newPassword: "",
			confirmPassword: "",
		},
		isValid: {
			// currentPassword: "",
			newPassword: false,
			confirmPassword: false,
		},
		errorMsg: {
			// currentPassword: "",
			newPassword: "",
			confirmPassword: "",
		},
	},
};

const formDataSlice = createSlice({
	name: "formData",
	initialState,
	reducers: {
		updateFormData: (state, action) => {
			const { form, value, isValid, errorMsg } = action.payload;
			state[form].value = { ...state[form].value, ...value };
			state[form].isValid = { ...state[form].isValid, ...isValid };
			state[form].errorMsg = { ...state[form].errorMsg, ...errorMsg };
		},
		clearForm: (state, action) => {
			const { form } = action.payload;
			state[form] = initialState[form];
		},
	},
});

export default formDataSlice.reducer;
export const { updateFormData, clearForm } = formDataSlice.actions;
