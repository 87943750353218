import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useTokenVerify } from "../Hooks/tanqueryHooks";
import Loader from "./Loader";
export default function SessionMiddleWare({ children }) {
	// eslint-disable-next-line
	// console.log("session redered");
	const { role, tfaStatus, isTFADone } = useSelector((state) => state.auth);
	const { token } = useSelector((state) => state.auth);
	const { isError, isLoading, isSuccess } = useTokenVerify();
	// debugger
	if (!token) {
		//console.log("token is null", null);
		return children;
	}
	if (isLoading) {
		//console.log("loading");

		return (
			<section className="login_page">
				<Loader />
			</section>
		);
	}
	if (isError) {
		//console.log(token);

		//console.log("error");

		return <Navigate to="/expired" />;
	}
	if (isSuccess) {

		//console.log("success");
		return (


			<Navigate
				to={
					tfaStatus && !isTFADone ? "tfa" :

						role === "superadmin"
							? "/dashboard/adminList"
							: "/dashboard/clientList"
				}
				replace={true}
			/>
		);
		// dispatch(setLogout());
		// window.history.replaceState(null, "", -1);
	} else {
		return children;
	}
	// eslint-disable-next-line
}
