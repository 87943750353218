import React from "react";
import Label from "./Label";
import useValidator from "../util/useValidator";
import { useSelector } from "react-redux";
import $ from "jquery";

function Input({
  divClassName,
  labelName,
  labelClassName,
  inputType,
  inputId,
  inputName,
  maxLength,
  autoComplete,
  inputClassName,
  focus,
  pClassName,
  req,
  form,
  value,
  validation,
  readOnly,
  hint,
}) {
  const otpform = useSelector((state) => state.formData[form]);

  const { state, setState } = useValidator();
  const inputError = state[form].errorMsg[inputName];
  const { isValid } = state[form];

  let isFormValid;

  if (Object.keys(isValid).length === 0) isFormValid = false;
  else isFormValid = Object.values(isValid).every((value) => value === true);

  const handleValidation = (e) => {
    setState(e);
  };

  const handleKeyUp = (e) => {
    if (isFormValid && e.key === "Enter") {
      $("#auto-submit").trigger("click");
      $("#submit").trigger("click");
    }

    if (e.keyCode === 13) {
      $("#resetPass").trigger("click");
    }

    if (validation === "otp") {
      const inputs = $(".modal_body").find("input");

      if (otpform.isValid[inputId]) {
        const currentIndex = inputs.index(e.target);
        const nextElement = inputs.eq(currentIndex + 1);

        if (nextElement.length > 0) {
          nextElement.trigger("focus");
        }
      }

      if (e.key === "Backspace") {
        const currentIndex = inputs.index(e.target);
        const previousElement = inputs.eq(currentIndex - 1);

        if (currentIndex > 0 && previousElement.length > 0) {
          previousElement.trigger("focus");
        }
      }

      if (isFormValid) {
        if (true) {
          $("#otp_error_msg").html("");
          setTimeout(() => {
            $("#auto-submit").trigger("click");
          }, 50000);
        }
      } else {
        $("#otp_error_msg").html("Please Provide a Valid M-Pin");
      }
    }
  };

  return (
    <div className={divClassName}>
      <Label
        className={labelClassName}
        name={labelName}
        text={
          <span>
            {labelName} {req ? <span style={{ color: "red" }}>*</span> : ""}
          </span>
        }
      />

      <input
        type={inputType}
        id={inputId}
        name={inputName}
        onChange={handleValidation}
        onKeyUp={handleKeyUp}
        maxLength={maxLength}
        value={value}
        autoComplete={autoComplete}
        className={inputClassName}
        autoFocus={focus}
        data_form={form}
        data_validation={validation}
        readOnly={readOnly}
        spellCheck="false"
      />

      {!validation === "otp" ? "" : <p className={pClassName}>{inputError}</p>}
    </div>
  );
}

export default Input;
