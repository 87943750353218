import { dateAndTime, funDate } from "../../../util/helper";
import Loader from "../../../components/Loader";
import { useParams } from "react-router-dom";
import { useGetAllMessages } from "../../../Hooks/tanqueryHooks";
import { useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux";
import createSocketService from "../../../services/socketService";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
const SMSDetailedView = ({
	size,
	newNotification,
	textNotify,
	setError,
}) => {
	// console.log("smsDetailView rendered");
	const queryClient = useQueryClient();
	const chatContainerRef = useRef(null);
	var { id: userId } = useParams();
	const { mutate, isPending, isSuccess, data, isError } = useGetAllMessages()
	const reative = useSelector(state => state.reactiveParams)
	const { token } = useSelector(state => state.auth);
	const { socket } = createSocketService(token);
	const contentRef = useRef(null);
	const [sectionHeight, setSectionHeight] = useState(600);

	useEffect(() => {
		// console.log("mutation called");
		reative.activeThread && mutate({ userId, thread_id: reative.activeThread })
	}, [mutate, reative, userId])

	// This function insert the new received message to the view.
	const handleNotifyEvent = (socketData) => {
		if (socketData.status) {
			const threadId = reative?.activeThread;
			queryClient.setQueryData(["get-all-messages", threadId], (data) => {
				if (data && socketData?.msg?.thread_id === threadId) {
					// console.log("inserting message");
					return {
						...data,
						response: data?.response ? [...data?.response, socketData?.msg] : [socketData?.msg]
					};
				}
				return data;
			});
			handleUpdateThreadDetails(socketData?.threadDetails)

		}
	};
	// This function update the count to the thread which is recieved in the socket.
	const handleUpdateThreadDetails = (threadDetails) => {
		// console.log(threadDetails, "++++++++=======================threadDetails");

		queryClient.setQueryData(["get-all-thread"], (data) => {
			let threadData = data?.data;
			// console.log("updaed");
			// console.log(threadData, "++++++++=======================threadData");

			let modified = threadData.filter(d => d.thread_id !== threadDetails.thread_id)
			modified = [threadDetails, ...modified]

			return {
				...data,
				data: modified
			}


		});
	}
	useEffect(() => {
		chatContainerRef?.current?.scrollTo({
			left: 0,
			top: chatContainerRef?.current?.scrollHeight,
			behavior: 'smooth'
		})
	}, [data])

	useLayoutEffect(() => {
		socket?.connect()
		socket?.on("notification", (data) => {
			// console.log("notification", data);
			handleNotifyEvent(data);
		});
		return () => {
			socket?.off(handleNotifyEvent)
			socket?.disconnect()
		}
	}, [reative])

	const resetNewMessageCountInGetAllThread = () => {
		queryClient.setQueryData(["get-all-thread"], (data = {}) => {
			const { data: thread_data } = data;
			const modified = thread_data.map(_d => {
				let d = _d;
				if (d.thread_id === reative.activeThread) {
					d.new_sms = false;
					d.new_sms_count = 0;
				}
				return _d;

			})

			return {
				...data,
				data: modified
			}
		})
	}

	// const { isLoading, isPending, isSuccess, data, isError } =
	// 	useTanQuery(queryOptions);

	// with resize event listener
  useEffect(() => {
		const chatDataTop = document.querySelector(".chatData")?.getBoundingClientRect().top;
		const chatDisplayBottom = document.querySelector(".displayFunction")?.getBoundingClientRect().bottom;
		setSectionHeight(chatDisplayBottom - chatDataTop);
    const handleResize = () => {
			const chatDataTop = document.querySelector(".chatData")?.getBoundingClientRect().top;
		const chatDisplayBottom = document.querySelector(".displayFunction")?.getBoundingClientRect().bottom;
		setSectionHeight(chatDisplayBottom - chatDataTop);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

	if (isPending) {
		<div ref={contentRef} className="chatContent" style={{ height: sectionHeight - 30 }}>
			<div className="contHeaderName">Messages</div>
			<div ref={chatContainerRef} className="chats">
				<div className="loaderDisplay">
					<Loader />
				</div>
			</div>
		</div>;
	}
	if (isError || (isSuccess && (!data.status))) {
		return setError({ status: true, type: 0 });
	}
	// if (isSuccess) {
	// 	console.log(data);
	// 	return <h1>Success</h1>;
	// }
	if (isSuccess) {
		// console.log(data);
		// resetNewMessageCountInGetAllThread()
		const smsData = data?.response;
		// console.log(smsData);
		return (
			<div className="chatContent" style={{ height: sectionHeight - 30 }}>
				<div className="contHeaderName">Messages</div>
				<div ref={chatContainerRef} className="chats">
					<>
						{smsData?.map((chat) => (
								<div key={chat._id}>
									{chat.type === 1 ? (
										<div>
											<div className="receiverSide">
												<div
													className={
														chat._id === textNotify
															? newNotification
																? "newDataReceiver"
																: "receiverMessage"
															: "receiverMessage"
													}
												>
													{chat.body}
												</div>
											</div>

											<div className="receiverSideText">
												<div className="receiverTime">
													{chat.createdBy ? dateAndTime(chat.date) : ""}
												</div>
												<div className="simShows">
													{chat.sim_slot === 2 ? (
														<>
															<div className="simTwo">
																<div>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24"
																		className="simDi"
																	>
																		<g>
																			<path
																				fill="transparent"
																				d="M0 0h24v24H0z"
																			/>
																			<path
																				fill="currentColor"
																				d="M15 2l4.707 4.707a1 1 0 0 1 .293.707V21a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10zm-3 5.5a3 3 0 0 0-2.995 2.824L9 10.5h2a1 1 0 1 1 1.751.66l-.082.083L9 14.547 9 16h6v-2h-2.405l1.412-1.27-.006-.01.008.008A3 3 0 0 0 12 7.5z"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</>
													) : (
														<>
															<div className="simOne">
																<div>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24"
																		className="simDi"
																	>
																		<g>
																			<path
																				fill="transparent"
																				d="M0 0h24v24H0z"
																			/>
																			<path
																				fill="currentColor"
																				d="M15 2l4.707 4.707a1 1 0 0 1 .293.707V21a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10zm-2 6h-3v2h1v6h2V8z"
																			/>
																		</g>
																	</svg>
																</div>
																<div></div>
															</div>
														</>
													)}
												</div>
											</div>
										</div>
									) : (
										<div>
											<div className="senderSide">
												<div
													className={
														chat._id === textNotify
															? newNotification
																? "newDataSender"
																: "senderMessage"
															: "senderMessage"
													}
												>
													{chat.body}
												</div>
											</div>
											<div className="senderSideText">
												<div className="simShows">
													{chat.sim_slot === 2 ? (
														<>
															<div className="simTwo">
																<div>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24"
																		className="simDi"
																	>
																		<g>
																			<path
																				fill="transparent"
																				d="M0 0h24v24H0z"
																			/>
																			<path
																				fill="currentColor"
																				d="M15 2l4.707 4.707a1 1 0 0 1 .293.707V21a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10zm-3 5.5a3 3 0 0 0-2.995 2.824L9 10.5h2a1 1 0 1 1 1.751.66l-.082.083L9 14.547 9 16h6v-2h-2.405l1.412-1.27-.006-.01.008.008A3 3 0 0 0 12 7.5z"
																			/>
																		</g>
																	</svg>
																</div>
															</div>
														</>
													) : (
														<>
															<div className="simOne">
																<div>
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		viewBox="0 0 24 24"
																		className="simDi"
																	>
																		<g>
																			<path
																				fill="transparent"
																				d="M0 0h24v24H0z"
																			/>
																			<path
																				fill="currentColor"
																				d="M15 2l4.707 4.707a1 1 0 0 1 .293.707V21a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10zm-2 6h-3v2h1v6h2V8z"
																			/>
																		</g>
																	</svg>
																</div>
																<div></div>
															</div>
														</>
													)}
												</div>
												<div className="senderTime">
													{chat.createdBy ? funDate(chat.date) : ""}
												</div>
											</div>
										</div>
									)}
								</div>
						))}
					</>
				</div>
				<div>
					<p></p>
				</div>
			</div>
		);
	}
};

export default SMSDetailedView;
