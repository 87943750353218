import { useEffect, useState } from "react";

export default function useWindowSize() {
  // Initialize state with a default height
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight || 600, // Set a default height or use another fallback value
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight || 600, // Set a default height or use another fallback value
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that the effect is only run on mount
  return windowSize;
}
