// eslint-disable-next-line
import React, { Children } from "react";

export default function ModalBody({ modal_grid, children }) {
  return (
    <div className="modal_body">
      <div className={modal_grid} style={{ height: "100%" }}>{children}</div>
    </div>
  );
}
