import React from "react";

function ResetIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3558 13.7374V12.592C20.3558 11.2023 19.2292 10.0757 17.8395 10.0757H7.77415C6.38441 10.0757 5.25781 11.2023 5.25781 12.592V18.8829C5.25781 20.2726 6.38441 21.3992 7.77415 21.3992H10.8068"
            stroke="#36BD3C"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            d="M8.32422 9.51516V7.30827C8.32422 6.11773 8.79716 4.97595 9.639 4.13411V4.13411C11.392 2.38108 14.2343 2.38108 15.9873 4.13411V4.13411C16.8291 4.97595 17.3021 6.11773 17.3021 7.30827V9.51516"
            stroke="#36BD3C"
            strokeWidth="1.5"
          />
          <path
            d="M18.9243 18.4158H21.1492M21.1492 18.4158V16.1913M21.1492 18.4158C20.6353 17.7851 20.2284 17.3147 19.7358 16.8159C19.224 16.3041 18.5727 15.9545 17.8633 15.8107C17.1539 15.6669 16.4179 15.7354 15.7472 16.0075C15.0765 16.2796 14.5009 16.7434 14.0923 17.3408C13.6836 17.9382 13.4601 18.6428 13.4496 19.3665C13.4391 20.0903 13.6421 20.801 14.0333 21.41C14.4244 22.0191 14.9864 22.4993 15.6489 22.7908C16.3114 23.0822 17.0451 23.172 17.7584 23.0488C18.4716 22.9257 19.1328 22.5951 19.6592 22.0983"
            stroke="#36BD3C"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default ResetIcon;
