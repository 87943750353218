import React, { useState } from 'react'
import { MdOutlineContentCopy } from 'react-icons/md';
import Buttons from './Buttons';
import apiCaller from '../util/apiCaller';
import { Tooltip } from 'react-tooltip'
import swal from "sweetalert";

const AutoPasswordResetForAdmin = ({
    adminName,
    paragraphRef,
    handleCloseResetPasswordForm,
    manualGenerate
}) => {
    const [displayAutoPassword, setDisplayAutoPassword] = useState(false);
    const [autoPass, setAutoPass] = useState("");
    const [copyConent, setCopyContent] = useState(true);


    const autoGenerate = async () => {
        // console.log("auto Generate");
        setDisplayAutoPassword(true);
        // const data = {
        // 	_id: adminUserId,
        // 	activity: "Password udpated",
        // };
        try {
            const responseChat = await apiCaller({
                url: "createPassword",
                method: "GET",
            });
            let displayData = responseChat.data.response;
            setAutoPass(displayData);
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const copyToClipboard = () => {
        // Create a range object
        const range = document.createRange();
        // Select the text content of the paragraph
        range.selectNode(paragraphRef.current);

        // Create a selection object
        const selection = window.getSelection();
        // Remove any existing selections
        selection.removeAllRanges();
        // Add the new range to the selection
        selection.addRange(range);

        // Execute the copy command
        document.execCommand("copy");

        // Clear the selection
        selection.removeAllRanges();
        setCopyContent(false);
    };
    const withoutCopy = () => {
        // Executed
        if (copyConent) {
            swal({
                title: "Warning",
                text: "Dont Forget to copy the Auto Generated Password",
                icon: "info",
                button: false,
                timer: 2000,
            });
        } else {
            manualGenerate(autoPass);
        }
    };
    const resetFormButtonConfig = [
        {
            text: "Sure",
            id: "submit",
            className: "modal_btn btn_confirm",
            onClick: [[], [autoGenerate]],
        },
        {
            text: "Cancel",
            id: "cancel",
            className: "modal_btn btn_close",
            onClick: [[], [handleCloseResetPasswordForm]],
        },
    ];

    const resetPassFormButtonConfig = [
        {
            text: "Confirm",
            id: "submit",
            className: "modal_btn btn_confirm",
            onClick: [[], [withoutCopy]],
        },
        {
            text: "Cancel",
            id: "cancel",
            className: "modal_btn btn_close",
            onClick: [[], [handleCloseResetPasswordForm]],
        },
    ];
    return (
        <div className="password-generator">

            <div className="reset-title">
                {displayAutoPassword ? (
                    <p>
                        Are You Sure, You want Confirm this Password for{" "}
                        {adminName}?
                    </p>
                ) : (
                    <p>Are You Sure, You want Reset {adminName}'s Password?</p>
                )}
            </div>
            <div>
                {displayAutoPassword ? (
                    <>
                        <div className="auto-password-generator">
                            <div>
                                <p>Auto Generated Password </p>
                            </div>
                            <div className="copy-password-text">
                                <p className="copy-password" ref={paragraphRef}>
                                    {autoPass}
                                </p>
                                <MdOutlineContentCopy
                                    onClick={copyToClipboard}
                                    className="copy-clip"
                                    data-tooltip-id="copy-reset-password"
                                    data-tooltip-content={
                                        copyConent
                                            ? "Copy the Password"
                                            : "Password Copied"
                                    }
                                />
                            </div>
                        </div>
                        <Tooltip
                            id="copy-reset-password"
                            place="top"
                            effect="solid"
                        />
                        <div className="rstBut-for-reset">
                            {resetPassFormButtonConfig.map((config, index) => (
                                <Buttons key={index} {...config} />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="copy-password-text-hide"></div>
                        <div className="rstBut-for-reset">
                            {resetFormButtonConfig.map((config, index) => (
                                <Buttons key={index} {...config} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AutoPasswordResetForAdmin;