import React from "react";
import Error_pic_400 from "./400.svg";
import Error_pic_401 from "./401.svg";
import Error_pic_404 from "./404.svg";
import Error_pic_403 from "./403.svg";
import Error_pic_500 from "./500.svg";
import { useLocation, useNavigate } from "react-router-dom";

function Errorpage() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const error_Code = state?.error_Code || "404";
	const redirection = state?.redirection || -1;
	// console.log("error page rendered", error_Code);
	const errorPic = {
		400: Error_pic_400,
		401: Error_pic_401,
		403: Error_pic_403,
		404: Error_pic_404,
		429: Error_pic_403,
		500: Error_pic_500,
	};
	return (
		<>
			<section id="error_page">
				<div className="error_image">
					<div className="error_pict">
						<img
							className="error_img"
							src={errorPic[error_Code]}
							alt="404 Error"
							title="404 Page"
						/>
					</div>
					<div className="error_header">
						<p className="error_head">Oops, Sorry We can't find that page...</p>
						<p className="error_para">
							Either something went wrong or the page doesn't exist anymore.
						</p>
					</div>
					<div className="backto">
						<button
							className="submit"
							onClick={() => {
								navigate(redirection);
							}}
						>
							Go Back
						</button>
					</div>
				</div>
			</section>
		</>
	);
}

export default Errorpage;
