import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../../../slice/paginationSlice';

const ActivityListTable = ({ data, body }) => {
    const dispatch = useDispatch();
    const currentPage = useSelector((state) => state.pagination.currentPage);

    const onPageChange = (event) => {
        dispatch(setCurrentPage(event.page + 1)); // PrimeReact pagination is 0-based
      };
    return (
        // <div>
            <div className='table_wrapper'>
                <DataTable
                    value={data}
                    size="large"
                    showGridlines
                    className="prime-data-table"
                    paginator
                    rows={5}
                    first={(currentPage - 1) * 5} 
                    onPage={onPageChange}
                >
                    <Column header="Name" field="name" sortable></Column>
                    <Column header="Email" field="email" body={body} sortable></Column>
                    <Column header="Role" field="role" sortable></Column>
                </DataTable>
            </div>
        // </div>
    )
}

export default ActivityListTable