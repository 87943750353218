import React from 'react'

const NotificationCount = ({ count = 0 }) => {
    return (
        <div className="ripple_wrapper">
            <div className="counter">{/* {count} */}</div>
        </div>

    )
}

export default NotificationCount