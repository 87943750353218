import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import EmailContainer from "./Email/EmailEmailContainer";
import "../../asset/chatBox/charbox.css";
import NoMail from "../../asset/logo/no_mail.svg";
import ChatHeader from "./chat/ChatHeader";
import SMSContainer from "./SMS/SMSContainer";

const Chat = () => {
	const location = useLocation();
	const [butVisible, setButVisible] = useState(location.pathname.split('/')[3]);
	const [newNotification, setNewNotification] = useState();
	const [textNotify, setTextNotify] = useState("");
	// eslint-disable-next-line
	var { pValue, id } = useParams();
	const navigate = useNavigate();
	const visibleSMS = () => {
		setButVisible('chat');
		navigate(`/dashboard/chat/chat/${id}`);
	};

	const visibleEmail = () => {
		setButVisible('email');
		navigate(`/dashboard/chat/email/${id}`);
	};

	useEffect(() => {
		pValue === "chat"
			? setButVisible('chat')
			: pValue === "email"
				? setButVisible('email')
				: setButVisible('chat');
	}, [pValue]);

	return (
		<>
			{/* ======================  Meta Tags ======================  */}
			<Helmet>
				<title>autoaudit.ai &#10072; SMS Page </title>
			</Helmet>
			{/* ======================  Meta Tags ======================  */}

			<div className="displayFunction">
				{/* <div className="headerPart"> */}
					{/* <div className="login_user">
						<div className="client_circle">
							<CustomAvatar name={name} />
						</div>
						<div className="company_header">
							<p className="login_username">
								<i>Welcome</i>
							</p>
							<p className="log_person">{name} </p>
						</div>
					</div> */}
				{/* </div> */}

				<div className="chatBoxpage">
					<ChatHeader
						butVisible={butVisible}
						visibleSMS={visibleSMS}
						visibleEmail={visibleEmail}
					/>

					<div className="chatData">
						{butVisible === 'chat' ? (
							<SMSContainer
								textNotify={textNotify}
								newNotification={newNotification}
								NoMail={NoMail}
							/>
						) : (
							<div>
								<EmailContainer />
							</div>
						)}
					</div>
				</div>
				<div></div>
			</div>
		</>
	);
};

export default Chat;
