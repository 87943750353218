import React from 'react';
import Avatar from 'react-avatar';

export default function CustomAvatar(props) {

  return (
    <Avatar
    {
      ...props
    }
    size={42}
    round={true}
    maxInitials={2}
    textSizeRatio={2}
    color="#4bab7d" 
    style ={{ fontFamily: "inherit"}}
    />
  )
}
