import React from "react";
import { useDispatch } from "react-redux";
import { updateFormData } from "../slice/formDataSlice";
import { errorTrigger, submitter, toggle, checkArray } from "../util/helper";
import $ from "jquery";

export default function Buttons({
	text,
	className,
	name,
	id,
	datavalue,
	form,
	onClick,
	isValidObject = {},
	disabled = false,
}) {
	const dispatch = useDispatch();

	const isValid = isValidObject;

	let isFormValid;

	// if the isValid object is empty then set the  isFormValid to false cause for empty object every method will return true but we need to  prevent it.
	if (Object.keys(isValid).length === 0) isFormValid = false;
	else isFormValid = Object.values(isValid).every((value) => value === true);

	// This function only execute on button click && when the form get validate  && only while the isLogged in is false
	const handleSubmit = (e) => {
		if (checkArray(onClick, 0)) {
			if (isFormValid) {
				submitter(e, onClick[0]);
			} else if (!isFormValid) {
				errorTrigger(form, dispatch, updateFormData, isValid);
			}
		}
		if (isFormValid) {
			$("#otp_error_msg").html("");
		} else {
			$("#otp_error_msg").html("Please Provide a Valid M-Pin");
		}
	};

	// This function get excute the 2nd array of onClick array prop whenever the button get clicked && not concern about the form validation and also the the props has the && onClick props array has the  value of "close"
	const handleToggle = (e) => {
		if (checkArray(onClick, 1)) {
			toggle(e, onClick[1]);
		}
	};

	// This function is executed only whenever the form is not valid and onclick props has the valu of sumbit ,if it is has the value of submit then the 3rd array in the onclik prop get executed

	return (
		<button
			className={className}
			onClick={(e) => {
				handleSubmit(e);
				handleToggle(e);
			}}
			name={name}
			id={id}
			datavalue={datavalue}
			data_form={form}
			disabled={disabled}
		>
			{text}
		</button>
	);
}
