import React from 'react'
import useWindowSize from '../../../Hooks/useWindowSize';
import { NavLink } from 'react-router-dom';

const SettingsSideMenu = () => {
    const size = useWindowSize();
    const activeOptionSettings = true
    const handleOptionSelect = () => {
        // console.log("boooo");
    }
    return (
        // <div className="settingsMenu" style={{ height: size.height - 280 }}>
        //     <div
        //         className={`changepasswod ${activeOptionSettings === "resetPassword" ? "active" : ""
        //             }`}
        //         onClick={() => handleOptionSelect("resetPassword")}
        //     >
        //         <p>Reset Password</p>
        //     </div>
        //     <div
        //         className={`changepasswod ${activeOptionSettings === "twoFactorAuth" ? "active" : ""
        //             }`}
        //         onClick={() => handleOptionSelect("twoFactorAuth")}
        //     >
        //         <p>Two Factor Authentication</p>
        //     </div>
        //     {/* {roleDetails === "superadmin" ? (
        // 				<div
        // 					className={`changepasswod ${
        // 						activeOptionSettings === "category" ? "active" : ""
        // 					}`}
        // 					onClick={() => handleOptionSelect("category")}
        // 				>
        // 					<p>Category</p>
        // 				</div>
        // 			) : (
        // 				""
        // 			)} */}
        //     {/* <div
        //     className={`changepasswod ${
        //       activeOptionSettings === "bankList" ? "active" : ""
        //     }`}
        //     onClick={() => handleOptionSelect("bankList")}
        //   >
        //     <p>Bank List</p>
        //   </div> */}
        // </div>
        <div className="settingsMenu" style={{ height: '100%'}}>
            <nav className="">
                <div className="centered-list ">
                    <NavLink to="/dashboard/settings/resetPassword" className={({ isActive }) => isActive ? "link active" : "link"} end >Reset Password</NavLink>

                    <NavLink to="/dashboard/settings/tfa" className={({ isActive }) => isActive ? "link active" : "link"} >Two Factor Authentication</NavLink>

                </div>
            </nav >




        </div >
    )
}

export default SettingsSideMenu