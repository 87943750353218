// socketService.js
import io from "socket.io-client";

class SocketService {
	constructor(token) {
		// console.log("socketService==============================================", token);
		this.socket = io(process.env.REACT_APP_SOCKET_URL, {
			auth: {
				token: token,
			},
		});
		this.reconnectTimeout = null;

		this?.socket?.on("connect", () => {
			// console.log("socket connected");
			if (this.reconnectTimeout) {
				clearTimeout(this.reconnectTimeout);
				this.reconnectTimeout = null;
			}
		});
		this?.socket?.on("connect_error", (err) => {
			this.reconnectTimeout = setTimeout(() => {
				this.socket.connect();
			}, 5000);
			// console.log("Socket connection error ", err);
		});
		this?.socket?.on("disconnect", () => {
			// console.log("Disconnected");
			if (this.reconnectTimeout) {
				clearTimeout(this.reconnectTimeout);
				this.reconnectTimeout = null;
			}
		});
	}

	connect() {
		this?.socket?.connect();
	}

	disconnect() {
		// console.log("Disconnection called");
		this?.socket?.disconnect();
	}
}

let socketServiceInstance = null;

// Function to create SocketService instance with token
export const createSocketService = (token) => {
	// console.log("socket instance  created");
	if (!token) {
		return null;
	}
	if (!socketServiceInstance) {
		socketServiceInstance = new SocketService(token);
	}
	return socketServiceInstance;
};

// Function to clear the SocketService instance
export const clearSocketInstance = () => {
	if (socketServiceInstance) {
		socketServiceInstance.disconnect();
		socketServiceInstance = null; // Reset the singleton instance
		// console.log("Socket instance cleared");
	}
};

// Export the function to create SocketService instance
export default createSocketService;
