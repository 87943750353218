import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet, useLocation } from "react-router-dom";
import ActivitySideMenu from "./component/ActivitySideMenu";
import useWindowSize from "../../Hooks/useWindowSize";
import { motion } from "framer-motion";
const ActivityLayout = () => {
	const location = useLocation();
	const size = useWindowSize();
	const [sectionHeight, setSectionHeight] = useState(400);
	const [isFirst, setIsFirst] = useState(true);
	const contentRef = useRef(null);
	useEffect(() => {
		if(contentRef){
			const fixHeightElement = document.querySelector(".settingsParti");
			if(fixHeightElement){
				const fixHeightElementTop = fixHeightElement.getBoundingClientRect().top;
				const bodySection = document.querySelector(".body_section");
				const bodySectionheight = bodySection.getBoundingClientRect().height;
				const headerSection = document.querySelector(".header_section");
				const headerSectionHeight = headerSection.getBoundingClientRect().height;
				const newHeight = bodySectionheight - fixHeightElementTop - headerSectionHeight;
				setSectionHeight(newHeight);
			}
		}
	}, []);

	// with resize event listener
  useEffect(() => {
    const handleResize = () => {
			if(contentRef){
				const fixHeightElement = document.querySelector(".settingsParti");
				if(fixHeightElement){
					const fixHeightElementTop = fixHeightElement.getBoundingClientRect().top;
					const bodySection = document.querySelector(".body_section");
					const bodySectionheight = bodySection.getBoundingClientRect().height;
					const headerSection = document.querySelector(".header_section");
					const headerSectionHeight = headerSection.getBoundingClientRect().height;
					const newHeight = bodySectionheight - fixHeightElementTop - headerSectionHeight;
					setSectionHeight(newHeight);
				}
			}
    };
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sectionHeight]);

	return (
		<>
			{/* ======================  Meta Tags ======================  */}
			<Helmet>
				<title>autoaudit.ai &#10072; Activity Page </title>
			</Helmet>
			{/* ======================  Meta Tags ======================  */}
			<div className="settingsHeader">Activities</div>
			<div ref={contentRef} className="settingsParti" style={{ height: (sectionHeight) }}
			>
				{/* Settings Menu Start */}
				<ActivitySideMenu />
				<div className="settingsOptions" style={{ height: '100%' }}>
					<Outlet />
				</div>
				{/* Settings Menu End */}
			</div>
		</>
	);
};

export default ActivityLayout;
