import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import Contact from "./Contact";
import swal from "sweetalert";
import "react-phone-number-input/style.css";
import Loader from "../../components/Loader";
import { mblNotSync } from "../../util/swalConfigs";
import useWindowSize from "../../Hooks/useWindowSize";
import EmptyMemberList from "./EmptyMemberList";
import ListHeader from "./components/ClientListHeader";
import ErrorPic from "../../ErrorPage/500_error.svg";



function ClientList({
	token,
	data: responseData,
	isSuccess,
	isLoading,
	isError,
	toggler,
	otpToggler,
	setSingleCurrentId,
	handleClientModalOpen,
	refetch,
}) {
	const size = useWindowSize();
  const [listOfContactsHeight, setListOfContactsHeight] = useState(null)
	const [sectionHeight, setSectionHeight] = useState(600);
	const contentRef = useRef(null);
	//ClientList Show
	useEffect(() => {
		const handleClickOutside = (event) => {
			// setSearchCls(false);
		};

		$(document).on("click", handleClickOutside);

		return () => {
			$(document).off("click", handleClickOutside);
		};
	}, []);

	const verifier = (pinStatus, id, adminVerfified, name, email, blocked) => {
		setSingleCurrentId(id);
		pinStatus
			? !adminVerfified && otpToggler()
			: // ? navigate("")
			// : // navigate(`/dashboard/charts`)
			swal(mblNotSync);
	};

	// Handle api success

	// Scroll to bottom

	// without resize event listener
	useEffect(() => {
		if(contentRef){
			const fixHeightElement = document.querySelector(".fix_height");
			if(fixHeightElement){
				const fixHeightElementTop = fixHeightElement.getBoundingClientRect().top;
				const bodySectionBottom = document.querySelector(".body_section")?.getBoundingClientRect().bottom;
				const cardHeight = document.querySelector(".list_of_contact")?.getBoundingClientRect().height;
				const newHeight = bodySectionBottom - fixHeightElementTop - (cardHeight/2)
				setSectionHeight(newHeight);
			}
		}
	});

	// with resize event listener
  useEffect(() => {
    const handleResize = () => {
			if(contentRef){
				const fixHeightElement = document.querySelector(".fix_height");
				if(fixHeightElement){
					const fixHeightElementTop = fixHeightElement.getBoundingClientRect().top;
					const bodySectionBottom = document.querySelector(".body_section")?.getBoundingClientRect().bottom;
					const cardHeight = document.querySelector(".list_of_contact")?.getBoundingClientRect().height;
					const newHeight = bodySectionBottom - fixHeightElementTop - (cardHeight/2)
					setSectionHeight(newHeight);
				}
			}
    };
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sectionHeight]);


	if (isLoading)
		return (
			<div className="fix_height_wrapper">
				<div className="fix_height">
					<Loader />
				</div>
			</div>
		);

	if (isError) {
		return (
			<div className="fix_height_wrapper">
				<div className="fix_height">
					<div className="list-error">
						<img className="list-error-img" src={ErrorPic} alt="Server Error " />
						{/* <p>Unable to load Clients data, Please try after some time</p> */}
					</div>
				</div>
			</div>
		);
	}

	if (isSuccess)
		return (
			<>
				{/* If the admin has no clients to manage then the below +Add client template get rendered */}
				{isSuccess && responseData?.hasRecords === false ? (
					<div className="new_addClientd">
						<EmptyMemberList toggler={toggler} />
						<div className="new_addClients">
							<button
								className="primary_button"
								onClick={handleClientModalOpen}
							>
								+ Add Client
							</button>
						</div>
					</div>
				) : (
					<div className="fix_height_wrapper">
            <div className="fix_height">
              {/* If the search response has client then the list will get rendered */}
              {responseData?.response?.length > 0 ? (
                responseData?.response?.map((client) => (
                  <Contact
                    token={token}
                    key={client._id}
                    {...client}
                    data={verifier}
                    funCall={refetch}
                    setListOfContactsHeight={setListOfContactsHeight}
                  />
                ))
              ) : (
                // If there is no clients match the search query then the no data found get rendered
                <div className="no_data_list">
                  <p className="no_data_para">No Data Found...</p>
                </div>
              )}
            </div>
					</div> 
				)}
			</>
		);
}

export default ClientList;
