import React from "react";

function ShareIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9608 6.68552C18.9608 8.07552 20.3408 10.2855 20.6208 12.8355M3.49078 12.8855C3.61651 11.6627 4.00289 10.481 4.62389 9.42014C5.24489 8.35926 6.08609 7.44382 7.09078 6.73552M8.19078 21.4555C9.35078 22.0455 10.6708 22.3755 12.0608 22.3755C13.4008 22.3755 14.6608 22.0755 15.7908 21.5255M12.0608 8.21552C12.7981 8.21552 13.5052 7.92263 14.0265 7.40127C14.5479 6.87992 14.8408 6.17282 14.8408 5.43552C14.8408 4.69822 14.5479 3.99111 14.0265 3.46976C13.5052 2.94841 12.7981 2.65552 12.0608 2.65552C11.3235 2.65552 10.6164 2.94841 10.095 3.46976C9.57367 3.99111 9.28078 4.69822 9.28078 5.43552C9.28078 6.17282 9.57367 6.87992 10.095 7.40127C10.6164 7.92263 11.3235 8.21552 12.0608 8.21552ZM4.83078 20.4355C5.56808 20.4355 6.27519 20.1426 6.79654 19.6213C7.31789 19.0999 7.61078 18.3928 7.61078 17.6555C7.61078 16.9182 7.31789 16.2111 6.79654 15.6898C6.27519 15.1684 5.56808 14.8755 4.83078 14.8755C4.09348 14.8755 3.38638 15.1684 2.86502 15.6898C2.34367 16.2111 2.05078 16.9182 2.05078 17.6555C2.05078 18.3928 2.34367 19.0999 2.86502 19.6213C3.38638 20.1426 4.09348 20.4355 4.83078 20.4355ZM19.1708 20.4355C19.9081 20.4355 20.6152 20.1426 21.1365 19.6213C21.6579 19.0999 21.9508 18.3928 21.9508 17.6555C21.9508 16.9182 21.6579 16.2111 21.1365 15.6898C20.6152 15.1684 19.9081 14.8755 19.1708 14.8755C18.4335 14.8755 17.7264 15.1684 17.205 15.6898C16.6837 16.2111 16.3908 16.9182 16.3908 17.6555C16.3908 18.3928 16.6837 19.0999 17.205 19.6213C17.7264 20.1426 18.4335 20.4355 19.1708 20.4355Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default ShareIcon;
