import React, { useRef, useState } from "react";
import Input from "../../components/Input";
import Buttons from "../../components/Buttons";
import "../../asset/settings/settings.css";
import { setLogout } from "../../slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearForm } from "../../slice/formDataSlice";
import swal from "sweetalert";
import { useEffect } from "react";

import { FiEyeOff, FiEye } from "react-icons/fi";
import apiCaller from "../../util/apiCaller";
import Modal from "../../components/Modal";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../components/ModalBody";
import ModalFooter from "../../components/ModalFooter";
import { Formik, Field, Form } from "formik";
import { addAdminResetPasswordSchema, OldpasswordSchema } from "../../validation/Validation";
import Label from "../../components/Label";
import { netWorkError, errorMessageGenerator, serverError } from "../../util/swalConfigs";

function ResetPassword({ resetPassword = true, onChildValueChange }) {
	// eslint-disable-next-line
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const authState = useSelector((state) => state.auth);
	const formState = useSelector((state) => state.formData);
	// const navigate = useNavigate();
	const [formDisplay, setFormDisplay] = useState(true);

	const [warning, setWarning] = useState(false);
	const [passwordIcon, setPasswordIcon] = useState({
		new: true,
		confirm: true,
	});
	const [eyeIcon, setEyeIcon] = useState(false);
	const [ceyeIcon, setCeyeIcon] = useState(false);
	const [neyeIcon, setNeyeIcon] = useState(false);
	const formWrapperRef = useRef(null);
	const [clickInside, setClickInSide] = useState(false)
	const dispatch = useDispatch();
	const { oldPassworddata, passwordResetForm } = formState;
	const form = "passwordResetForm";
	const form1 = "oldPassworddata";
	const [toggle, setToggle] = useState({});
	const clearFormData = (form) => {
		dispatch(clearForm({ form }));
	};

	// ... (previous imports and code)

	const handleSubmit = (values) => {
		// console.log(values);
		// Remove the misplaced .then
		setToggle((prev) => ({ ...prev, readOnlyForm: true }));
		dispatch(clearForm({ form }));
		let data = {
			newPassword: values.newPassword,
			confirmPassword: values.confirmPassword,
		};

		// Check if onChildValueChange is provided as a prop and it's a function
		if (onChildValueChange && typeof onChildValueChange === "function") {
			onChildValueChange(values.newPasswordessag, true); // Pass the data you want to send to the parent
		} else {
			// The rest of your axios post logic...
			apiCaller({ url: "adminchangepassword", data })
				.then(({ data }) => {
					setLoading(false);
					setWarning(false);
					if (data.status === true) {
						swal({
							title: "Success!",
							text: "Password Changed Successfully",
							icon: "success",
							button: false,
							timer: 2000,
						}).then(() => {
							dispatch(setLogout());
						});
					}
				})
				.catch((error) => {
					// console.log(error);
					if (error.message === "Network Error") {
						swal(netWorkError);
						dispatch(clearForm({ form }));
					}
				});
		}
		// }
	};

	// ... (rest of the code)

	useEffect(() => {
		clearFormData(formState[form].value["currentPassword"]);
		// eslint-disable-next-line
		setFormDisplay(resetPassword);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		document.addEventListener("click", (e) => {
			if (e.target.closest('.resetPass')) {
				// console.log("click inside form");
				setClickInSide(true)
			} else {
				// console.log("click outside of form");
				setClickInSide(false)

			}
		})
	}, []);

	const handleCancel = () => {
		if (!resetPassword) {
			// console.log("object", resetPassword);
			onChildValueChange("", true); // Pass the data you want to send to the parent
		} else {
			setEyeIcon(false);
			clearFormData(form);
			setFormDisplay(true);
		}
	};

	const handleRest = () => {
		setCeyeIcon(false);
		setNeyeIcon(false);
		let currentPassword = formState[form].value["Current Password"];
		let currentPasswordData = { oldpassword: currentPassword };

		apiCaller({ url: "adminverifypassword", data: currentPasswordData })
			.then(({ data }) => {
				setLoading(false);
				if (data.status === true) {
					setFormDisplay(false);
					dispatch(clearForm({ form }));
				} else {
					dispatch(clearForm({ form }));

					if (currentPasswordData.oldpassword === undefined) {
						swal({
							title: "Alert",
							text: "Kindly provide your current password",
							icon: "warning",
							button: false,
							timer: 2000,
						});
					} else {
						swal({
							title: "Incorrect Password!",
							text: "Kindly Enter A Valid Current Password",
							icon: "warning",
							button: false,
							timer: 2000,
						});
					}
				}
			})
			.catch((error) => {
				const response = error?.response;
				let config;
				switch (response?.status || 500) {
					case 500:
						config = serverError;
						break;
					default:
						config = errorMessageGenerator(response?.data?.message);
						break;
				}
				swal(config);
				// console.log(error.response);

			});
	};

	const warningFunction = (validateForm) => {
		validateForm().then((res) => {
			if (Object.keys(res).length === 0) setWarning(!warning);
		});
	};

	const cancelModal = (e) => {
		setWarning(false);
	};

	const passwordEye = () => {
		setEyeIcon(!eyeIcon);
	};
	const handleCurrentPasswordSubmit = (values, { resetForm }) => {
		apiCaller({ url: "adminverifypassword", data: values })
			.then(() => {
				setLoading(false);
				setFormDisplay(false);
				resetForm()
			})
			.catch((error) => {
				const response = error?.response;
				let config;
				switch (response?.status || 500) {
					case 500:
						config = serverError;
						break;
					default:
						config = errorMessageGenerator(response?.data?.message);
						break;
				}
				swal(config);
				// console.log(error.response);

			});
	}

	const currentPswdConfig = {
		divClassName: "oldPassword",
		labelName: "Current Password",
		labelClassName: "reset_label",
		labelText: "Current Password",
		inputType: "text",
		inputId: eyeIcon ? "visiblecurrentPassword" : "currentPassword",
		inputName: "Current Password",
		maxLength: 16,
		autoComplete: "off",
		inputClassName: "reset_input",
		focus: false,
		pClassName: "error",
		req: false,
		form,
		value: formState[form].value["currentPassword"],
		validation: "password",
		readOnly: toggle.readOnlyCurrentpswd,
		eye: true,
	};

	const resetFormConfig = {
		divClassName: "oldPassword",
		labelName: "New Password",
		labelClassName: "reset_label",
		labelText: "New Password",
		inputType: "text",
		inputId: neyeIcon ? "visibleNewPassword" : "newPassword",
		inputName: "newPassword",
		maxLength: 16,
		autoComplete: "off",
		inputClassName: "reset_input",
		focus: true,
		pClassName: "error",
		req: true,
		form,
		value: formState[form].value["newPassword"],
		validation: "password",
		readOnly: toggle.readOnlyForm,
	};

	const resetConfirmConfig = {
		divClassName: "oldPassword",
		labelName: "Confirm New Password",
		labelClassName: "reset_label",
		labelText: "Confirm New Password",
		inputType: "text",
		inputId: ceyeIcon ? "visibleconfirmPassword" : "confirmPassword",
		inputName: "confirmPassword",
		maxLength: 16,
		autoComplete: "off",
		inputClassName: "reset_input",
		focus: false,
		pClassName: "error",
		req: true,
		form,
		value: formState[form].value["confirmPassword"],
		validation: "password",
		readOnly: toggle.readOnlyForm,
	};

	const resetButtonConfig = {
		text: "Reset",
		id: "resetPass",
		className: "modal_btn btn_confirm",
		onClick: [[], [handleRest]],
		// datavalue: "1",
		form1,
		isValidObject: oldPassworddata["isValid"],
	};

	const buttonConfigDelete = [
		{
			text: "Confirm",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[], []],
			datavalue: "1",
			form: "clientAddForm",
		},
		{
			text: "Cancel",
			className: "modal_btn btn_close",
			onClick: [[], [cancelModal]],
			datavalue: "1",
			close: true,
			form: "clientAddForm",
		},
	];

	return (
		<>
			<div ref={formWrapperRef} className="resetPass">
				<div className="reset_header_top"> <p className="reset_header">Reset Password</p></div>

				{formDisplay ? (
					<>
						<div>
							<p className="resetPasswordContent">Please input your existing password</p>
						</div>
						<Formik
							initialValues={{ oldpassword: "" }}
							validationSchema={OldpasswordSchema}
							onSubmit={handleCurrentPasswordSubmit}
						>
							{({ values, errors, touched, validateForm, resetForm }) => (
								<>
									<Form>
										<div className="reset_pwd1">

											<div className="oldPassword">
												<Label
													className="reset_label"
													name={"Current Password"}
													text={
														<span>
															Current Password
															<span style={{ color: "red" }}>*</span>

														</span>
													}
												/>
												<Field
													type="text"
													value={values.oldpassword}
													name="oldpassword"

													id={
														eyeIcon ? "visiblecurrentPassword" : "currentPassword"
													}
													maxLength={16}
													autoComplete="off"
													className="reset_input"
													autoFocus={true}
													// readOnly={toggle.readOnlyForm}
													spellCheck="false"
												/>
												<p className="error">
													{errors.oldpassword && touched.oldpassword && clickInside
														? errors.oldpassword
														: ""}
												</p>
											</div>

											<span className="reset_eye" onClick={passwordEye}>
												{" "}
												{eyeIcon ? <FiEye /> : <FiEyeOff />}
											</span>
											<button
												id="resetPass"
												type="submit"
												className="modal_btn btn_confirm"
											// onClick={handleRest}
											>
												Reset
											</button>
										</div>
									</Form>
								</>
							)}
						</Formik>
						{/* <div>
							<div className="resetPasswordContent">
								<p>Please input your existing password</p>
							</div>
						</div>
						<div className="reset_pwd1">
							<Input {...currentPswdConfig} />
							<span className="reset_eye" onClick={passwordEye}>
								{" "}
								{eyeIcon ? <FiEye /> : <FiEyeOff />}
							</span>


							{<Buttons {...resetButtonConfig} />}
						</div> */}
					</>
				) : (
					<>
						<Formik
							initialValues={{ newPassword: "", confirmPassword: "" }}
							validationSchema={addAdminResetPasswordSchema}
							onSubmit={handleSubmit}
						>
							{({ values, errors, touched, validateForm }) => (
								<>
									<Form>
										<div className="resetConfirm">
											<div className="reset_pwd1">
												<div className="reset_pwd">
													<div className="oldPassword">
														<Label
															className="reset_label"
															name={resetFormConfig.labelName}
															text={
																<span>
																	New Password
																	{resetFormConfig.req ? (
																		<span style={{ color: "red" }}>*</span>
																	) : (
																		""
																	)}
																</span>
															}
														/>

														<Field
															type="text"
															value={values.newPassword}
															name="newPassword"
															id={
																passwordIcon.new
																	? "newPassword"
																	: "newPasswordVisible"
															}
															maxLength={16}
															autoComplete="off"
															className="reset_input"
															autoFocus={true}
															// readOnly={toggle.readOnlyForm}
															spellCheck="false"
														/>

														<p className="error">
															{errors.newPassword && touched.newPassword
																? errors.newPassword
																: ""}
														</p>
													</div>
													<span
														className="reset_eye"
														onClick={() =>
															setPasswordIcon((prev) => ({
																...prev,
																new: !prev.new,
															}))
														}
													>
														{" "}
														{passwordIcon.new ? <FiEye /> : <FiEyeOff />}
													</span>
												</div>

												<div className="reset_pwd">
													<div className="oldPassword">
														<Label
															className="reset_label"
															name={resetConfirmConfig.labelName}
															text={
																<span>
																	Confirm New Password
																	{resetConfirmConfig.req ? (
																		<span style={{ color: "red" }}>*</span>
																	) : (
																		""
																	)}
																</span>
															}
														/>

														<Field
															id={
																passwordIcon.confirm
																	? "confirmPassword"
																	: "confirmPasswordVisible"
															}
															name="confirmPassword"
															type="text"
															maxLength={16}
															value={values.confirmPassword}
															autoComplete="off"
															className="reset_input"
															autoFocus={false}
															// readOnly={resetConfirmConfig.readOnly}
															spellCheck="false"
														/>

														<p className="error">
															{errors.confirmPassword && touched.confirmPassword
																? errors.confirmPassword
																: ""}
														</p>
													</div>
													<span
														className="reset_eye"
														onClick={() =>
															setPasswordIcon((prev) => ({
																...prev,
																confirm: !prev.confirm,
															}))
														}
													>
														{" "}
														{passwordIcon.confirm ? <FiEye /> : <FiEyeOff />}
													</span>
												</div>
											</div>
											{resetPassword ? (
												<>
													<div className="passwordCondition">
														<div className="password_condition">
															<p className="textPassCri">
																Password Requirements:
															</p>
															<ul>
																<li>- Include at least 1 uppercase letter.</li>
																<li>- Include at least 1 lowercase letter.</li>
																<li>- Include at least 1 numeric character.</li>
																<li>- Include at least 1 special character.</li>
																<li>- Must be between 8 and 16 characters in length.</li>
															</ul>
															<br></br>
															<p>
																For enhanced security, please make sure your
																chosen password adheres to these criteria.
															</p>
														</div>
													</div>
												</>
											) : (
												<></>
											)}
										</div>
										<div
											className={resetPassword ? "rstBut" : "rstButtonReset"}
										>
											<button
												id="submit"
												type="button"
												className="modal_btn btn_confirm"
												onClick={() => warningFunction(validateForm)}
											>
												Save
											</button>
											<button
												id="cancel"
												type="button"
												className="modal_btn btn_close"
												onClick={handleCancel}
											>
												Cancel
											</button>

											{/* {resetFormButtonConfig.map((config, index) => (
												<Buttons key={index} {...config} />
											))} */}
										</div>
										<div>
											{warning ? (
												<>
													<Modal>
														<ModalHeader
															header="Reset Password"
															onClick={cancelModal}
														/>
														<ModalBody modal_grid="client_body_one">
															<div className="client_delete">
																<p className="deleteHeader">
																	Are you sure you want to Reset the Password?
																</p>
															</div>
															<div></div>
														</ModalBody>
														<ModalFooter
															buttonConfig={buttonConfigDelete}
														></ModalFooter>
													</Modal>
												</>
											) : (
												<></>
											)}
										</div>
									</Form>
								</>
							)}
						</Formik>
					</>
				)}
			</div>
		</>
	);
}

export default ResetPassword;
