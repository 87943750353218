import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
const ClientListWrapperForAdmin = ({ children }) => {
	// console.log("ClientListWrapper redered");

	const authState = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const isAdmin = authState.role === "admin";
	// console.log("isAdmin from clientlistwrapper", isAdmin);

	if (authState.role === "admin") {
		return children;
	} else {
		return <Navigate to="/*"
			replace={true}
			state={{
				error_Code: "403",
				redirection: isAdmin
					? "/dashboard/clientList"
					: "/dashboard/adminList",
			}
			} />
	}
};

export default ClientListWrapperForAdmin;
