import React from "react";
import Buttons from "./Buttons";

export default function ModalFooter({ className, buttonConfig, children }) {
  return (
    <div className={`modal_footer ${className ? className : "right_side"}`}>
      <div className="footer_align">
        {buttonConfig.map((config, key) => (
          <Buttons key={key} {...config} />
        ))}
      </div>
      {children}
    </div>
  );
}
