import React from "react";
import { Outlet } from "react-router-dom";
import HeaderMenu from "../menubar/Headerbar";
import Sidemenu from "../menubar/Sidemenu";
import Header from "../pages/members/components/Header";
import { useSelector } from "react-redux";
function Layout() {
	const authState = useSelector((state) => state.auth);



	return (
		<div>
			<div className="header_section">
				<HeaderMenu
				/>
			</div>
			<div className="sidemenu_section">
				<Sidemenu />
			</div>
			<div className="body_section">
				{/* <div className="client_show_list"> */}
				<Header name={authState.name} />

				<Outlet />
				{/* </div> */}
			</div>
		</div>
	);
}

export default Layout;
