import axios from "axios";
import { getItem, timeAndDate, url } from "./helper";
import { locationProvider } from "./geoLocation";

// // Setting up the default headers for all requests
// try {
// 	const location = await locationProvider();
// 	const locationDetails = JSON.stringify(location);
// 	axios.defaults.headers.common["locationDetails"] = locationDetails;
// } catch (error) {
// 	console.log(error);
// }

// Add a request interceptor
let locationDetails;
try {
	const location = await locationProvider();
	locationDetails = JSON.stringify(location);
	axios.defaults.headers.common["locationDetails"] = locationDetails;
	// console.log("INSIDE TRY CATCH");
} catch (error) {
	// console.log(error);
}
axios.interceptors.request.use(async (config) => {
	// console.log('inside interceptor ');
	const localLocationDetails = getItem("reversedCoordinates");
	// console.log("localLocationDetails", localLocationDetails);
	// console.log("locationDetails", locationDetails);
	if (localLocationDetails) {
		// console.log('inside interceptor local');

		config.headers["locationDetails"] = JSON.stringify(localLocationDetails);
	} else if (locationDetails) {
		// console.log('inside interceptor try catch');

		config.headers["locationDetails"] = locationDetails;
	}

	const { time, datedetails } = timeAndDate();

	// Update the headers with the current time and date details
	config.headers["time"] = time;
	config.headers["datedetails"] = datedetails;

	return config;
}, (error) => {
	// Do something with request error
	return Promise.reject(error);
});
// axios.interceptors.response.use(null, (error) => {
// 	console.warn('from interceptor', error); // log the request details
// 	return Promise.reject(error); // ensure the promise chain keeps failing
// });

export const queryFn = async ({ queryKey }) => {
	const [, queryData] = queryKey;
	// console.log(queryData);
	const { endPoint, params, data, method = "POST" } = queryData;
	try {
		const response = await axios(
			// The following line conditionally sets the url if third party url is used then
			// It will replace the env variable with the passing whole url
			typeof endPoint === "object" && endPoint !== null && endPoint.replace
				? endPoint.url
				: `${process.env.REACT_APP_API_URL}/user/${endPoint}`,
			{
				method,
				headers: {
					Authorization: `Bearer ${getItem("auth") ?? ""}`,
				},

				...(params && {
					params,
				}),

				...(data && {
					data: {
						...data,
					},
				}),
			}
		);
		// if (response.status !== 200) {
		// 	console.log(response);

		// 	throw new Error(`Request failed with status code ${response.status}`);
		// }
		return response.data;
	} catch (error) {
		// console.log(error);
		if (error.response.status === 401) {
			localStorage.removeItem("user");
		}
		throw error; // Re-throw the error to be caught by the caller
	}
};

export const mutationFn = async ({
	endPoint,
	params = null,
	method = "POST",
	data = null,
	body = null,
}) => {
	// console.log("ssss");
	try {
		const options = {
			method,
			headers: {
				Authorization: `Bearer ${getItem("auth") ?? ""}`,
			},
			...(body && {
				body,
			}),
			...(params && { params }),

			...(data && { data }),
		};
		const response = await axios(
			`${process.env.REACT_APP_API_URL}/user/${endPoint}`,
			options
		);
		// if (response.status !== 200) {
		// 	console.log(response);

		// 	throw new Error(`Request failed with status code ${response.status}`);
		// }
		return response.data;
	} catch (error) {
		// console.log(error);
	}
};
const headers = {
	Authorization: `Bearer ${getItem("auth") ?? ""}`,
};
const headersFunc = (token) => ({
	Authorization: `Bearer ${token ?? ""}`,
});
export const addClient = async ({ data, token }) => {
	const response = await axios(url("addClient"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data,
	});
	return response.data;
};
export const listClient = async (data, token) => {
	const response = await axios(url("listClient"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data,
	});
	return response.data;
};

export const deleteClient = async ({ _id, token }) => {
	// console.log("_id", _id, "token", token);
	const response = await axios(url("deleteclient"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data: { id: _id },
	});
	return response.data;
};

export const toggleBlockClient = async ({ data, token }) => {
	const response = await axios(url("unBlocked"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data,
	});
	return response.data;
};
// Query functions for Admin
export const addAdmin = async ({ data, token }) => {
	const response = await axios(url("createAdmin"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data,
	});
	return response.data;
};
export const getAdmin = async (id, token) => {
	const response = await axios(url("getAdmin"), {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		method: "GET",
		params: { id },
	});
	return response.data;
};

export const listAdmin = async (data, token) => {
	const response = await axios(url("listAdmin"), {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		method: "POST",
		data,
	});
	return response;
};
export const deleteAdmin = async ({ data, token }) => {
	const response = await axios(url("deleteAdmin"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data,
	});
	return response.data;
};
export const adminLogin = async (data) => {
	const response = await axios(url("adminLogin"), {
		method: "POST",
		data,
	});
	return response.data;
};
export const adminVerfyMpin = async ({ data, token }) => {
	const response = await axios(url("adminVerify"), {
		headers: {
			...headersFunc(token),
		},
		method: "POST",
		data,
	});
	return response.data;
};
export const getAdminsCount = async (params, token) => {
	try {
		const response = await axios(url("getAdminsCount"), {
			headers: {
				...headersFunc(token)
			},
			method: "GET",
			params,
		});
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error;
	}

};

export const verifyToken = async (token) => {
	try {
		const response = await axios(url("is-valid"), {
			headers: {
				...headersFunc(token),
			},
			method: "GET",
		});
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error

	}
};


export const getAllThread = async (params, token) => {
	try {
		const response = await axios(url("getAllMessagesupdate"), {
			headers: {
				...headersFunc(token),
			},
			method: "GET",
			params,
		});
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error

	}
};

export const getAllMessages = async (data, token) => {
	// console.log(params, "get-all-messages queryfunction");

	try {
		const response = await axios(url("allMessages"), {
			headers: {
				...headersFunc(token),
			},
			method: "POST",
			data,
		});
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error

	}
};

export const resetAdminPassword = async ({ data, token }) => {
	try {
		const response = await axios(url("updatePassword"), {
			headers: {
				...headersFunc(token),
			},
			method: "POST",
			data,
		});
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error
	}
}

export const syncSms = async (params, token) => {
	try {
		const response = await axios(url("getAllMessagesupdate"), {
			headers: {
				...headersFunc(token),
			},
			method: "GET",
			params,
		});
		return response.data;
	} catch (error) {
		// console.log(error);
		throw (error);
	}
}; export const syncEmail = async (data, token) => {
	try {
		// changed this url from `emailSync` to `showEmailData`
		const response = await axios(url("emailSync"), {
			headers: {
				...headersFunc(token),
			},
			method: "POST",
			data,
		});
		console.info('syncEmail', response.data)
		return response.data;
	} catch (error) {
		// console.log(error);
		throw (error);
	}
};

export const getTFA = async (token) => {
	try {
		const response = await axios(url("tfastatuschecker"), {
			headers: {
				...headersFunc(token)
			},
			method: "GET",
		})
		return response.data;

	} catch (error) {
		throw error

	}
}


export const generateTFACreds = async ({ data, token }) => {
	try {
		const response = await axios(url('generate'), {
			headers: {
				...headersFunc(token)
			},
			method: "POST"
		})
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error

	}

}

export const toggleTFAStatus = async ({ data, token }) => {
	try {
		const response = await axios(url('updatetfastatus'), {
			headers: {
				...headersFunc(token)
			},
			method: "POST",
			data
		})
		return response.data;
	} catch (error) {
		// console.log(error);
		throw error
	}

}

export const logger = async ({ data, token }) => {
	try {
		await axios(url('logger'), {
			headers: {
				...headersFunc(token)
			},
			method: "POST",
			data
		})

	} catch (error) {
		// console.log(error);
		throw (error)

	}
}