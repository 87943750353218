import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SharedFunctionsContext from "../util/SharedFunctionsContext";
import SessionMiddleWare from "../components/SessionMiddleWare";
import Loader from "../components/Loader";
import ProtectedRouteWithMiddleware from "../components/HOC/ProtectedRouteWithMiddleWare";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ClientListWrapperSA from "../components/HOC/ClientListWrapperForSA";
import ClientListWrapperForAdmin from "../components/HOC/ClientListWrapperForAdmin";
import Login from "../authentication/Login";
import Layout from "../layouts/Layout";
import ClientListPage from "./members/ClientListPage";
import ActivityLayout from "./activity/ActivityLayout";
import MyActivity from "./activity/component/MyActivity";
import ActivityList from "./activity/component/ActivityList";
import SettingsLayout from "./settings/SettingsLayout";
import ResetPassword from "./settings/ResetPassword";
import Twofactor from "./settings/Twofactor";
import swal from "sweetalert";
import TFAMiddleWare from "../components/HOC/TFAMiddleWare";
import Tfa from "../authentication/Tfa"
import TFACheckedMiddleware from "../components/HOC/TFACheckedMiddleware";
import ToomanyRequest from "../ErrorPage/ToomanyRequest";
import { useLogger } from "../Hooks/tanqueryHooks";
import { AnimatePresence } from "framer-motion";
import AdminPage from "./members/AdminPage";
import Chat from "./members/Chat";
import Errorpage from "../ErrorPage/Errorpage";
import Sessionpage from "../ErrorPage/Sessionpage";
// const Errorpage = lazy(() => import("../ErrorPage/Errorpage"));
// const Sessionpage = lazy(() => import("../ErrorPage/Sessionpage"));
// const Chat = lazy(() => import("./members/Chat"));
// const AdminPage = lazy(() => import("./members/AdminPage"));
// const Admins = lazy(() => import("./members/Admins"));
// const Charts = lazy(() => import("./members/Charts"));
function Allpages() {

	const location = useLocation();
	// eslint-disable-next-line
	const authState = useSelector((state) => state.auth);
	// state to handle location status
	const [locationAccess, setLocationAccess] = useState(true);
	const [isAlertOpen, setIsAlertOpen] = useState(false);
	const { mutate } = useLogger();
	useEffect(() => {
		// toast(NotifyToaster);
		if (location?.state?.activity) {
			const { activity } = location.state;

			mutate({
				token: authState.token, data: {
					activity,
					skiplog: true,
					type: "Browsing",
				}
			})
		} else {
			// console.log(location);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const navigate = useNavigate();
	const onNavigate = (location, state) => {
		navigate(location, state && { state });
	};

	const sharedItems = {
		onNavigate,
	};

	const alertFunction = () => {
		swal({
			title: "Permission Denied!",
			text: "Please Allow Location Permission",
			icon: "warning",
			button: false,
			confirm: {
				text: "Are you sure you want to close the alert?",
				confirmButtonText: "Yes, Close",
				confirmButtonColor: "error",
			},
			closeOnClickOutside: locationAccess,
		})
	}
	const alertFunctionForFirefox = () => {
		swal({
			title: "Permission Denied!",
			text: "Please Allow Location Permission with 'Remember this decision' option",
			icon: "warning",
			button: false,
			closeOnClickOutside: locationAccess,
		})
	}
	useEffect(() => {
		navigator.permissions
			.query({ name: "geolocation" })
			.then((permissionStatus) => {

				if (permissionStatus.state === 'denied'){
					setLocationAccess(false);
					setIsAlertOpen(true);
					alertFunction();
				} else if(permissionStatus.state === 'prompt') {
					setLocationAccess(false);
					setIsAlertOpen(true);
					alertFunctionForFirefox();
				} else {
					if (isAlertOpen) {
						swal.close();
						setIsAlertOpen(false);
					}
					setLocationAccess(true);
				}
				if (permissionStatus.state === 'prompt') {
					// Permission needs to be requested
					navigator.geolocation.getCurrentPosition(function (position) {
						// alert('too')
						// User granted permission
						// if (isAlertOpen) {
						// 	swal.close();
						// 	setIsAlertOpen(false);
						// }
						// // navigator.geolocation.getCurrentPosition(function () {})
						// swal({
						// 	title: "Action Needed",
						// 	text: `Please select "Remember this decision" to continue`,
						// 	icon: "warning",
						// 	button: "Close",
						// 	closeOnClickOutside: locationAccess,
						// }).then(() => {
							// navigator.geolocation.getCurrentPosition(function () { })
						// 	setIsAlertOpen(false);
						// })
						// alertFunctionForFirefox();
						// swal({
						// 	title: "Permission Denied!",
						// 	text: "Please Allow Location Permission with 'Remember this decision' option",
						// 	icon: "warning",
						// 	button: false,
						// 	closeOnClickOutside: locationAccess,
						// }).then(() => {
						// 	navigator.geolocation.getCurrentPosition(function () { })
						// 	setIsAlertOpen(false);
						// })
						setIsAlertOpen(true);
						// triggerPermissionChange('granted');
						// onSuccessiveWatch(position);

						// setLocationAccess(true);
					}, function (error) {
						// triggerPermissionChange('denied');
						// User denied permission
						setLocationAccess(false);
						setIsAlertOpen(true);
						alertFunctionForFirefox();
					});
				}
				permissionStatus.onchange = (d) => {
					if (permissionStatus.state === 'denied'){
						setLocationAccess(false)
						alertFunction();
						setIsAlertOpen(true);
					} else if(permissionStatus.state === 'prompt') {
						setLocationAccess(false)
						alertFunctionForFirefox();
						setIsAlertOpen(true);
					} else {
						if (isAlertOpen) {
							swal.close();
							setIsAlertOpen(false);
						};
						setLocationAccess(true)
					}
				};
			});
	}, [locationAccess, navigate, isAlertOpen])

	return (
		// <LoadingOverlay spinner active={loading} text={""}>
		// <Suspense fallback={<Loader />}>
		<SharedFunctionsContext.Provider value={sharedItems}>
			<AnimatePresence>
				<Routes location={location} key={location.pathname}>
					{/* <Route path="/home" element={<Home />} /> */}
					<Route
						path="/"
						element={
							// <TFACheckedMiddleware>
							<SessionMiddleWare>
								<Login />
							</SessionMiddleWare>
							// </TFACheckedMiddleware>
						}
					/>
					<Route
						path="/tfa"
						element={
							<TFAMiddleWare>
								{/* <Suspense fallback={<Loader />}> */}
								<Tfa />
								{/* </Suspense> */}
							</TFAMiddleWare>
						}
					/>
					<Route
						path="/expired"
						element={
							// <Suspense fallback={<Loader />}>
							<Sessionpage />
							// </Suspense>
						}
					/><Route
						path="/429"
						element={
							// <Suspense fallback={<Loader />}>
							<ToomanyRequest />
							// </Suspense>
						}
					/>

					<Route

						path="/dashboard"
						element={
							// <AuthMiddleWare>
							<TFACheckedMiddleware>
								<ProtectedRouteWithMiddleware>
									<Layout />
								</ProtectedRouteWithMiddleware>
							</TFACheckedMiddleware>
							// </AuthMiddleWare>
						}
					>
						{/* <Route path="contact" element={<Suspense fallback={<Loader />}>
										<Contact />
									</Suspense>} /> */}
						{/* <Route
								path="charts/:memberId"
								element={
									<Suspense fallback={<Loader />}>
										<Charts />
									</Suspense>
								}
							/> */}
						{/* <Route path="clientList" element={<ClientList />} /> */}
						<Route
							path="clientList/:adminId"
							element={
								<ClientListWrapperSA>
									<ClientListPage />
								</ClientListWrapperSA>
							}
						/>
						<Route
							path="clientList"
							element={
								<ClientListWrapperForAdmin>
									<ClientListPage />
								</ClientListWrapperForAdmin>
							}
						/>

						<Route path="settings" element={<SettingsLayout />}>
							<Route index element={<Navigate to="resetPassword" replace />} />
							<Route path="resetPassword" element={<ResetPassword />} />
							<Route path="tfa" element={<Twofactor />} />
						</Route>
						{/* <Route path="activity/*" element={<ActivityTest />} /> */}

						<Route path="activity" element={<ActivityLayout />}>
							<Route index element={<Navigate to="myactivity" replace />} />
							<Route
								path="myactivity"
								element={<MyActivity />}
							/>
							<Route
								path="adminList"
								element={<ActivityList selectList={1} />}
							/>
							<Route
								path="clientList"
								element={<ActivityList selectList={0} />}
							/>
							<Route path="adminList/:id" element={<MyActivity />} />
							<Route path="clientList/:id" element={<MyActivity />} />
						</Route>

						{/* <Route
								path="chat"
								element={
									<Suspense fallback={<Loader />}>
										<Chat />
									</Suspense>
								}
							> */}
						<Route
							path="chat/:pValue/:id"
							element={
								// <Suspense fallback={<Loader />}>
								<Chat />
								// </Suspense>
							}
						/>
						{/* </Route> */}

						<Route
							path="adminList"
							element={
								// <Suspense fallback={<Loader />}>
								<AdminPage />
								// </Suspense>
							}
						/>
						{/* <Route
							path="admin"
							element={
								// <Suspense fallback={<Loader />}>
								<Admins />
								// </Suspense>
							}
						/> */}
					</Route>

					<Route
						path="*"
						element={
							// <Suspense fallback={<Loader />}>
							<Errorpage />
							// </Suspense>
						}
					/>
				</Routes>
			</AnimatePresence>
			<ToastContainer position="bottom-right" autoClose={10000} stacked />
		</SharedFunctionsContext.Provider>
		// </Suspense>
		// </LoadingOverlay>
	);
}

export default Allpages;
