import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';

const TFAMiddleWare = ({ children }) => {
    const { tfaStatus, isTFADone, role } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    // console.log("tfa middle ware rendered", tfaStatus, isTFADone);

    useLayoutEffect(() => {
        if (!tfaStatus) {
            navigate(-1)
        }

    }, [])
    if (tfaStatus && isTFADone) {
        return <Navigate to={role === "admin" ?
            "/dashboard/clientList"
            : "/dashboard/adminList"}
            replace={true} />;
    }
    if (tfaStatus && !isTFADone) {
        return children;
    }
}

export default TFAMiddleWare