import React from "react";
// import InfoBadgeSkeleton from "../skeletons/InfoBadgeSkeleton";
// Param definitions
//  name  - the name of the key that get render in the InfoBadge
//  value - the value of the key that get render in the InfoBadge (can be a string or an number)
const InfoBadge = React.memo(({ name, value }) => {
	// console.log(name, value);
	// Logic for determining the value is get render or not.
	let bool;
	if (typeof value === "string" && value !== "") {
		bool = true;
	} else if (typeof value === "number" && value >= 0) {
		bool = true;
	}
	return (
		<div className="info-badge-wrapper">
			<p className="contact_heads">
				{name} {bool && " - "}{" "}
				{bool && <span className="count_contacts"> {value}</span>}
			</p>
		</div>
		// <InfoBadgeSkeleton/>
	);
});

export default InfoBadge;
