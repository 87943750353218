import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { useTFAStatusChecker, useToggleTFAStatus } from "../../Hooks/tanqueryHooks";
import TFAModal from "./components/TFAModal";

function Twofactor() {
	const [enableModal, setEnableModal] = useState(false);
	const [confirmBox, setConfirmBox] = useState(false);
	const { token } = useSelector(state => state.auth)

	const { isLoading, isError: isStatusError, isSuccess: isStatusSuccess, data: statusData } = useTFAStatusChecker();
	// if (isStatusSuccess) {
	// 	console.log("statusData", statusData.data);
	// }
	const { mutate, isPending: isTogglePending, isError: isToggleError, isSuccess: isToggleSuccess, data: toggleData } = useToggleTFAStatus();

	// useEffect(() => {
	// 	console.log("isLoading", isLoading);
	// 	console.log("isStatusError", isStatusError);
	// 	console.log("isLoading", isLoading);

	// }, [])

	const cancelModal = () => {
		setConfirmBox(false);
		setEnableModal(false);
	};
	const handleTFAToggle = () => {
		// console.log(statusData?.data?.tfastatus);
		if (statusData?.data?.tfastatus) {
			mutate({ token, data: { status: false } })
		} else {
			setEnableModal(!enableModal);
		}


		// console.log("toggle button click");
	}
	// const tfaButtonClickHandler = async () => {
	// 	setLoading(true)
	// 	const tfaClickBtn = document.querySelector(".tfa_click_button");
	// 	if (!(tfaClickBtn.classList.contains("active"))) {
	// 		await generateAuth();
	// 	} else {
	// 		await fetchTfaStatus();
	// 	}
	// setEnableModal(!enableModal);
	// 	setLoading(false);
	// };
	if (isLoading) {
		return <Loader />
	}
	if (isStatusError) {

		return <h3>Unable to load data</h3>
	}

	if (isStatusSuccess) {

		const tfastatus = statusData?.data?.tfastatus;
		return (
			<div>
				<div className="resetPass">
					<div className="reset_header_top">
						<p className="reset_header">Two factor Authentication</p>
					</div>
					<div className="authentication">
						{isTogglePending && <Loader />}
						<span className="tittle_tfa">Enable</span>

						<div className={"container"}>
							<div
								className={`tfa_click_button ${tfastatus ? 'toggle-btn active' : 'toggle-btn'}`}
								onClick={handleTFAToggle}
							>
								<div className="inner-circle"></div>
							</div>
						</div>
					</div>

					{enableModal && (

						<TFAModal confirmBox={confirmBox} cancelModal={cancelModal} tfastatus={tfastatus}
							setConfirmBox={setConfirmBox}
							isTogglePending={isTogglePending}
							toggleMutate={mutate}
						/>
					)}
				</div>

			</div>
		);
	}

}

export default Twofactor;
