import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	listClientParams: { pageNumber: 1, search: "", ownerId: "" },
	activity: "",
	activeThread: null,
};

const reactiveParmsSlice = createSlice({
	name: "reactiveParams",
	initialState,
	reducers: {
		setListClientParams: (state, action) => {
			const { page, search } = action.payload;
			return {
				...state,
				listClientParams: {
					...state.listClientParams,
					page: page !== undefined ? page : state.listClientParams.page,
					search: search !== undefined ? search : state.listClientParams.search,
				},
			};
		},
		setActivity: (state, action) => {
			const { activity } = action.payload;
			return {
				...state,
				activity,
			};
		},
		setActiveThread: (state, action) => {
			const activeThread = action.payload;
			return {
				...state,
				activeThread
			};
		},
	},
});

export const { setListClientParams, setActivity, setActiveThread } = reactiveParmsSlice.actions;
export default reactiveParmsSlice.reducer;
