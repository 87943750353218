import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import Modal from "../../components/Modal";
import ModalBody from "../../components/ModalBody";
import ModalHeader from "../../components/ModalHeader";
import ModalFooter from "../../components/ModalFooter";
import "../../asset/settings/settings.css";
import apiCaller from "../../util/apiCaller";

function AssignAdminDetails({ _id, name, email, funCall, changeAdminModal }) {
  const [adminList, setAdminList] = useState([]);
  const [selectedAdminList, setSelectedAdminList] = useState(null);
  const [assignedAdmins, setAssignedAdmins] = useState([]);
  const [selectedRemoveAdmin, setSelectedRemoveAdmin] = useState([]);
  const [activeOption, setActiveOption] = useState("remove");
  const [removeAdminFlag, setRemoveAdminFlag] = useState(false);

  const isSelectable = () => assignedAdmins.length > 1;

  const isRowSelectable = (event) => {
    if (event.data) {
      return isSelectable();
    } else {
      return true;
    }
  };

  const rowClassName = () => {
    return isSelectable() ? "" : "p-disabled";
  };

  const changeAdminDetails = (e) => {
    let selectedAdmins = [];
    if(selectedAdminList?.length > 0){
      selectedAdminList?.forEach((item) => {
        selectedAdmins.push(item._id);
      });
      const data = {
        adminIds: selectedAdmins,
        clientId: [_id],
        skiplog: true,
      };
      apiCaller({ url: "shareClients", data })
        .then((responseChat) => {
          let displayData = responseChat.data.status;
          if (displayData === true) {
            swal({
              title: "Success!",
              text: "Client Assigned to Admin Successfully",
              icon: "success",
              button: false,
              timer: 2000,
            });
            funCall();
          } else {
            swal({
              title: "Warning!",
              text: responseChat.data.message,
              icon: "warning",
              button: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          swal({
            title: "Warning!",
            text: error.message,
            icon: "warning",
            button: false,
            timer: 2000,
          });
        })
        .finally(() => {
          changeAdminModal(e);
          setSelectedAdminList([]);
        });
    }else{
      swal({
        title: "Warning!",
        text: "Select atleast one admin",
        icon: "warning",
        button: false,
        timer: 2000,
      });
    }
    e.stopPropagation();
  };

  const removeAdminDetails = (e) => {
    let selectedRemoveAdmins = [];
    if(selectedRemoveAdmin?.length > 0){
      console.info('selectedRemoveAdmin:', selectedRemoveAdmin);
      selectedRemoveAdmin.forEach((item) => {
        selectedRemoveAdmins.push(item._id);
      });
      const data = {
        adminId: selectedRemoveAdmins,
        clientId: [_id],
        skiplogin: true,
      };
      apiCaller({ url: "removeClientAccess", data })
        .then((responseChat) => {
          let displayData = responseChat.data.status;
          if (displayData === true) {
            swal({
              title: "Success !",
              text: "Client Removed from Admin Successfully",
              icon: "success",
              button: false,
              timer: 2000,
            });
            funCall();
            changeAdminModal(e);
          } else {
            swal({
              title: "Access Denied !",
              text: responseChat.data.message,
              icon: "warning",
              button: false,
              timer: 2000,
            });
          }
        })
        .catch((error) => {
          swal({
            title: "Warning!",
            text: error.message,
            icon: "warning",
            button: false,
            timer: 2000,
          });
        })
        .finally(() => {
          setSelectedRemoveAdmin([]);
        });
    }else{
      swal({
        title: "Warning!",
        text: "Select atleast one admin",
        icon: "warning",
        button: false,
        timer: 2000,
      });
    }
    e.stopPropagation();
  };

  const adminDetailsList = () => {
    apiCaller({
      url: "getUnassignedAdmins",
      skiplogin: true,
      method: "GET",
      params: { clientId: _id },
    })
      .then((res) => {
        let displayList = res.data.unassignedAdmins;
        let assAdmins = res.data.assignedAdmins;
        assAdmins.forEach((item, idx) => {
          assAdmins[idx].index = idx + 1;
        });
        displayList.forEach((item, idx) => {
          displayList[idx].index = idx + 1;
        });
        setAssignedAdmins(assAdmins);
        setAdminList(displayList);
      })
      .catch((error) => {
        swal({
          title: "Warning!",
          text: error.message,
          icon: "warning",
          button: false,
          timer: 2000,
        });
      });
  };

  const cancelModal = (e) => {
    e.stopPropagation();
    changeAdminModal(e);
    setSelectedAdminList([]);
  };

  const handleOptionSelect = (option) => {
    setActiveOption(option);
  };

  const buttonConfigChangeAdmin = [
    {
      text: "Assign",
      id: "submit",
      className: "modal_btn btn_confirm",
      onClick: [[], [changeAdminDetails]],
      datavalue: "1",
      form: "clientAddForm",
    },
    {
      text: "Cancel",
      className: "modal_btn btn_close",
      onClick: [[], [cancelModal]],
      datavalue: "1",
      close: true,
      form: "clientAddForm",
    },
  ];

  const buttonConfigRemoveAdmin = [
    {
      text: "Remove",
      id: "submit",
      className: removeAdminFlag
        ? "btn_disable modal_btn btn_confirm"
        : "modal_btn btn_confirm",
      onClick: [[], [removeAdminDetails]],
      datavalue: "1",
      form: "clientAddForm",
      disabled: removeAdminFlag,
    },
    {
      text: "Cancel",
      className: "modal_btn btn_close",
      onClick: [[], [cancelModal]],
      datavalue: "1",
      close: true,
      form: "clientAddForm",
    },
  ];

  useEffect(() => {
    adminDetailsList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      selectedRemoveAdmin?.length === assignedAdmins.length &&
      assignedAdmins.length !== 0 &&
      selectedRemoveAdmin?.length !== 0
    ) {
      setRemoveAdminFlag(true);
      swal({
        title: "Access Denied !",
        text: "Admin access removal failed. Please have at least one admin for the client",
        icon: "warning",
        button: false,
        timer: 5000,
      });
    } else {
      setRemoveAdminFlag(false);
    }
    // eslint-disable-next-line
  }, [selectedRemoveAdmin]);

  return (
    <>
      <Modal size="modal_xl" color="bgcolor">
        <ModalHeader
          header={`Admin Access Management`}
          onClick={cancelModal}
        />
        <ModalBody>
          <div className="adminmodal" style={{height: '100%'}}>
            <div className="admin_Container">
              <div className="adminMenu">
                <div
                  className={`changepasswod ${
                    activeOption === "remove" ? "active" : ""
                  }`}
                  onClick={() => handleOptionSelect("remove")}
                >
                  <p>Withdraw Admin Access</p>
                </div>
                <div
                  className={`changepasswod ${
                    activeOption === "assign" ? "active" : ""
                  }`}
                  onClick={() => handleOptionSelect("assign")}
                >
                  <p>Assign Admin Access</p>
                </div>
              </div>
              <div className="adminOptions">
                {activeOption === "remove" ? (
                  <div className="admin_modal">
                    <div className="reset_header_top"> <p className="reset_header">Remove Admin for{" "}
                      {name.charAt(0).toUpperCase() + name.slice(1)}</p></div>

                    <div className="adminTable">
                      <DataTable
                        value={assignedAdmins}
                        size="large"
                        showGridlines
                        paginator
                        rows={5}
                        className="prime-data-table"
                        selectionMode={"checkbox"}
                        selection={selectedRemoveAdmin}
                        isDataSelectable={isRowSelectable}
                        rowClassName={rowClassName}
                        onSelectionChange={(e) =>
                          setSelectedRemoveAdmin(e.value)
                        }
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        ></Column>
                        <Column field="index" header="S No" sortable></Column>
                        <Column field="name" header="Name" sortable></Column>
                        <Column field="phone" header="Phone" sortable></Column>
                        <Column field="email" header="Email" sortable></Column>
                      </DataTable>
                    </div>
                    <div className="admin_footer">
                      <ModalFooter
                        className="admin_btn"
                        buttonConfig={buttonConfigRemoveAdmin}
                      ></ModalFooter>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="admin_modal">
                      {/* <div className="assName">
                        Assign Admin for{" "}
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                      </div> */}

                    <div className="reset_header_top"> <p className="reset_header">Assign Admin for{" "}
                        {name.charAt(0).toUpperCase() + name.slice(1)}</p></div>

                      <div className="adminTable">
                        <DataTable
                          value={adminList}
                          size="large"
                          showGridlines
                          paginator
                          rows={5}
                          className="prime-data-table"
                          selectionMode={"checkbox"}
                          selection={selectedAdminList}
                          onSelectionChange={(e) =>
                            setSelectedAdminList(e.value)
                          }
                        >
                          <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3rem" }}
                          ></Column>
                          <Column field="index" header="S No" sortable></Column>
                          <Column field="name" header="Name" sortable></Column>
                          <Column
                            field="phone"
                            header="Phone"
                            sortable
                          ></Column>
                          <Column
                            field="email"
                            header="Email"
                            sortable
                          ></Column>
                        </DataTable>
                      </div>
                      <div className="admin_footer">
                        <ModalFooter
                          className="admin_btn"
                          buttonConfig={buttonConfigChangeAdmin}
                        ></ModalFooter>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AssignAdminDetails;
