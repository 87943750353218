import { Tooltip } from "react-tooltip";
import apiCaller from "../../../util/apiCaller";
import { Paginator } from "primereact/paginator";
import React, { useState, useEffect } from "react";
import { CiMonitor, CiLocationOn } from "react-icons/ci";
import { FiRefreshCcw } from "react-icons/fi";
import swal from "sweetalert";
import moment from "moment";
import Modal from "../../../components/Modal";
import ModalHeader from "../../../components/ModalHeader";
import ModalBody from "../../../components/ModalBody";
import ModalFooter from "../../../components/ModalFooter";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import DateImg from "../../../asset/images/date_frame.svg";
import SearchIcon from "../../../asset/images/search.svg"
import { isObjectEmpty } from "../../../util/helper";

export default function MyActivity({ fromSuperadmin = false }) {
	const { id } = useParams()
	const isAdminActivityRendered = useMatch(`/dashboard/activity/adminList/${id}`);
	const isClientActivityRendered = useMatch(`/dashboard/activity/clientList/${id}`);
	const navigate = useNavigate();
	const authState = useSelector(state => state.auth)
	const [page, setPage] = useState(1);
	const [rows, setRows] = useState(5);
	const [first, setFirst] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [activityData, setActivityData] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalLocation, setModalLocation] = useState({
		active: false,
		data: null,
	});

	const [isCancelFrom, setIsCancelFrom] = useState(false);
	const [searchableData, setSearchableData] = useState({});
	const [filterData, setFilterData] = useState({
		fromDate: "",
		toDate: "",
		searchUserAndIp: "",
	});


	const [pickAFromDate, setPickAFromDate] = useState(false);
	const [pickAToDate, setPickAToDate] = useState(false);

	// useEffect for handle click outside
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				(pickAToDate || pickAFromDate) &&
				!event.target.closest(".date_filters") &&
				!event.target.closest(".rdp")
			) {
				// console.log("vikash how are you");
				setPickAToDate(false);
				setPickAFromDate(false);
			}
		};

		if (pickAToDate || pickAFromDate) {
			window.addEventListener("click", handleClickOutside);
		}

		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, [pickAToDate, pickAFromDate]);



	const handleDateModal = (field) => {
		setPickAFromDate(field === "from");
		setPickAToDate(field === "to");
	}

	const handleDateSelection = (selectedDate) => {
		if (!selectedDate) return;

		const formattedSelectedDate = format(selectedDate, "yyyy-MM-dd");

		if (pickAFromDate) {
			setFilterData((prev) => ({ ...prev, ["fromDate"]: formattedSelectedDate }));
			setPickAFromDate(false)
		} else if (pickAToDate) {
			setFilterData((prev) => ({ ...prev, ["toDate"]: formattedSelectedDate }));
			setPickAToDate(false)
		}

	};




	const getUsrDetails = () => {
		var data = {
			// If MyActivity rendered via either adminList/:id or clientList/:id  that id is used to hit the api
			_id: !!id ? id : authState._id,
		};
		apiCaller({ url: "activityDetails", data })
			.then((res) => {
				const dataList = res.data;
				// console.log("object", dataList);
			})
			.catch((error) => { });
	};

	const getMyActivityDetails = () => {
		var data = {
			page: page,
			perPage: 5,
			// If MyActivity rendered via either adminList/:id or clientList/:id  that id is used to hit the api
			_id: id ? id : authState._id,
			search: searchableData.search,
			startDate: searchableData.fromDate,
			endDate: searchableData.toDate,
		};

		apiCaller({ url: "logActivities", data })
			.then((res) => {
				const dataList = res.data.response;
				setActivityData(dataList.data);
				// console.log("Data List : ", activityData[0].location[0].address.state)
				setTotalPage(dataList.pagination[0].total);
			})
			.catch((error) => {
				setActivityData([]);
				setTotalPage(0);
				if (error.response) {
					// The request was made and the server responded with a status code
					// other than 2xx. Access response data here.
					console.error("Response data:", error.response.data);
					console.error("Response status:", error.response.status);
					console.error("Response headers:", error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					console.error("No response received:", error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.error("Error setting up the request:", error.message);
				}
			});
	};

	const onPageChange = (event) => {
		setFirst(event.first);
		setRows(event.rows);
		setPage(event.page + 1);
	};
	const handleMoadalClose = () => {
		setModalVisible(!modalVisible);
		getUsrDetails();
	};

	const handleLocationModalClose = () => {
		setModalLocation({
			active: false,
			data: null,
		});
	};
	const handleLocationModalOpen = (_id) => {
		const [location] = activityData.find((data) => data._id).location;
		// console.log(location);
		setModalLocation((prev) => ({
			...prev,
			active: true,
			data: location,
		}));
	};
	const validateDates = (filterData) => {
		const fromDateObj = new Date(filterData.fromDate);
		const toDateObj = new Date(filterData.toDate);
		if (filterData.fromDate !== "" && filterData.toDate !== "") {
			if (toDateObj >= fromDateObj) {
				return { isInvalid: true };
			} else {
				return {
					isInvalid: false,
					yarn: swal({
						title: "Warning!",
						text: "To date greater than from date",
						icon: "warning",
						button: false,
						timer: 5000,
					}),
				};
			}
		} else {
			return { isInvalid: false };
		}
	};

	const updatedFilterField = (fieldName, fieldValue) => {
		setFilterData((prev) => ({ ...prev, [fieldName]: fieldValue }));
	};

	const cancelFilterFrom = () => {
		setSearchableData({});
		setFilterData((prev) => ({
			...prev,
			fromDate: "",
			toDate: "",
			searchUserAndIp: "",
		}));
		setIsCancelFrom(!isCancelFrom);
	};

	const searchFrom = (searchUserDataIps) => {
		const { fromDate, toDate, searchUserAndIp } = filterData;
		let isValidate = validateDates(filterData);
		const { isInvalid } = isValidate;
		let filterDatas = {
			fromDate: isInvalid ? moment(fromDate).format("DD/MM/YYYY") : "",
			toDate: isInvalid ? moment(toDate).format("DD/MM/YYYY") : "",
			search:
				searchUserDataIps !== undefined ? searchUserDataIps : searchUserAndIp,
		};
		if (fromDate === "") {
			delete filterDatas.fromDate;
		}
		if (toDate === "") {
			delete filterDatas.toDate;
		}
		if (searchUserAndIp === "") {
			delete filterDatas.search;
		}
		setSearchableData(filterDatas);
	};

	useEffect(() => {
		getMyActivityDetails(); // eslint-disable-next-line
	}, [page, searchableData, id]);

	useEffect(() => {
		searchFrom(); // eslint-disable-next-line
	}, [filterData]);

	const closeModal = () => {
		setModalLocation({ active: false, data: null });
		setModalVisible(false);
	};
	const handleViewMaps = () => {
		// console.log("handleViewMaps");
		const { lat, lon } = modalLocation.data;
		const url = `https://www.google.com/maps?q=${lat},${lon}`;
		window.open(url, "_blank");
	};
	const buttonConfigAdmin = [
		{
			text: "View Maps",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[], [handleViewMaps]],
			datavalue: "1",
			form: "clientAddForm",
		},
		{
			text: "Cancel",
			className: "modal_btn btn_close",
			onClick: [[], [closeModal]],
			datavalue: "1",
			close: true,
			form: "clientAddForm",
		},
	];

	return (
		<>
			<div className="set_height">
				<div className="activity-Header">
					<div className="reset_header_top"><p className="reset_header">My Activity</p></div>
					<div>
						{
							(isAdminActivityRendered || isClientActivityRendered) && <div className="enable-button">
								<button
									className="primary_button"
									onClick={() => navigate(-1)}
								>
									<div className="back-icon">
										<IoMdArrowBack size={20} />
									</div>
									<div className="back-btn">{"  "} Back</div>
								</button>
							</div>
						}

					</div>
				</div>


				{/* {!fromSuperadmin ? (
				<div className="reset_header"> My Activity </div>
			) : (
				<></>
			)} */}
				<div className="filter-container">
					<div className="date_fl_rel date_fl_rel_fst">
						<div className={`date_filters`} onClick={() => handleDateModal("from")}>
							<p>{filterData.fromDate || "From"}</p>
							<div className="datefrom_img_inp">
								<img className="dateImg" src={DateImg} alt="DateImg" />
							</div>
						</div>
						{pickAFromDate && (
							<DayPicker
								mode="single"
								showOutsideDays
								selected={filterData.fromDate ? new Date(filterData.fromDate) : null}
								onSelect={handleDateSelection}
							/>
						)}
					</div>

					<div className="date_fl_rel date_fl_rel_scnd">
						<div className={`date_filters`} onClick={() => handleDateModal("to")}>
							<p>{filterData.toDate || "To"}</p>
							<div className="datefrom_img_inp">
								<img className="dateImg" src={DateImg} alt="DateImg" />
							</div>
						</div>
						{pickAToDate && (
							<DayPicker
								mode="single"
								showOutsideDays
								selected={filterData.toDate ? new Date(filterData.toDate) : null}
								onSelect={handleDateSelection}
							/>
						)}
					</div>


					<div className="floating-label-group">
						<div className="activity_search_input_wrapper">
							<input
								type="text"
								className="form-input"
								autoComplete="off"
								autoFocus
								required
								value={filterData.searchUserAndIp}
								onChange={(e) =>
									updatedFilterField("searchUserAndIp", e.target.value)
								}
							/>
							<label className="floating-label">Search</label>
							<div className="search_icon">
								<img src={SearchIcon} alt="searchIcon" />
							</div>
						</div>
					</div>
					<div className="filter-submit">
						<span className="user_chat" onClick={cancelFilterFrom}>
							<FiRefreshCcw
								className="reset_icon"
								data-tooltip-id="newRefresh"
								data-tooltip-content="Reset"
							/>{" "}
						</span>
						<Tooltip
							id="newRefresh"
							place="top"
							effect="solid"
							style={{
								backgroundColor: "var(--primarycolor)",
								borderRadius: "20px",
							}}
						/>
					</div>
				</div>


				<div className="pick_activity">

				</div>

				<div className="overflow-div">
					<div className="login-history-tit">
						<div className="login-icon-details">
							<div className="login-icon-tit"></div>
						</div>
						<div className="system-os title">
							<p>User Details</p>
						</div>
						<div className="system-activity title">
							<p>User Activity</p>
						</div>
						<div className="system-ip title">
							<p>IP Address</p>
						</div>
						<div className="system-time title">
							<p>Date and Time</p>
						</div>
						<div className="system-location title">
							<p>Location</p>
						</div>
					</div>
					{activityData.length > 0 ? (
						activityData &&
						activityData.map((obj) => (
							<div key={obj._id} className="login-history">
								<div className="login-icon-details">
									<div className="login-icon">
										<CiMonitor size={25} />
									</div>
								</div>
								<div className="system-os">
									<div className="system-os-detail">
										<p className="system_set">{obj.os}</p>
									</div>
									<div className="system-activity">
										<div className="system-detail">
											<p className="system_set">{obj.system}</p>
										</div>
									</div>
								</div>
								<div className="system-activity">
									<Tooltip id="actEye" place="top" effect="solid" />
									<div className="activity-eye-activity">
										<p className="system_set">{obj.activity}</p>
										{/* <div className="activity-eye-div" onClick={handleMoadalClose}>
                    <LuInfo
                      className="activity-eye"
                      data-tooltip-id="actEye"
                      data-tooltip-content="Activity Details"
                    />
                  </div> */}
									</div>
								</div>
								<div className="system-ip">
									<p className="system_set">{obj.ip}</p>
								</div>
								<div className="system-time">
									<p className="system_set">{`${obj.datedetails}, ${obj.time}`}</p>
								</div>
								<div className="system-location">
									<div className="address">
										{/* <p>
                    Prince Info City I, 286/1 & 286/2, Thiruvalluvar Street,
                    CMWSSB Division 183, Ward 183, Zone 14 Perungudi,
                    Sholinganallur, Chennai District, Tamil Nadu, 600096, India
                  </p> */}

										<p>{obj?.location[0]?.error || !obj?.location[0] || isObjectEmpty(obj?.location[0]) ? "Not available" : `${obj?.location[0]?.address?.state}, ${obj?.location[0]?.address?.country}`}</p>

										<Tooltip id="location" place="top" effect="solid" />

										{(!obj?.location[0]?.error) && <div
											className="location"
											onClick={() => handleLocationModalOpen(obj._id)}
										>
											<CiLocationOn size={25}
												className="activity-eye"
												data-tooltip-id="location"
												data-tooltip-content="Location Details"
											/>
										</div>}
									</div>
								</div>
							</div>
						))
					) : (
						<div className="noData-found">
							<p>No Data Found</p>
						</div>
					)}
				</div>

				{modalVisible ? (
					<>
						<Modal>
							<ModalHeader
								header="Activity Details"
								onClick={handleMoadalClose}
							></ModalHeader>
							<ModalBody></ModalBody>
						</Modal>
					</>
				) : (
					<></>
				)}

				{modalLocation.active && (
					<>
						<Modal>
							<ModalHeader
								header="Location Details"
								onClick={handleLocationModalClose}
							/>

							<ModalBody>
								<div>
									<div className="location-title">
										<div className="grid_set">
											<div className="grid_one">
												<p className="grid_header">Address</p>
											</div>
											<div className="grid_two">
												<p className="grid_header">:</p>
											</div>
											<div className="grid_three">
												<p className="address_showing">{modalLocation.data?.display_name}</p>
											</div>
										</div>
										<div className="grid_set">
											<div className="grid_one">
												<p className="grid_header">Lattitute</p>
											</div>
											<div className="grid_two">
												<p className="grid_header">:</p>
											</div>
											<div className="grid_three">
												<p className="longi">{modalLocation.data?.lat}</p>
											</div>
										</div>
										<div className="grid_set">
											<div className="grid_one">
												<p className="grid_header">Longitude</p>
											</div>
											<div className="grid_two">
												<p className="grid_header">:</p>
											</div>
											<div className="grid_three">
												<p className="longi">{modalLocation.data?.lon}</p>
											</div>
										</div>

									</div>

									<div className="locattion_lati">
										<table className="location_show">

										</table>

									</div>
								</div>
							</ModalBody>

							<ModalFooter buttonConfig={buttonConfigAdmin}></ModalFooter>
						</Modal>
					</>
				)}
			</div>


			<Paginator
				first={first}
				rows={rows}
				totalRecords={totalPage}
				onPageChange={(e) => onPageChange(e)}
			/>

		</>
	);
}
