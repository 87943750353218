import {
	determineRefetchForGetCurrentPosition,
	geoErrorProvider,
	locationOptions,
	// onErrorWatch,
	onSuccessiveWatch,
	onsuccessiveGetCurrent,
} from "./helper";

// location provider is responsible for provide reverse-geocoding data , when the first api call occurs
// in the application this will get called and return the the reverse-geocoding details.
export const locationProvider = () => {
	// console.log("locationProvider called");
	// Initialize a new promise that handle returning coordinates from geolocation API
	return new Promise((resolve, reject) => {
		const { shouldRefetch, storedReversedGeoCodes } =
			determineRefetchForGetCurrentPosition();
		if (shouldRefetch) {
			navigator.geolocation.getCurrentPosition(

				(data) => {
					onsuccessiveGetCurrent(resolve, reject, data);
				},
				(err) => {
					reject(geoErrorProvider(err));
				},
				locationOptions
			);
		} else {
			resolve(storedReversedGeoCodes);
		}
	});
};
// while locationProvider provides reverse-geocoding data for the first api call
// locationWatcher is responsible for updating the stored location information
// in the localStorage whenever more accurate location obtained in the background,
// it will continuously update the location whenever the location from the browser changes
const locationWatcher = () => {
	// console.log("locationWatcher called");
	navigator.geolocation.watchPosition(
		onSuccessiveWatch,
		geoErrorProvider,
		locationOptions
	);
};

try {
	locationWatcher();

} catch (error) {
	// console.log(error);
}

navigator.permissions.query({ name: "geolocation" }).then((result) => {
	result.onchange = function () {
		try {
			// console.log("inside onchange call back");
			if (this.state === "granted") {
				// console.log("granted and revoked");

				locationProvider();
				locationWatcher();
			}
		} catch (error) {
			// console.log(error);
		}

	}

	// Don't do anything if the permission was denied.
});