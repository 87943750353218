import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const ClientListWrapperSA = ({ children }) => {
	const authState = useSelector((state) => state.auth);
	const { adminId } = useParams();
	const navigate = useNavigate();

	const isSuperAdmin = authState.role === "superadmin";

	useLayoutEffect(() => {
		//
		if (!isSuperAdmin && !adminId) {
			navigate("..//*", {
				state: {
					error_Code: "403",
					redirection: isSuperAdmin
						? "/dashboard/adminList"
						: "/dashboard/clientList",
				},
				replace: true,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (authState.role === "superadmin" && adminId) {
		return children;
	} else {
		return <Navigate to="/*"
			replace={true}
			state={{
				error_Code: "403",
				redirection: isSuperAdmin
					? "/dashboard/adminList"
					: "/dashboard/clientList",
			}
			} />
	}
};

export default ClientListWrapperSA;

