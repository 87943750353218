import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { setLogin, setLogout, setUser } from "../slice/authSlice";
import Buttons from "../components/Buttons";
//import Loader from "../components/Loader";
import logo from "../asset/logo/autoaudit.svg";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { Helmet } from "react-helmet";
import ForgotPassword from "./ForgotPassword";
import { Field, Form, Formik } from "formik";
import { loginSchema } from "../validation/Validation";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { useLogin } from "../Hooks/tanqueryHooks";
import { clearStorage, setItem } from "../util/helper";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [eyeIcon, setEyeIcon] = useState(true);
	const [forgotPass, setForgotPass] = useState(false);
	const [initialFormValues] = useState({
		email: "",
		password: "",
	});
	const handleError = (error) => {
		//console.log(error);
		const msg =
			error?.response?.data?.message ||
			"Unable to reach the server. Please try again";
		swal({
			title: "Oops!",
			text: msg,
			icon: "error",
			button: false,
			timer: 3000,
		});
	};
	const handleSuccess = (data) => {
		const { authorization: token, tfastatus: tfaStatus } = data;
		const { _id, name, email, role } = data.response;

		// console.log(token);
		swal({
			title: "Success !",
			text: "Login Successful !",
			icon: "success",
			button: false,
			timer: 2000,
		}).finally(() => {
			dispatch(setLogin({ status: data.status }));
			dispatch(setUser({ _id, name, email, token, role, tfaStatus }));
			setItem({
				user_persist: data.status,
				user: { ...data.response, tfaStatus: data.tfastatus, },
				auth: data.authorization,
				role: data.role,
			});

			if (tfaStatus) {
				navigate('tfa', { replace: true });
			} else {
				navigate(`dashboard/${role === 'superadmin' ? "adminList" : role === 'admin' ? "clientList" : "*"}`, {
					state: { activity: "Home page" },
					replace: true,
				});
			}
		});
	};
	const { mutate, isPending } = useLogin({
		handleError,
		handleSuccess,
	});

	//Client Login
	const makeLogin = (values) => {
		mutate({
			email: values.email,
			password: values.password,
		});
	};

	//Forgot Password
	const forgot_Password = () => {
		setForgotPass(!forgotPass);
	};

	return (
		<>
			{/* ======================  Meta Tags ======================  */}
			<Helmet>
				<title>autoaudit.ai &#10072; Login Page </title>
			</Helmet>
			{/* ======================  Meta Tags ======================  */}
			<LoadingOverlayWrapper spinner active={isPending}>
				<section className="login_page">
					<div className="fix_width">
						<div className="login_header">
							<div className="logo">
								<img
									style={{ width: 100 }}
									src={logo}
									className="logo"
									alt="autoaudit.ai"
									title="autoaudit.ai"
								/>
							</div>
							<h1 className="login_head">autoaudit.ai</h1>
							<p className="login_para">
								Most Secured Platform to Mirror SMS and Emails
							</p>
						</div>
						{forgotPass ? (
							<>
								<ForgotPassword handleForgotPassword={forgot_Password} />
							</>
						) : (
							<>
								<div className="parent_div">
									<Formik
										initialValues={initialFormValues}
										validationSchema={loginSchema}
										onSubmit={(values) => {
											makeLogin(values);
										}}
									>
										{({ values, errors, touched, setFieldValue }) => (
											<Form>
												<div className={"login_section"}>
													{" "}
													<div className="login_option">
														<div>
															<label className="input_label" name="email">
																<span>
																	Email <span style={{ color: "red" }}>*</span>
																</span>
															</label>
															<Field
																name="email"
																maxLength="60"
																tabIndex="0"
																className="input_type"
																autoComplete="off"
																disabled={isPending}
																autoFocus={true}
																onChange={(event) => {
																	const uppercaseValue =
																		event.target.value.toLowerCase();
																	setFieldValue("email", uppercaseValue);
																}}
															// autoFocus={true}
															/>
															<p className="error">
																{errors.email && touched.email
																	? errors.email
																	: ""}
															</p>
														</div>
														<div>
															<label className="input_label" name="password">
																<span>
																	Password{" "}
																	<span style={{ color: "red" }}>*</span>
																</span>
															</label>
															<Field
																name="password"
																type="text"
																tabIndex="0"
																disabled={isPending}
																maxLength="16"
																minLength="8"
																id={eyeIcon ? "password" : "passwordVisible"}
																className="input_type"
																autoComplete="off"
																value={values.password}
															/>
															<button
																type="button"
																onClick={() => setEyeIcon(!eyeIcon)}
																className="remove_btn_default iconEye"
																tabIndex="0"
																disabled={isPending}
															>
																{eyeIcon && <FiEyeOff />}
																{!eyeIcon && <FiEye />}
															</button>
															<p className="error">
																{errors.password && touched.password
																	? errors.password
																	: ""}
															</p>
														</div>
													</div>
													<div className="forgotoldPassword">
														<button
															disabled={isPending}
															tabIndex="0"
															type="button"
															className="remove_btn_default forgot_pass"
															onClick={forgot_Password}
														>
															Forgot Password?
														</button>
													</div>
												</div>
												<div className="login_submit">
													<Buttons
														type="submit"
														className="submit"
														text="Login"
														name="button"
														id="auto-submit"
														disabled={isPending}
													/>
												</div>
											</Form>
										)}
									</Formik>
								</div>
							</>
						)}
					</div>
				</section>
			</LoadingOverlayWrapper>
		</>
	);
};

export default Login;
