import React from "react";

function Modal({ children, size, color }) {
  return (
    <>
      <div className="modal" id="add_client_modal">
        <div className={`modal-content ${size ? size : "modal_md"} ${color}`}>
          {children}
        </div>
      </div>
    </>
  );
}

export default Modal;
