import React from "react";
import Loader from "../../../components/Loader";

const MessageLoader = ({ size, sizeToReduce }) => {
	return (
		<div>
			<div className="emailBox">
				<div
					className="mailContact"
					style={{ height: size.height - sizeToReduce }}
				>
					<div className="loaderDisplay">
						<Loader />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MessageLoader;
