import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const TFACheckedMiddleware = ({ children }) => {
    const { tfaStatus, isTFADone } = useSelector((state) => state.auth);

    if (tfaStatus && !isTFADone) {
        return <Navigate to='tfa' />
    } else {
        return children
    }


}

export default TFACheckedMiddleware