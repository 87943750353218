import React, { useEffect, useState } from 'react'
import ActivityListTable from './ActivityListTable'
import { useListAdmin, useListMutationAdmin } from '../../../Hooks/tanqueryHooks';
import { useNavigate } from 'react-router-dom';
import { CiViewList } from "react-icons/ci";
import { isPending } from '@reduxjs/toolkit';
import { Paginator } from 'primereact/paginator';

const ActivityAdminList = ({ id }) => {
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [rows, setRows] = useState(4);
	const [first, setFirst] = useState(0);
    const [datas,setDatas]=useState(null);

    const [searchParams, setSearchParams] = useState({
        ownerId: id,
        pageNumber: page,
        search: "",
        limit: 10
    });

    // useEffect(() => {
    //     mutate(searchParams)
    // }, [mutate]);




    const {
        isLoading,
        isError,
        isSuccess,
        data,
        isPending,
    } = useListAdmin(searchParams);

// const {mutate,isError,isPending,isLoading,isSuccess,data}=useListMutationAdmin({
//     onSuccess: (data, variables, context) => {
//         console.log(data,"mutate")
//        // setDatas(data.data)
//       },
//       onError: (error) => {
//         console.log(error)
//       },
// })


// useEffect(() => {
//     setSearchParams(prevParams => ({
//         ...prevParams,
//         ownerId: id,
//         pageNumber: page
//     }));
//     mutate(searchParams)
// }, [searchParams]);


    const onPageChange = (e) => {
		// setFirst(e.first);
		// setRows(e.rows);
		// setPage(e.page + 1);
        setSearchParams({
            ...searchParams,
            pageNumber:e.page+1,
        })
	};
    const bodyContent = (data) => {
        return (
            <div className="activity-email">
                <span className='show_details' onClick={() => navigate(`/dashboard/activity/adminList/${data._id}`)}>
                    <CiViewList size={25}/>
                </span>

                {data.email}

            </div>
        );
    };
    if (isLoading || isPending) {
        return "Loading...";
    }
    if (isError) {
        return "Error..."
    }
    if (isSuccess) {
        return (
            <div className="activity_list">
                <ActivityListTable data={data?.data?.searchResults} body={bodyContent} />
                {/* <Paginator
                    first={searchParams?.pageNumber}
                    rows={4}
                    totalRecords={data?.totalCount}
                    onPageChange={(e) => onPageChange(e)}
                    /> */}
            </div>
        )
    }

}

export default ActivityAdminList