import React from "react";

function OfflineIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21.5156C16.9706 21.5156 21 17.4862 21 12.5156C21 7.54506 16.9706 3.51562 12 3.51562C7.02944 3.51562 3 7.54506 3 12.5156C3 17.4862 7.02944 21.5156 12 21.5156Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.75 12.5156C15.75 18.5156 12 21.5156 12 21.5156C12 21.5156 8.25 18.5156 8.25 12.5156C8.25 6.51562 12 3.51562 12 3.51562C12 3.51562 15.75 6.51562 15.75 12.5156Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.51172 9.51562V9.51562C9.09404 8.2003 14.9057 8.2003 20.488 9.51562V9.51562"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.51172 15.5156V15.5156C9.09404 16.8309 14.9057 16.8309 20.488 15.5156V15.5156"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default OfflineIcon;
