import swal from "sweetalert";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import "../../asset/contact/contact.css";
import { useDispatch } from "react-redux";
import { Column } from "primereact/column";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import ModalBody from "../../components/ModalBody";
import ModalHeader from "../../components/ModalHeader";
import ModalFooter from "../../components/ModalFooter";
import CustomAvatar from "../../components/CustomAvatar";
import { setCurrentMember } from "../../slice/memberSlice";
import { styles } from "../../asset/SelectDropdown/SelectDropdown";
import ResetPasswordOptions from "./ResetPasswordOptions";
import apiCaller from "../../util/apiCaller";
import { setUser } from "../../slice/authSlice";
import { useDeleteAdmin } from "../../Hooks/tanqueryHooks";

// icons added
import DeleteIcon from "../../components/icons/DeleteIcon";
import ClientIcon from "../../components/icons/ClientIcon";
import ResetIcon from "../../components/icons/ResetIcon";

import {
	adminDeleted,
	errorMessageGenerator,
	errorMessageGeneratorWithConfirmation,
	msgGenerator,
} from "../../util/swalConfigs";
import { getItem } from "../../util/helper";
import MailIcon from "../../components/icons/MailIcon";
import MobIcon from "../../components/icons/MobIcon";
export default function Admins({ _id, name, phone, email, token, handleResetPasswordToggle, setListOfContactsHeight }) {
	const user = getItem("user") || false;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [adminList, setAdminList] = useState([]);
	const [clientList, setClientList] = useState([]);
	const [delClient, setDelClient] = useState(false);
	const [clientModal, setClientModal] = useState(false);
	const [resetModal, setResetModal] = useState(false);

	const [selectedClientList, setSelectedClientList] = useState([]);
	const [selectedAdminList, setSelectedAdminList] = useState([]);
	const handleSuccessiveDelete = () => {
		swal(adminDeleted);
	};
	const handle409 = (data) => {
		swal(errorMessageGeneratorWithConfirmation(data?.message)).finally(() => {
			let datalist = data;
			if (datalist.adminClients.length > 0) {
				let updateAdmin = [];
				let filterData = datalist.adminList.filter((x) => x._id !== user._id);
				filterData.forEach((item) => {
					updateAdmin.push({
						value: item._id,
						label: item.name,
					});
				});
				datalist.adminClients.forEach((item, idx) => {
					datalist.adminClients[idx].index = idx + 1;
				});
				setDelClient(false);
				setClientModal(!clientModal);
				setClientList(datalist.adminClients);
				setAdminList(updateAdmin);
			} else {
				swal(msgGenerator(data.message));
			}
		});
	};
	const handleErrorDelete = ({ response }) => {
		// 409 indicates an error occurred while deleting the admin cause the admin has client belonging to his/her account
		switch (response.status) {
			case 409:
				handle409(response.data);
				break;
			default:
				swal(
					errorMessageGenerator(
						response?.data?.message
					)
				);
				break;
		}

		// console.log(error?.response?.data?.message);
	};
	const handleSettled = () => {
		confirmDeleteAdmin(!delClient);
	};

	const { mutate, isPending } = useDeleteAdmin({
		handleSuccessiveDelete,
		handleErrorDelete,
		handleSettled,
	});

	const onClick = () => {
		dispatch(setCurrentMember({ _id }));
	};

	// Delete Client========================

	const DeleteAdmin = (e) => {
		const data = {
			adminId: _id,
		};
		mutate({ data, token });
		e.stopPropagation();
	};

	const confirmDeleteAdmin = (e) => {
		setDelClient(!delClient);
		// e.stopPropagation();
	};

	const cancelClientModal = () => {
		setDelClient(false);
		setClientModal(false);
		setClientList([]);
		setAdminList([]);
		setSelectedAdminList([]);
		setSelectedClientList([]);
	};

	const cancelResetModal = () => {
		setResetModal(false);
	};

	const handleViewClients = () => {
		dispatch(setUser({ adminName: name }));
		navigate(`/dashboard/clientList/${_id}`, {
			state: { adminName: name, _id, activity: "Client List Page" },
		});
	};

	const handleResetPassword = () => {
		setResetModal(true);
	};

	const updatedFilterField = (fieldValue) => {
		setSelectedAdminList(fieldValue);
	};

	const changeAdminDetails = (e) => {
		let selectedClients = [];
		let selecteAdmins = [];
		selectedClientList.forEach((item) => {
			selectedClients.push(item._id);
		});
		selectedAdminList.forEach((item) => {
			selecteAdmins.push(item.value);
		});
		const data = {
			adminIds: selecteAdmins,
			clientId: selectedClients,
		};
		if (selecteAdmins.length > 0 && selectedClients.length > 0) {
			apiCaller({ url: "shareClients", data })
				.then((responseChat) => {
					let displayData = responseChat.data.status;
					if (displayData === true) {
						swal({
							title: "Success!",
							text: "Client Assigned to Admin Successfully",
							icon: "success",
							button: false,
							timer: 2000,
						});
						// funCall();
					} else {
						swal({
							title: "Warning!",
							text: "Admin not Assign",
							icon: "warning",
							button: false,
							timer: 2000,
						});
					}
				})
				.catch((error) => {
					swal({
						title: "Warning!",
						text: error.message,
						icon: "warning",
						button: false,
						timer: 2000,
					});
				})
				.finally(() => {
					setClientModal(false);
					setClientList([]);
					setAdminList([]);
					setSelectedAdminList([]);
					setSelectedClientList([]);
				});
			e.stopPropagation();
		} else {
			swal({
				title: "Warning!",
				text: "Select the Admin and  Client",
				icon: "warning",
				button: false,
				timer: 2000,
			});
		}
	};


	// measure `list of contacts div height and pass it to the parent component
	useEffect(() => {
		const handleHeight = () => {
			setListOfContactsHeight(
				document.querySelector(".list_of_contacts").clientHeight
			);
		};
		window.addEventListener("resize", handleHeight);
		handleHeight();
		return () => window.removeEventListener("resize", handleHeight);
	}, [setListOfContactsHeight]);

	const buttonConfigDelete = [
		{
			disabled: isPending,
			text: "Confirm",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[confirmDeleteAdmin], [DeleteAdmin]],
			datavalue: "1",
			form: "clientAddForm",
		},
		{
			text: "Cancel",
			className: "modal_btn btn_close",
			onClick: [[], [confirmDeleteAdmin]],
			datavalue: "1",
			close: true,
			form: "clientAddForm",
		},
	];

	const buttonConfigAdmin = [
		{
			text: "Confirm",
			id: "submit",
			className: "modal_btn btn_confirm",
			onClick: [[cancelClientModal], [changeAdminDetails]],
			datavalue: "1",
			form: "clientAddForm",
		},
		{
			text: "Cancel",
			className: "modal_btn btn_close",
			onClick: [[], [cancelClientModal]],
			datavalue: "1",
			close: true,
			form: "clientAddForm",
		},
	];
	return (
		<>
			<div className="list_of_contacts">
				<div className="client_circle">
					<CustomAvatar name={name} />
				</div>

				<div
					className="member_info"
					onClick={(e) => {
						onClick(e);
					}}
				>
					<div className="uer_name_view">
						<Tooltip
							id={name}
							place="top"
							effect="solid"
							style={{
								backgroundColor: "var(--primarycolor)",
								borderRadius: "20px",
							}}
						/>

						<p
							data-tooltip-id={name}
							data-tooltip-content={name}
							className="user_name"
						>
							{name}
						</p>
					</div>

					<div className="user_phone">
						<div className="mobile_user">
							<MobIcon
								svgClass="total_icon"
								spanClass="mobile_icon"
							/>
							<Tooltip
								id={phone}
								place="top"
								effect="solid"
								style={{
									backgroundColor: "var(--primarycolor)",
									borderRadius: "20px",
								}}
							/>
							<span
								data-tooltip-id={phone}
								data-tooltip-content={phone}
								className=""
							>{phone}</span>
						</div>
					</div>

					<div className="user_email">
						<div className="mobile_user">
							<MailIcon
								svgClass="total_icon"
								spanClass="mobile_icon"
							/>
							<Tooltip
								id={email}
								place="top"
								effect="solid"
								style={{
									backgroundColor: "var(--primarycolor)",
									borderRadius: "20px",
								}}
							/>

							<span
								data-tooltip-id={email}
								data-tooltip-content={email}
								className=""
							>
								{email}
							</span>
						</div>
					</div>
				</div>

				<div className="user_chatl">
					<Tooltip id="clientList" place="top" effect="solid" className="tooltip_correction" />

					<ClientIcon
						svgClass="total_icon"
						spanClass="show_icons client_span"
						TooltipId="clientList"
						ToolContent="View Clients"
						onClick={handleViewClients}
					/>

					<ResetIcon
						svgClass="total_icon"
						spanClass="show_icons client_span"
						TooltipId="clientList"
						ToolContent="Reset Password"
						// onClick={handleResetPassword}
						onClick={() => { handleResetPasswordToggle({ _id, name, toggle: true }) }
						}
					/>

					<DeleteIcon
						svgClass="total_icon"
						spanClass="show_icons delete_span"
						TooltipId="newTrash"
						ToolContent="Delete"
						onClick={confirmDeleteAdmin}
					/>

					<Tooltip id="newTrash" place="top" effect="solid" />
				</div>
			</div>
			{delClient ? (
				<Modal>
					<ModalHeader header="Are you sure?" onClick={confirmDeleteAdmin} />
					<ModalBody modal_grid="client_body_one">
						<div className="client_delete">
							<p className="deleteHeader">
								Do you really want to delete these records?
							</p>
							<table className="delete_table">
								<tr>
									<td className="delRec">Name</td>
									<td className="delReCol">:</td>
									<td>{name}</td>
								</tr>
								<tr>
									<td className="delRec">Email</td>
									<td className="delReCol">:</td>
									<td>{email}</td>
								</tr>
							</table>
						</div>
					</ModalBody>
					<ModalFooter buttonConfig={buttonConfigDelete}></ModalFooter>
				</Modal>
			) : (
				<></>
			)}
			{clientModal ? (
				<Modal size="modal_lg">
					<ModalHeader header="Are you sure?" onClick={cancelClientModal} />
					<ModalBody modal_grid="client_body_one">
						<div className="admin_modal">
							<div className="admin_select">
								<div className="deleteHeader">Select Admin's </div> <br></br>
								<div className="div-category">
									<Select
										isMulti
										options={adminList}
										onChange={(e) => updatedFilterField(e)}
										styles={styles}
									/>
								</div>
							</div>
							<div className="adminTable">
								<DataTable
									value={clientList}
									size="large"
									showGridlines
									paginator
									rows={5}
									className="prime-data-table"
									selectionMode={"checkbox"}
									selection={selectedClientList}
									onSelectionChange={(e) => setSelectedClientList(e.value)}
								>
									<Column
										selectionMode="multiple"
										headerStyle={{ width: "3rem" }}
									></Column>
									<Column field="index" header="S No" sortable></Column>
									<Column field="name" header="Name" sortable></Column>
									<Column field="phone" header="Phone" sortable></Column>
									<Column field="email" header="Email" sortable></Column>
								</DataTable>
							</div>
						</div>
					</ModalBody>
					<ModalFooter buttonConfig={buttonConfigAdmin}></ModalFooter>
				</Modal>
			) : (
				<></>
			)}
			{resetModal ? (
				<>
					<Modal>
						<ModalHeader header={`Reset Password`} onClick={cancelResetModal} />
						<ModalBody>
							<ResetPasswordOptions
								adminUserId={_id}
								getValuefromResetAdminPassword={cancelResetModal}
								adminName={name}
							/>
						</ModalBody>
					</Modal>
				</>
			) : (
				<></>
			)}
		</>
	);
}
