import React from "react";

function MobIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="6.85547"
            y="2.6333"
            width="10.4297"
            height="19.8779"
            rx="1.81787"
            stroke="currentColor"
            strokeWidth="0.908937"
          />
          <path
            d="M10.7031 4.3916H13.4296"
            stroke="currentColor"
            strokeWidth="0.908937"
            strokeLinecap="round"
          />
        </svg>
      </span>
    </>
  );
}

export default MobIcon;
