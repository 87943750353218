// import { useTokenVerify } from "../../Hooks/tanqueryHooks";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import createSocketService from "../../services/socketService";
import swal from "sweetalert";
import { setLogout } from "../../slice/authSlice";
const ProtectedRouteWithMiddleware = ({ children }) => {
	// const { isError, isLoading, isSuccess, error } = useTokenVerify();
	const { token } = useSelector((state) => state.auth);
	const socketService = createSocketService(token);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const hanldeUnauthorization = () => {
		swal({
			title: "Access Denied!",
			text: "Please contact your administrator for further information.",
			icon: "warning",
			button: false,
		})
	}
	const handleVerifyAdminSocket = (data) => {
		if (data?.blocked) {
			dispatch(setLogout());
			hanldeUnauthorization();
			navigate("/");
		}
	};
	useEffect(() => {
		if (socketService && socketService.socket) {

			const { socket } = socketService;

			socket?.on("verifyAdmin", handleVerifyAdminSocket);

			return () => {
				socket?.off("verifyAdmin", handleVerifyAdminSocket)
			};
		}
	}, [socketService]);

	if (token) {
		return children
	} else {
		return <Navigate to="/" replace={true} />;
	}

	// if (isLoading) {
	// 	return (
	// 		<section className="login_page">
	// 			<Loader />
	// 		</section>
	// 	);
	// }
	// if (isError) {
	// 	(error?.response?.status === 401 || error?.response?.status === 403) && hanldeUnauthorization()
	// 	return null;
	// }
	// if (isSuccess) {
	// 	return children;
	// }

	// useEffect(() => {
	// 	// Set up the interval and store the interval ID
	// 	const intervalId = setInterval(() => {
	// 		const token = getItem("auth");
	// 		checkTokenValidity(token, navigate);
	// 	}, 300000);

	// 	// Clean up the interval when the component is unmounted
	// 	return () => {
	// 		clearInterval(intervalId);
	// 	};
	// }, [navigate]);
};

export default ProtectedRouteWithMiddleware;
