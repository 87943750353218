import React from "react";

const RippleNotify = ({ Icon, top, right, left, bottom }) => {
	return (
		<div className="ripple_wrapper">
			<div className="ripple" style={{ top, right, bottom, left }}></div>
			{/* <div className="counter">9</div> */}
			{Icon}
		</div>
	);
};

export default RippleNotify;
