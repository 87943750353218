import React, { useEffect } from "react";
import "../asset/common/common.css";
import { playSound } from "../util/helper";
import { useNavigate } from "react-router-dom";
import NewMsg from "../asset/images/chat.svg";


const NotifyToaster = ({
	closeToast,
	body,
	address,
	destination,
	header,
	userId,
	type,
	clientName = "Client",
}) => {
	const navigate = useNavigate();
	const handleClickToast = () => {
		navigate(`dashboard/chat/chat/${userId}`);
		closeToast()
		// console.log("Navigation will happen");
	};
	useEffect(() => {
		playSound();
	}, []);
	return (
		<>
			{/* <div className="toaster_container" onClick={handleClickToast}>
				<div>
					<p className="toaster_heading">New Message !</p>
				</div>
				<h5>From : {address}</h5>
				<p className="toast_client">Client Name :{clientName}</p>
				<p>Click to see the details</p>
				<p>{body}</p>
			</div> */}

			<div className="toast_content" onClick={handleClickToast}>
				<div className="toast_container">
					<div className="toast_icon">
						<img
							src={NewMsg}
							alt="New msg"
							className="toast_img"
							loading="lazy"
						/>
					</div>

					<div className="toast_information">
						<div className="toaster_header">
							<div className="toast_heading">
								<p className="notify_name">{clientName}</p>
								<p className="notify_sender">{address}</p>
							</div>
							<p className="notify_para">
								{body}
							</p>
						</div>

						<div className="toast_msg">
							<p className="toaster_now">Just now</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotifyToaster;
