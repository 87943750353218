import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import Admins from "./Admins";
import "react-phone-number-input/style.css";
import useWindowSize from "../../Hooks/useWindowSize";
import Loader from "../../components/Loader";
import EmptyMemberList from "./EmptyMemberList";
import ListHeader from "./components/AdminListHeader";
import ErrorPic from "../../ErrorPage/500_error.svg";
import ResetPasswordForAdmin from "../../components/ResetPasswordForAdmin";
import Modal from "../../components/Modal";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../components/ModalBody";

function AdminList({
	token,
	toggler,
	isLoading,
	isError,
	isSuccess,
	data,
	isPending,
	statusCode,
}) {
	const size = useWindowSize();
	const [listOfContactsHeight, setListOfContactsHeight] = useState(null)
	const [toggleResetPasswordOptions, setToggleResetPasswordOptions] = useState({
		_id: null,
		adminName: null,
		toggle: false,
	});

	// eslint-disable-next-line
	// eslint-disable-next-line
	const [searchCls, setSearchCls] = useState(false);
	const [sectionHeight, setSectionHeight] = useState(600);
	const contentRef = useRef(null);

	// const { isLoading, isError, isSuccess, data, isPending } =
	// 	useListAdmin(searchParams);
	// if (isSuccess) {
	// 	console.log(data);
	// }
	//ClientList Show
	useEffect(() => {
		const handleClickOutside = (event) => {
			setSearchCls(false);
		};
		$(document).on("click", handleClickOutside);
		return () => {
			$(document).off("click", handleClickOutside);
		};
	}, []);

	const handleResetPasswordToggle = (data) => {
		setToggleResetPasswordOptions((prev => ({
			...prev,
			...data

		})))
	}
	const handleCloseResetPasswordForm = () => {
		setToggleResetPasswordOptions({
			_id: null,
			adminName: null,
			toggle: false,
		})
	}
	// without resize event listener
	useEffect(() => {
		if(contentRef){
			const fixHeightElement = document.querySelector(".fix_height");
			if(fixHeightElement){
				const fixHeightElementTop = fixHeightElement.getBoundingClientRect().top;
				const bodySectionBottom = document.querySelector(".body_section")?.getBoundingClientRect().bottom;
				const cardHeight = document.querySelector(".list_of_contact")?.getBoundingClientRect().height;
				const newHeight = bodySectionBottom - fixHeightElementTop - (cardHeight/2)
				setSectionHeight(newHeight);
			}
		}
	});

	// with resize event listener
  useEffect(() => {
    const handleResize = () => {
			if(contentRef){
				const fixHeightElement = document.querySelector(".fix_height");
				if(fixHeightElement){
					const fixHeightElementTop = fixHeightElement.getBoundingClientRect().top;
					const bodySectionBottom = document.querySelector(".body_section")?.getBoundingClientRect().bottom;
					const cardHeight = document.querySelector(".list_of_contact")?.getBoundingClientRect().height;
					const newHeight = bodySectionBottom - fixHeightElementTop - (cardHeight/2)
					setSectionHeight(newHeight);
				}
			}
    };
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sectionHeight]);


	if (isError) {
		if(statusCode === 500){
			return (
				<div className="fix_height_wrapper">
					<div className="fix_height">
						<div className="list-error">
							<img
								className="list-error-img"
								src={ErrorPic}
								alt="Server Error "
							/>
							{/* <p>Unable to load Clients data, Please try after some time</p> */}
						</div>
					</div>
				</div>
			);
		}else if(statusCode === 403 ){
			return (
				<div className="fix_height_wrapper">
					<div className="fix_height">
						<div className="list-error">
							<img
								className="list-error-img"
								src={ErrorPic}
								alt="Server Error "
							/>
							<p>No data</p>
						</div>
					</div>
				</div>
			)
		}
	}

	if (isLoading || isPending)
		return (
			<div className="fix_height_wrapper">
				<div className="fix_height">
					<Loader />
				</div>
			</div>
		);

	if (isSuccess) {
		const adminList = data?.searchResults;
		return (
			<>
				{data?.hasRecords === false ? (
					<div className="new_addClientd">
						<EmptyMemberList toggler={toggler} />
						<div className="new_addClients">
							<button className="primary_button" onClick={toggler}>
								+ Add Admin
							</button>
						</div>
					</div>
				) : (
				<div className="fix_height_wrapper">
					<div ref={contentRef} className="fix_height">
						{adminList?.length > 0 ? (
							adminList?.map((admin) => (
								<Admins
									handleResetPasswordToggle={handleResetPasswordToggle}
									token={token}
									key={admin._id}
									{...admin}
									setListOfContactsHeight={setListOfContactsHeight}
								// funCall={AdminList}
								// onEditClick={handleEditClick}
								/>
							))
						) : (
							<div className="no_data_list">
								<p className="no_data_para">No Data Found...</p>
							</div>
						)}
					</div>
					</div>
				)}

				{
					toggleResetPasswordOptions.toggle &&

					<ResetPasswordForAdmin
						handleResetPasswordToggle={handleResetPasswordToggle}
						handleCloseResetPasswordForm={handleCloseResetPasswordForm}
						adminUserId={toggleResetPasswordOptions._id}
						adminName={toggleResetPasswordOptions.name}
					/>
				}

			</>
		);
	}
}

export default AdminList;
