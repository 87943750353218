import React, { useState, useEffect } from "react";
import { clearForm } from "../../slice/formDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import $ from "jquery";
import { Helmet } from "react-helmet";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Modal from "../../components/Modal";
import ModalHeader from "../../components/ModalHeader";
import ModalBody from "../../components/ModalBody";
import ModalFooter from "../../components/ModalFooter";
import Input from "../../components/Input";
import { Field, Form, Formik } from "formik";
import swal from "sweetalert";
import "react-phone-number-input/style.css";
import { addMemberSchema } from "../../validation/Validation";
import InfoHeader from "./components/InfoHeader";
import {
	mpinverified,
	mpinverifyFailed,
	clientAdded,
	serverError,
	errorMessageGenerator,
} from "../../util/swalConfigs";
import { toast } from "react-toastify";
import NotifyToaster from "../../components/NotifyToaster";
import { useQueryClient } from "@tanstack/react-query";
import ClientList from "./ClientList";
import {
	useAddClient,
	useListClient,
	useAdminVerifyMpin,
} from "../../Hooks/tanqueryHooks";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import createSocketService from "../../services/socketService";
import { motion } from "framer-motion";
import ListHeader from "./components/ClientListHeader";
function ClientListPage() {
	const { adminId } = useParams();
	const authState = useSelector((state) => state.auth);
	const [toggleModal, setToggleModal] = useState(false);
	const [toggleotpModal, setToggleOtpModal] = useState(false);
	const formDataState = useSelector((state) => state.formData);
	const { OTPForm } = formDataState;
	const queryClient = useQueryClient();
	const isAdmin = authState.role === "admin";
	const isSuperAdmin = authState.role === "superadmin";
	const _id = isAdmin ? authState._id : adminId;
	const name = isAdmin ? authState.name : null;
	const [searchParams, setSearchParams] = useState({
		ownerId: _id,
		pageNumber: 1,
		search: "",
	});
	const [singleCurrentId, setSingleCurrentId] = useState("");
	const [initialFormValues, setInitialFormValues] = useState({
		name: "",
		email: "",
		phone: "",
	});
	const {
		data: clientListData,
		isSuccess,
		isLoading,
		refetch,
		isError,
	} = useListClient(searchParams);
	const dispatch = useDispatch();
	const { socket } = createSocketService(authState.token);

	const hanldeNotifyEvent = (data) => {
		const { clientName, msg: { address, body, userId, uuid } } = data
		// handleUpdateMessageData(data.userId)
		toast(<NotifyToaster clientName={clientName} body={body} address={address} userId={userId} />, {
			autoClose: 10000,
			toastId: uuid,
			hideProgressBar: true,
		});
		handleSocketUpdate(data)
	};
	// const handleUpdateMessageData = (userId) => {
	// 	const data = queryClient.setQueryData(["list-client"], (data) => {
	// 		const response = data.response;
	// 		console.log("old", response);
	// 		const modified = response.map(c => {
	// 			let client = c;
	// 			if (client._id === userId) {
	// 				client = {
	// 					...client,
	// 					newSms: true,
	// 				}
	// 			}
	// 			return client

	// 		})
	// 		console.log("new", modified);
	// 		return {
	// 			...data,
	// 			modified
	// 		}

	// 	});
	// 	console.log(data);
	// }
	// Handles the update logic for smsSyncStatus socket
	function handleSocketUpdate(data) {

		if (typeof data === "object" && data.status) {
			// //console.log("handleSocketUpdate called");
			// //console.log(data);
			const { userId = "", updates = {} } = data ?? {};
			// This will update the cached data in the queryClient
			queryClient.setQueryData(["list-client"], (data = {}) => {
				const { response = [] } = data;
				// Modified the exact property that received via socket
				// for the client whose _id matches the id received vai socket
				const modifiedData = response?.map((client) => {
					if (client._id === userId) {
						return { ...client, ...updates };
					}
					return client;
				});

				return { ...data, response: modifiedData };
			});
		}
	}
	useEffect(() => { refetch() }, [refetch, searchParams])
	useEffect(() => {
		// setTimeout(() => {
		// 	console.log(socket);
		// }, 3000);
		setTimeout(() => {
			socket?.connect()
		}, 2000);

		socket?.on("notification", (data) => {
			// console.log("notification", data);
			hanldeNotifyEvent(data);
		});
		socket?.on("userBlockStatus", (data) => {
			// console.log("blockAdmin", data);
			handleSocketUpdate(data);
		});
		socket?.on("smsSync", (data) => {
			// console.log("smsSync", data);
			handleSocketUpdate(data);
		});
		socket?.on("emailSync", (data) => {
			// console.log("emailSync", data);
			handleSocketUpdate(data);
		});
		socket?.on("userActive", (data) => {
			// console.log("userActive", data);
			handleSocketUpdate(data);
		});
		socket?.on("mpinGenerate", (data) => {
			// console.log("mpinGenerate Socket", data);
			handleSocketUpdate(data);
		});
		return () => {
			socket?.off("notification", hanldeNotifyEvent);
			socket?.off("userBlockStatus", handleSocketUpdate);
			socket?.off("smsSync", handleSocketUpdate);
			socket?.off("emailSync", handleSocketUpdate);
			socket?.off("userActive", handleSocketUpdate);
			socket?.off("mpinGenerate", handleSocketUpdate);
			// console.log(disconnect, "disconnect function");

		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// console.log(socketService.socket);
	const handleSuccessiveVerification = () => {
		setSingleCurrentId("");
		return swal(mpinverified);
	};
	const handleErroriveVerification = () => {
		swal(mpinverifyFailed);
		setSingleCurrentId("");
	};


	const { mutate: adminVerifyMutate } = useAdminVerifyMpin({
		handleSuccessiveVerification,
		handleErroriveVerification,
	});
	// setTimeout(() => { console.log(socket); }, 3000)
	//ClientList Show
	useEffect(() => {
		const handleClickOutside = (event) => {
			// setSearchCls(false);
		};

		$(document).on("click", handleClickOutside);

		return () => {
			$(document).off("click", handleClickOutside);
		};
	}, []);

	const clearFormData = (form) => {
		dispatch(clearForm({ form }));
	};
	const handleChangeSearch = (event) => {
		// //console.log("handleChangeSearch");
		// //console.log(event.target.value);
		setSearchParams((prev) => ({
			...prev,
			search: event.target.value,
		}));
	};
	const handleSuccessiveAdding = () => {
		swal(clientAdded);
		toggler();
	};
	const handleErrorAdding = ({ response }) => {
		let config;
		let status = response?.status;
		switch (status) {
			case 500:
				config = serverError;
				break;
			default:
				config = errorMessageGenerator(response?.data.message);
				break;
		}
		swal(config);
	};
	// const handleSettled = () => {
	// 	dispatch(clearForm({ clientAddForm }));
	// };

	const { mutate, isPending } = useAddClient({
		handleSuccessiveAdding,
		handleErrorAdding,
		// handleSettled,
	});
	const addClientFunction = (values) => {
		const { name, email, phone } = values;
		var str = name;
		var strCaps = str.charAt(0).toUpperCase() + str.slice(1);
		let zzz = email;
		let yyy = zzz.toLowerCase();

		const data = {
			email: yyy,
			ownerId: _id,
			phone: phone,
			name: strCaps,
		};
		mutate({ data, token: authState.token });
	};

	const toggler = () => {
		setToggleModal((prev) => !prev);
		setInitialFormValues({
			name: "",
			email: "",
			phone: "",
			password: "",
		});
	};

	const otpToggler = () => {
		setToggleOtpModal((prev) => !prev);
	};

	//OTP Input
	const OTPSubmit = () => {
		let OtP =
			OTPForm.value.mpin1 +
			OTPForm.value.mpin2 +
			OTPForm.value.mpin3 +
			OTPForm.value.mpin4 +
			OTPForm.value.mpin5 +
			OTPForm.value.mpin6;
		let data = { id: singleCurrentId, mpin: OtP };

		if (OTPForm.value.mpin1 === "") {
			$("#otp_error_msg").html("Please Provide a Valid M-Pin");
		}
		adminVerifyMutate({ data, token: authState.token });
		clearFormData("OTPForm");
		otpToggler();
	};

	// OTP MPIN Creating
	const otpProsArray = [
		{
			divClassName: "otp_one",
			labelClassName: "otp_label",
			inputType: "text",
			inputId: "mpin1",
			inputName: "mpin1",
			maxLength: 1,
			autoComplete: "off",
			inputClassName: "otp_type",
			focus: true,
			pClassName: "otp_error",
			req: true,
			form: "OTPForm",
			validation: "otp",
		},
		{
			divClassName: "otp_one",
			labelClassName: "otp_label",
			inputType: "text",
			inputId: "mpin2",
			inputName: "mpin2",
			maxLength: 1,
			autoComplete: "off",
			inputClassName: "otp_type",
			focus: false,
			pClassName: "otp_error",
			req: true,
			form: "OTPForm",
			validation: "otp",
		},
		{
			divClassName: "otp_one",
			labelClassName: "otp_label",
			inputType: "text",
			inputId: "mpin3",
			inputName: "mpin3",
			maxLength: 1,
			autoComplete: "off",
			inputClassName: "otp_type",
			focus: false,
			pClassName: "otp_error",
			req: true,
			form: "OTPForm",
			validation: "otp",
		},
		{
			divClassName: "otp_one",
			labelClassName: "otp_label",
			inputType: "text",
			inputId: "mpin4",
			inputName: "mpin4",
			maxLength: 1,
			autoComplete: "off",
			inputClassName: "otp_type",
			focus: false,
			pClassName: "otp_error",
			req: true,
			form: "OTPForm",
			validation: "otp",
		},
		{
			divClassName: "otp_one",
			labelClassName: "otp_label",
			inputType: "text",
			inputId: "mpin5",
			inputName: "mpin5",
			maxLength: 1,
			autoComplete: "off",
			inputClassName: "otp_type",
			focus: false,
			pClassName: "otp_error",
			req: true,
			form: "OTPForm",
			validation: "otp",
		},
		{
			divClassName: "otp_one",
			labelClassName: "otp_label",
			inputType: "text",
			inputId: "mpin6",
			inputName: "mpin6",
			maxLength: 1,
			autoComplete: "off",
			inputClassName: "otp_type",
			focus: false,
			pClassName: "otp_error",
			req: true,
			form: "OTPForm",
			validation: "otp",
		},
	];

	//OTP Button Creating
	const buttonConfigOTP = [
		{
			text: "Next",
			id: "auto-submit",
			className: "modal_btn btn_confirm",
			onClick: [[OTPSubmit]],
			datavalue: "1",
			form: "OTPForm",
			isValidObject: OTPForm["isValid"],
		},
		{
			text: "Close",
			className: "modal_btn btn_close",
			onClick: [[], [otpToggler]],
			datavalue: "1",
			form: "OTPForm",
			isValidObject: OTPForm["OTPForm"],
		},
	];
	const handleClientModalOpen = () => {
		//console.log("handleClientModalOpen");
		setToggleModal(true);
		setInitialFormValues({
			name: "",
			email: "",
			phone: "",
			password: "",
		});
	};
	const documentTitle = (
		<Helmet>
			<title>autoaudit.ai &#10072; ClientList Page </title>
		</Helmet>
	);

	if (isError) {
	}
	if (isSuccess) {
	}
	return (
		<>
			{documentTitle}
			<InfoHeader
					_id={_id}
					isSuperAdmin={isSuperAdmin}
					isSuccess={isSuccess}
					data={clientListData}
					isLoading={isLoading}
					isError={isError}
					clientsCount={clientListData?.totalCount}
					isAdmin={isAdmin}
					value={searchParams.search}
					handleChangeSearch={handleChangeSearch}
					name={name || clientListData?.adminName}
					toggler={toggler}
				/>
			<ListHeader />
			<section id="contact_lists"
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4 }}
			>
				
				{/* clientList */}

				<ClientList
					token={authState.token}
					refetch={refetch}
					isSuccess={isSuccess}
					data={clientListData}
					isLoading={isLoading}
					isError={isError}
					handleClientModalOpen={handleClientModalOpen}
					otpToggler={otpToggler}
					toggler={toggler}
					setSingleCurrentId={setSingleCurrentId}
				/>
			</section>

			{toggleModal && (
				<LoadingOverlayWrapper spinner active={isPending}>
					<Formik
						initialValues={initialFormValues}
						validationSchema={addMemberSchema}
						onSubmit={(values) => {
							addClientFunction(values);
						}}
					>
						{({
							values,
							handleChange,
							handleBlur,
							errors,
							touched,
							setFieldError,
							setFieldTouched,
							setFieldValue,
						}) => (
							<Form>
								<Modal>
									<ModalHeader header="Add Client" onClick={toggler} />
									<ModalBody>
										<div className="parent_div">
											<div className="login_option">
												<div>
													<label className="input_label" name="Name">
														<span>
															Name <span style={{ color: "red" }}>*</span>
														</span>
													</label>
													<Field
														name="name"
														placeholder="Client's name"
														maxLength="40"
														className="input_type"
														autoComplete="off"
														autoFocus
														disabled={isPending}
													/>
													<p className="error">
														{errors.name && touched.name ? errors.name : ""}
													</p>
													<label className="input_label" name="phone">
														<span>
															Phone Number
															<span style={{ color: "red" }}> *</span>
														</span>
													</label>
												</div>

												<div>
													<PhoneInput
														placeholder="Client's phone number"
														defaultCountry="IN"
														id="phoneId"
														className="phoneInput"
														disabled={isPending}
														autoComplete="off"
														value={values.phone} // Pass the Formik form field value to PhoneInput
														onChange={(phoneValue) => {
															handleChange({
																target: {
																	name: "phone",
																	value: phoneValue, // Update the Formik form field value
																},
															});
														}}
														onBlur={() => {
															// Manually trigger onBlur validation and set field error
															handleBlur("phone");
															if (
																values.phone ||
																!isValidPhoneNumber(values?.phone ?? "")
															) {
																setFieldError("phone", errors.phone);
																setFieldTouched("phone", true);
															}
														}}
													/>
													<p className="error">
														{errors.phone && touched.phone ? errors.phone : ""}
													</p>
												</div>

												<div>
													<label className="input_label" name="email">
														<span>
															Email <span style={{ color: "red" }}>*</span>
														</span>
													</label>

													<Field
														name="email"
														placeholder="Client's email address"
														maxLength="60"
														className="input_type"
														autoComplete="off"
														disabled={isPending}
														onChange={(event) => {
															const uppercaseValue =
																event.target.value.toLowerCase();
															setFieldValue("email", uppercaseValue);
														}}
													/>
													<p className="error">
														{errors.email && touched.email ? errors.email : ""}
													</p>
												</div>
											</div>
										</div>
									</ModalBody>
									<div className="modal_footer right_side">
										<div className="footer_align">
											<button
												type="submit"
												className="modal_btn btn_confirm"
												disabled={isPending}
											>
												{" "}
												Save{" "}
											</button>
											<button
												disabled={isPending}
												onClick={toggler}
												type="button"
												className="modal_btn btn_close"
											>
												{" "}
												Cancel{" "}
											</button>
										</div>
									</div>{" "}
								</Modal>
							</Form>
						)}
					</Formik>
				</LoadingOverlayWrapper>
			)}
			{toggleotpModal && (
				<Modal>
					<ModalHeader header="Enter M-PIN" onClick={otpToggler} />

					<ModalBody modal_grid="client_body_one">
						<div className="client_body">
							{otpProsArray.map((config, index) => (
								<Input key={index} {...config} />
							))}
						</div>
						<p id="otp_error_msg" className="error center"></p>
					</ModalBody>

					<ModalFooter buttonConfig={buttonConfigOTP}></ModalFooter>
				</Modal>
			)}
		</>
	);
}

export default ClientListPage;
