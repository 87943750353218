import React, { useState } from 'react'
import { Field, Formik, Form } from 'formik'
import { addAdminResetPasswordSchema } from '../validation/Validation'
import Label from './Label'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import Modal from './Modal'
import ModalHeader from './ModalHeader'
import ModalBody from './ModalBody'
import ModalFooter from './ModalFooter'
import { useResetPassword } from '../Hooks/tanqueryHooks'
import { useDispatch, useSelector } from 'react-redux'
import { setLogout } from '../slice/authSlice'
import swal from "sweetalert";
import { errorMessageGenerator, serverError } from '../util/swalConfigs'

const ResetPasswordFormForAdmin = ({ adminUserId, handleCloseResetPasswordForm }) => {
    const { token, role } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const [warning, setWarning] = useState(false);

    const handleSuccess = () => {
        swal({
            title: "Success!",
            text: "Password Changed Successfully",
            icon: "success",
            button: false,
            timer: 2000,
        }).then(() => {
            role === "admin" ? dispatch(setLogout()) : (handleCloseResetPasswordForm())
        });
    }
    const handleError = (error) => {
        // console.log(error);
        const { response } = error;
        // console.log(response);
        let config;
        let status = response?.status;
        switch (status) {
            case 500:
                config = serverError;
                break;

            default:
                config = errorMessageGenerator(response?.data?.message);
                break;
        }
        swal(config);
    }
    const changePassword = useResetPassword({
        handleSuccess,
        handleError,
    })

    const [passwordIcon, setPasswordIcon] = useState({
        new: true,
        confirm: true,
    });


    const handleSubmit = (values) => {
        const data = {
            _id: adminUserId,
            newpassword: values.newPassword
        }
        changePassword.mutate({
            data,
            token
        })
        // Remove the misplaced .then


        // // Check if onChildValueChange is provided as a prop and it's a function
        // if (onChildValueChange && typeof onChildValueChange === "function") {
        //     onChildValueChange(values.newPasswordessag, true); // Pass the data you want to send to the parent
        // } else {
        //     // The rest of your axios post logic...
        //     apiCaller({ url: "adminchangepassword", data })
        //         .then(({ data }) => {
        //             setLoading(false);
        //             setWarning(false);
        //             if (data.status === true) {
        //                 swal({
        //                     title: "Success!",
        //                     text: "Password Changed Successfully",
        //                     icon: "success",
        //                     button: false,
        //                     timer: 2000,
        //                 }).then(() => {
        //                     dispatch(setLogout());
        //                 });
        //             }
        //         })
        //         .catch((error) => {
        //             if (error.message === "Network Error") {
        //                 swal({
        //                     title: "Warning!",
        //                     text: "Please Check Your Internet Connection",
        //                     icon: "warning",
        //                     button: false,
        //                     timer: 2000,
        //                 });
        //                 dispatch(clearForm({ form }));
        //             }
        //         });
        // }
    }
    // };
    const handleCancelModal = (e) => {
        setWarning(false);
    };
    const buttonConfigDelete = [
        {
            text: "Confirm",
            id: "submit",
            className: "modal_btn btn_confirm",
            onClick: [[], []],
            datavalue: "1",
            form: "clientAddForm",
        },
        {
            text: "Cancel",
            className: "modal_btn btn_close",
            onClick: [[], [handleCancelModal]],
            datavalue: "1",
            close: true,
            form: "clientAddForm",
        },
    ];
    return (
        <div className="password-generator">
            <div>
                <div className="resetPass">
                    <div className="reset_header_top"> <p className="reset_header">Reset Password</p></div>

                    {(
                        <>
                            <Formik
                                initialValues={{ newPassword: "", confirmPassword: "" }}
                                validationSchema={addAdminResetPasswordSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, validateForm }) => (
                                    <>
                                        <Form>
                                            <div className="resetConfirm">
                                                <div className="confirm_password_set">
                                                    <div className="reset_pwd">
                                                        <div className="oldPassword">
                                                            <Label
                                                                className="reset_label"
                                                                name="New Password"
                                                                text={
                                                                    <span>
                                                                        New Password
                                                                        <span style={{ color: "red" }}>*</span>
                                                                    </span>
                                                                }
                                                            />

                                                            <Field
                                                                type="text"
                                                                value={values.newPassword}
                                                                name="newPassword"
                                                                id={
                                                                    passwordIcon.new
                                                                        ? "newPassword"
                                                                        : "newPasswordVisible"
                                                                }
                                                                maxLength={16}
                                                                autoComplete="off"
                                                                className="reset_input"
                                                                autoFocus={true}
                                                                // readOnly={toggle.readOnlyForm}
                                                                spellCheck="false"
                                                            />

                                                            <p className="error">
                                                                {errors.newPassword && touched.newPassword
                                                                    ? errors.newPassword
                                                                    : ""}
                                                            </p>
                                                        </div>
                                                        <span
                                                            className="reset_eye"
                                                            onClick={() =>
                                                                setPasswordIcon((prev) => ({
                                                                    ...prev,
                                                                    new: !prev.new,
                                                                }))
                                                            }
                                                        >
                                                            {" "}
                                                            {passwordIcon.new ? <FiEye /> : <FiEyeOff />}
                                                        </span>
                                                    </div>

                                                    <div className="reset_pwd">
                                                        <div className="oldPassword">
                                                            <Label
                                                                className="reset_label"
                                                                name="Confirm New Password"
                                                                text={
                                                                    <span>
                                                                        Confirm New Password
                                                                        <span style={{ color: "red" }}>*</span>
                                                                    </span>
                                                                }
                                                            />

                                                            <Field
                                                                id={
                                                                    passwordIcon.confirm
                                                                        ? "confirmPassword"
                                                                        : "confirmPasswordVisible"
                                                                }
                                                                name="confirmPassword"
                                                                type="text"
                                                                maxLength={16}
                                                                value={values.confirmPassword}
                                                                autoComplete="off"
                                                                className="reset_input"
                                                                autoFocus={false}
                                                                // readOnly={resetConfirmConfig.readOnly}
                                                                spellCheck="false"
                                                            />

                                                            <p className="error">
                                                                {errors.confirmPassword && touched.confirmPassword
                                                                    ? errors.confirmPassword
                                                                    : ""}
                                                            </p>
                                                        </div>
                                                        <span
                                                            className="reset_eye"
                                                            onClick={() =>
                                                                setPasswordIcon((prev) => ({
                                                                    ...prev,
                                                                    confirm: !prev.confirm,
                                                                }))
                                                            }
                                                        >
                                                            {" "}
                                                            {passwordIcon.confirm ? <FiEye /> : <FiEyeOff />}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="passwordCondition">
                                                    <div className="password_condition">
                                                        <p className="textPassCri">
                                                            Password Requirements:
                                                        </p>
                                                        <ul>
                                                            <li>- Include at least 1 uppercase letter.</li>
                                                            <li>- Include at least 1 lowercase letter.</li>
                                                            <li>- Include at least 1 numeric character.</li>
                                                            <li>- Include at least 1 special character.</li>
                                                            <li>- Must be between 8 and 16 characters in length.</li>
                                                        </ul>
                                                        <br></br>
                                                        <p>
                                                            For enhanced security, please make sure your
                                                            chosen password adheres to these criteria.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                className={"rstButtonReset"}
                                            >
                                                <button
                                                    id="submit"
                                                    type="submit"
                                                    className="modal_btn btn_confirm"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    id="cancel"
                                                    type="button"
                                                    className="modal_btn btn_close"
                                                    onClick={handleCloseResetPasswordForm}
                                                >
                                                    Cancel
                                                </button>

                                                {/* {resetFormButtonConfig.map((config, index) => (
												<Buttons key={index} {...config} />
											))} */}
                                            </div>
                                            <div>
                                                {warning ? (
                                                    <>
                                                        <Modal>
                                                            <ModalHeader
                                                                header="Reset Password"
                                                                onClick={handleCancelModal}
                                                            />
                                                            <ModalBody modal_grid="client_body_one">
                                                                <div className="client_delete">
                                                                    <p className="deleteHeader">
                                                                        Are you sure you want to Reset the Password?
                                                                    </p>
                                                                </div>
                                                                <div></div>
                                                            </ModalBody>
                                                            <ModalFooter
                                                                buttonConfig={buttonConfigDelete}
                                                            ></ModalFooter>
                                                        </Modal>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </>
                    )}
                </div>
            </div>
        </div >
    )
}

export default ResetPasswordFormForAdmin