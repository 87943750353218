import React from "react";

function ClientIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.8398 10.5156V5.51562C19.8398 4.98519 19.6291 4.47648 19.2541 4.10141C18.879 3.72634 18.3703 3.51563 17.8398 3.51562H3.83984C3.30941 3.51563 2.8007 3.72634 2.42563 4.10141C2.05056 4.47648 1.83984 4.98519 1.83984 5.51562V21.5156L5.83984 17.5156H12.8398"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.1601 12.8989V14.7816H20.2773"
            stroke="currentColor"
            strokeWidth="1.09653"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3398 18.203V16.3203H17.2226"
            stroke="currentColor"
            strokeWidth="1.09653"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3398 16.3201L16.7958 17.6882C17.1331 18.0256 17.5503 18.2721 18.0085 18.4046C18.4668 18.5372 18.9512 18.5515 19.4165 18.4462C19.8817 18.3409 20.3128 18.1195 20.6694 17.8026C21.0259 17.4857 21.2964 17.0836 21.4555 16.6339"
            stroke="currentColor"
            strokeWidth="1.09653"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.043 14.4674C16.2021 14.0177 16.4726 13.6156 16.8291 13.2987C17.1857 12.9818 17.6168 12.7604 18.082 12.6551C18.5473 12.5498 19.0317 12.5641 19.49 12.6967C19.9482 12.8292 20.3654 13.0757 20.7027 13.4131L22.1587 14.7812"
            stroke="currentColor"
            strokeWidth="1.09653"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default ClientIcon;
