import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../slice/formDataSlice";

export default function useValidator() {
  const state = useSelector((state) => state.formData);
  const dispatch = useDispatch();
  const setState = (e) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data_validation");
    const form = e.target.getAttribute("data_form");
    const validator = () => {
      let isValid = false;

      switch (type) {
        case "email":
          {
            const emailRegex =
              // eslint-disable-next-line
              /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
            isValid = emailRegex.test(value);
          }

          break;
        case "password":
          {
            const regex =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%^&*])[A-Za-z\d@$!#%^&*]{8,16}$/;
            isValid = regex.test(value);
          }

          break;
        case "phone":
          {
            const regex = /^\d{10}$/;
            isValid = regex.test(value);
          }
          break;

        case "text":
          isValid = value.length > 2;
          break;
        case "otp":
          {
            const OTPRegex = /^\d$/;
            isValid = OTPRegex.test(value);
          }

          break;
        default:
          break;
      }
      return isValid;
    };

    const isValid = validator();

    dispatch(
      updateFormData({
        form,
        value: { [name]: value },
        isValid: { [name]: isValid },
        errorMsg: {
          [name]: isValid
            ? ""
            : name === "email"
            ? `Kindly Enter A Valid ${
                name.charAt(0).toUpperCase() + name.slice(1)
              } address`
            : name === "OTP"
            ? `Kindly Enter A Valid M-Pin`
            : name === "newPassword"
            ? `Kindly Enter A Valid New Password`
            : name === "confirmPassword"
            ? `Kindly Enter A Valid Confirm New Password`
            : `Kindly Enter A Valid ${
                name.charAt(0).toUpperCase() + name.slice(1)
              }`,
        },
      })
    );
  };
  return { state, setState };
}
