import React from "react";
import Error_pic_400 from "../ErrorPage/400.svg";
import Error_pic_401 from "../ErrorPage/401.svg";
import Error_pic_403 from "../ErrorPage/403.svg";
import Error_pic_404 from "../ErrorPage/404.svg";
import Error_pic_500 from "../ErrorPage/500.svg";
import { useNavigate } from "react-router-dom";

function ErrorComponent({ code, headerContnet, bodyContent, btnText = "Go back", redirection = "/" }) {
    const navigate = useNavigate();
    const errorPic = {
        400: Error_pic_400,
        401: Error_pic_401,
        403: Error_pic_403,
        404: Error_pic_404,
        429: Error_pic_403,
        500: Error_pic_500,
    };

    return (
        <>
            <section id="error_page">
                <div className="error_image">
                    <div className="error_pict">
                        <img
                            className="error_img"
                            src={errorPic[code]}
                            alt="404 Error"
                            title="404 Page"
                        />
                    </div>
                    <div className="error_header">
                        <p className="error_head">{headerContnet && headerContnet}</p>
                        <p className="error_para">
                            {bodyContent && bodyContent}
                            {/* Either something went wrong or the page doesn't exist anymore. */}
                        </p>
                    </div>
                    <div className="backto">
                        <button
                            className="submit"
                            onClick={() => {
                                navigate(redirection);
                            }}
                        >
                            {btnText}
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ErrorComponent;
