import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLogout } from "../slice/authSlice";
import ErrorComponent from "../components/ErrorComponent";

function Sessionpage() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setLogout());
	}, [dispatch]);

	return (
		<ErrorComponent
			code={401}
			headerContnet="Session has expired...!"
			btnText="Click to login"
		/>
	);
}

export default Sessionpage;
