// import { useDispatch } from "react-redux";

import { useEffect } from "react";
import { useGetAdmin } from "../../../Hooks/tanqueryHooks";
import InfoBadge from "../../admin/components/InfoBadge";

const InfoHeader = ({
	_id,
	isSuperAdmin,
	toggler = null,
	value,
	handleChangeSearch,
}) => {
	const { isLoading, isError, isSuccess, data, refetch } = useGetAdmin(_id);
	// here we make the useGetAdmin get call only once on the inital render to avoid fetch data on every re-rendering
	// to achieve this we add a flag enabled:false in the useGetAdmin hook (ctrl+click the useGetAdmin to see the details)
	useEffect(() => {
		refetch();
	}, [refetch]);

	if (isLoading) {
		return (
			<div className="no_contacts">
				<div className="contact_count contact_details">
					{/* Only got render if Super admin get login */}

					{isSuperAdmin && <InfoBadge key="name" name="Loading..." />}
					{/* only render the the client count if the admin has any clients belong to him */}
					<InfoBadge key="count" name="Loading..." />
				</div>

				<div className="memberlist_right">
					<InfoBadge key="search" name="Loading..." />

					{/* {clientsCount > 0 && (
						<SearchBox
							clsFunc={clsFunc}
							handleChange={handleChangeSearch}
							searchText={searchParams.search}
						/>
					)} */}
					<div className="card_button">
						<button className="primary_button" onClick={toggler}>
							+ Add Client
						</button>
					</div>
				</div>
			</div>
		);
	}
	if (isError) {
		return (
			<div className="no_contacts">
				<div className="contact_count contact_details">
					{/* Only got render if Super admin get login */}

					{isSuperAdmin && <InfoBadge key="name" name="Unable to load..." />}
					{/* only render the the client count if the admin has any clients belong to him */}
					<InfoBadge key="count" name="Unable to load..." />
				</div>

				<div className="memberlist_right">
					<InfoBadge key="search" name="Unable to load..." />

					{/* {clientsCount > 0 && (
						<SearchBox
							clsFunc={clsFunc}
							handleChange={handleChangeSearch}
							searchText={searchParams.search}
						/>
					)} */}
					<div className="card_button">
						<button className="primary_button" onClick={toggler}>
							+ Add Client
						</button>
					</div>
				</div>
			</div>
		);
	}
	if (isSuccess) {
		return (
			<div className="no_contacts">
				<div className="contact_count contact_details">
					{/* Only got render if Super admin get login */}

					{isSuperAdmin && (
						<InfoBadge name="Name" value={data?.response?.name} />
					)}
					{/* only render the the client count if the admin has any clients belong to him */}
					<InfoBadge name="Clients" value={data?.response?.clientCount} />
				</div>

				<div className="memberlist_right">
					{data?.response?.clientCount > 0 && (
						<div className="header_search">
							<input
								value={value}
								type="text"
								className="search_box"
								placeholder="Search your client here"
								id="search_box"
								onChange={handleChangeSearch}
								autoComplete="off"
							/>

							{/* {searchCls && (
							<div className="search_close" onClick={clsFunc}></div>
						)} */}
						</div>
					)}

					{/* {clientsCount > 0 && (
						<SearchBox
							clsFunc={clsFunc}
							handleChange={handleChangeSearch}
							searchText={searchParams.search}
						/>
					)} */}
					<div className="card_button">
						<button className="primary_button" onClick={toggler}>
							+ Add Client
						</button>
					</div>
				</div>
			</div>
		);
	}
};

export default InfoHeader;
