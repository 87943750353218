import React from "react";

function DeleteIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 6.51562H5H21"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 6.51562V20.5156C19 21.0461 18.7893 21.5548 18.4142 21.9298C18.0391 22.3049 17.5304 22.5156 17 22.5156H7C6.46957 22.5156 5.96086 22.3049 5.58579 21.9298C5.21071 21.5548 5 21.0461 5 20.5156V6.51562M8 6.51562V4.51562C8 3.98519 8.21071 3.47648 8.58579 3.10141C8.96086 2.72634 9.46957 2.51563 10 2.51562H14C14.5304 2.51563 15.0391 2.72634 15.4142 3.10141C15.7893 3.47648 16 3.98519 16 4.51562V6.51562"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default DeleteIcon;
