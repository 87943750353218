import React from "react";
import useWindowSize from "../../../Hooks/useWindowSize";
import { NavLink } from "react-router-dom";
import { useSelector, } from "react-redux";

const ActivitySideMenu = () => {

	const { role } = useSelector((state) => state.auth)
	const size = useWindowSize();
	return (
		<div className="settingsMenu" style={{ height: '100%'}}>
			<nav className="">
				<div className="centered-list ">
					<NavLink to="/dashboard/activity/myactivity" className={({ isActive }) => isActive ? "link active" : "link"} end >My Activity</NavLink>

					{role === 'superadmin' && <NavLink to="/dashboard/activity/adminList" className={({ isActive }) => isActive ? "link active" : "link"} >Admins</NavLink>}

				</div>
			</nav >




		</div >

	);
};

export default ActivitySideMenu;
