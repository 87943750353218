import React from "react";

function BlockIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12.0039"
            cy="12.5154"
            r="9.30859"
            stroke="currentColor"
            strokeWidth="1.63593"
          />
          <path
            d="M18.2383 5.88379L5.40234 18.7171"
            stroke="currentColor"
            strokeWidth="1.63593"
          />
        </svg>
      </span>
    </>
  );
}

export default BlockIcon;
