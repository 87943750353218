import React from "react";

function MailIcon({ svgClass, spanClass, TooltipId, ToolContent, onClick }) {
  return (
    <>
      <span
        className={spanClass}
        data-tooltip-id={TooltipId}
        data-tooltip-content={ToolContent}
        onClick={onClick}
      >
        <svg
          className={svgClass}
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.64766 4.81421H20.0477C21.0377 4.81421 21.8477 5.62421 21.8477 6.61421V17.4142C21.8477 18.4042 21.0377 19.2142 20.0477 19.2142H5.64766C4.65766 19.2142 3.84766 18.4042 3.84766 17.4142V6.61421C3.84766 5.62421 4.65766 4.81421 5.64766 4.81421Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.8477 6.61426L12.8477 12.9143L3.84766 6.61426"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </>
  );
}

export default MailIcon;
