import React, { useRef, useState } from "react";
import "../asset/tfa/tfa.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import apiCaller from "../util/apiCaller";
import { getItem, setItem, updateLocalUser } from "../util/helper";
import { setUser } from "../slice/authSlice";
import { useDispatch } from "react-redux";

const initialCode = new Array(6).fill("");
function TwoFactorAuthPage() {
	// console.log("tfa page");
	const [codes, setCodes] = useState(initialCode);
	let role = getItem("role");
	const navigate = useNavigate();
	const inputRefs = useRef([]);
	const dispatch = useDispatch();
	const handleCodeChange = (newCode, index) => {
		const newCodes = [
			...codes.slice(0, index),
			newCode,
			...codes.slice(index + 1),
		];
		setCodes(newCodes);
		if (newCode.length > 0 && index + 1 < codes.length) {
			inputRefs.current[index + 1].focus();
		}
	};

	const handleKeyDown = (e, index) => {
		["e", "E", "+", "-", ""].includes(e.key) && e.preventDefault();

		if (e.key === "Backspace" && index - 1 >= 0) {
			e.preventDefault();
			const newCodes = [
				...codes.slice(0, index),
				"",
				...codes.slice(index + 1),
			];
			setCodes(newCodes);
			inputRefs.current[index - 1].focus();
		}
		if (e.key === "ArrowLeft" && index - 1 >= 0) {
			inputRefs.current[index - 1].focus();
		}
		if (e.key === "ArrowRight" && index + 1 < codes.length) {
			inputRefs.current[index + 1].focus();
		}
	};
	const handleClick = (index) => {
		// Find the next empty field and focus on it
		for (let i = 0; i < codes.length; i++) {
			if (codes[i] === "" && inputRefs.current[i]) {
				inputRefs.current[i].focus();
				break;
			}
		}
	};
	const handlePaste = (e) => {
		e.preventDefault();
		// Get the pasted value
		const pastedValue = e.clipboardData.getData("text");
		const digits = pastedValue.split("");
		// Update the state with the digits
		setCodes(digits);
	};

	const tfasubmit = (e) => {
		// console.log("TFA submit");
		e.preventDefault();

		let tfa = codes.join("");
		const data = {
			token: tfa,
		};
		if (data.token.length === 6) {
			apiCaller({ url: "verify", data })
				.then((respons) => {
					if (respons.data.status) {
						swal({
							title: "Success!",
							text: "TFA verified Successful!",
							icon: "success",
							button: false,
							timer: 2000,
						}).then(() => {
							updateLocalUser({ isTFADone: true });
							dispatch(setUser({ isTFADone: true }));

							role === "superadmin"
								? navigate("/dashboard/adminList")
								: navigate("/dashboard/clientList");

						})

					} else {
						swal({
							title: "Failed!",
							text: "Invalid TFA!",
							icon: "warning",
							button: false,
							timer: 2000,
						});
					}
				})
				.catch((err) => {
					// console.log(err);
					swal({
						title: "Failed!",
						text: `${err?.response?.data?.message}`,
						icon: "warning",
						button: false,
						timer: 2000,
					});
				});
		} else {
			swal({
				title: "Failed!",
				text: "Enter Valid Code!",
				icon: "warning",
				button: false,
				timer: 2000,
			});
		}
	};

	return (
		<div className="fix_width tfa-page">
			<div className="parent_div">
				<div className="login_section">
					<h2 className="forgot_header">Two-Factor Authentication</h2>
					<p className="forgot_para">
						Enter the six-digit authentication code from your authenticator app.
					</p>

					<form>
						<div className="code-input">
							{codes.map((code, index) => (
								<input
									key={index}
									type="number"
									id={`code-${index}`}
									name={`code-${index}`}
									value={code}
									onChange={(e) => handleCodeChange(e.target.value, index)}
									data-maxlength="1"
									onInput={(e) => {
										e.target.value = e.target.value.slice(0, 1);
									}}
									onKeyDown={(e) => handleKeyDown(e, index)}
									required
									autoComplete="off"
									ref={(component) => {
										inputRefs.current[index] = component;
									}}
									onClick={() => handleClick(index)}
									onPaste={(e) => handlePaste(e)}
								/>
							))}
						</div>
						<div className="but-div"></div>
					</form>
				</div>
			</div>
			<button
				onClick={(e) => {
					tfasubmit(e);
				}}
				className="submit"
			>
				submit
			</button>
		</div>
	);
}

export default TwoFactorAuthPage;
