import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const forgetPassSchema = Yup.object().shape({
	email: Yup.string()
		.required("Email is required")
		.email("Kindly enter valid email address")
		.matches(
			// eslint-disable-next-line
			/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
			"Kindly enter valid email address"
		),
});

export const loginSchema = Yup.object().shape({
	email: Yup.string()
		.required("Email is required")
		.matches(
			// eslint-disable-next-line
			/^([a-zA-Z0-9_\.-]+)@([\da-zA-Z\.-]+)\.([a-zA-Z\.]{2,6})$/,
			"Invalid email"
		),
	password: Yup.string()
		.required("Password is required")
		.min(8, "Password must be at least 8 characters")
		.max(16, "Password cannot exceed 16 characters")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`[\]\\;'/=_+-]).{8,}$/,
			"Invalid Password"
		),
});

export const addBankSchema = Yup.object().shape({
	name: Yup.string()
		.required("Name is required")
		.min(
			3,
			"Please ensure that the name is a minimum of 3 characters in length"
		)
		.matches(/^[A-Z\s]+$/, "Name must contain only uppercase letters"),
});

export const addCategorySchema = Yup.object().shape({
	name: Yup.string()
		.required("Name is required")
		.min(
			3,
			"Please ensure that the name is a minimum of 3 characters in length"
		)
		.matches(/^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+){0,2}$/, "Invalid Name"),
});

// add Member form validation schema
export const addMemberSchema = Yup.object().shape({
	name: Yup.string()
		.required("Name is required")
		.min(
			3,
			"Please ensure that the name is a minimum of 3 characters in length"
		)
		.matches(
			/^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+){0,10}$/,
			"The name field must only contain alphanumeric characters"
		),
	email: Yup.string()
		.required("Email is required")
		.matches(
			// eslint-disable-next-line
			/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
			"Invalid email"
		),
	phone: Yup.string()
		.required("Phone number is required")
		// Add your phone validation logic here
		.test(
			"is-valid-phone",
			"Please provide a valid phone number",
			function (value) {
				return isValidPhoneNumber(value ?? "");
			}
		),
});

// admin form validation schema
export const addAdminSchema = Yup.object().shape({
	name: Yup.string()
		.required("Name is required")
		.min(
			3,
			"Please ensure that the name is a minimum of 3 characters in length"
		)
		.matches(
			/^(?=.*[a-zA-Z])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+){0,10}$/,
			"The name field must only contain alphanumeric characters"
		),
	email: Yup.string()
		.required("Email is required")
		.matches(
			// eslint-disable-next-line
			/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/,
			"Invalid email"
		),
	phone: Yup.string()
		.required("Phone number is required")
		// Add your phone validation logic here
		.test(
			"is-valid-phone",
			"Please provide a valid phone number",
			function (value) {
				return isValidPhoneNumber(value ?? "");
			}
		),
	password: Yup.string()
		.required("Password is required")
		// .min(8, "Password must be at least 8 characters")
		.max(16, "Password cannot exceed 16 characters")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`[\]\\;'/=_+-]).{8,}$/,
			"Password must be 8+ characters, with uppercase, lowercase, a digit, and a special character"
		),
});

export const addAdminResetPasswordSchema = Yup.object().shape({
	newPassword: Yup.string()
		.required("New password is required")
		.min(8, "Password must be at least 8 characters")
		.max(16, "Password cannot exceed 16 characters")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`[\]\\;'/=_+-]).{8,}$/,
			"Kindly enter a valid New Password"
		),
	confirmPassword: Yup.string()
		.required("Confirm password is required")
		.min(8, "Password must be at least 8 characters")
		.max(16, "Password cannot exceed 16 characters")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>~`[\]\\;'/=_+-]).{8,}$/,
			"Kindly enter a valid new password"
		)
		.oneOf(
			[Yup.ref("newPassword"), null],
			"Confirm password must match the new password"
		),
});

export const OldpasswordSchema = Yup.object().shape({

	oldpassword: Yup.string()
		.required("Current password is required")
	// .min(8, "Password must be at least 8 characters")
	// .max(16, "Password cannot exceed 16 characters")
	// .matches(
	// 	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%^&*])[A-Za-z\d@$!#%^&*]+$/,
	// 	"Kindly enter a valid New Password"
	// ),
});