export const styles = {
  menuList: (base) => ({
    ...base,
    "::-webkit-scrollbar": {
      display: "none",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected ? "#ffff" : "#1e2022",
    backgroundColor: state.isSelected ? "var(--primarycolor)" : "white",
    "&:hover": {
      backgroundColor: state.isSelected ? "var(--primarycolor)" : "#daeeda",
    },
    padding: ".5rem 3rem .5rem .5rem",
    cursor: "pointer",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
  control: (base, state) => ({
    ...base,
    border: "1px solid #c3c3c3",
    boxShadow: 'none',
    '&:hover': {
        border: '1px solid #c3c3c3',
    }
})
};
