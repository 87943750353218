import React, { useEffect, useRef, useState } from "react";
import { checkDateAndTime, dateAndTime, funDate, funcTime, nameFunc } from "../../../util/helper";

const EmailList = ({ data, tempfilter, clientID, size }) => {
	const contentRef = useRef(null);
	const [sectionHeight, setSectionHeight] = useState(600);
	// with resize event listener
  useEffect(() => {
		const chatDataTop = document.querySelector(".chatData")?.getBoundingClientRect().top;
		const chatDisplayBottom = document.querySelector(".displayFunction")?.getBoundingClientRect().bottom;
		setSectionHeight(chatDisplayBottom - chatDataTop);
    const handleResize = () => {
			const chatDataTop = document.querySelector(".chatData")?.getBoundingClientRect().top;
		const chatDisplayBottom = document.querySelector(".displayFunction")?.getBoundingClientRect().bottom;
		setSectionHeight(chatDisplayBottom - chatDataTop);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

	return (
		<div ref={contentRef} className="mailContact" style={{ height: sectionHeight-20 }}>
			<>
				{data.map((obj) => (
					<div key={obj._id} className="emailNameList">
						<div
							className={
								obj?._id === clientID ? "emailNamesActive" : "emailNames"
							}
							onClick={() => {
								tempfilter(obj?._id, obj?.emailContent[0]);
							}}
						>
							<div className="emailNameDetails">
								<div className="emailSenderName">
									<p className="eSender">
										{nameFunc(obj?.emailContent[0]?.from)}
									</p>
								</div>
								<div className="emailTime">
									<p className="eTime">
										{checkDateAndTime(obj?.emailContent[0]?.date)}
									</p>
								</div>
							</div>
							<div className="emailContent">
								<p className="emailContentpTag">{obj?.emailContent[0]?.text}</p>
							</div>
						</div>
					</div>
				))}
			</>
		</div>
	);
};

export default EmailList;
