import { configureStore } from "@reduxjs/toolkit";
// import clientReducer from "../slice/clientSlice";
import memberSlice from "../slice/memberSlice";
import selectedClientSlice from "../slice/selectedClientSlice";
import formDataSlice from "../slice/formDataSlice";
import authSlice, { setLogout } from "../slice/authSlice";
import reactiveParamsSlice from "../slice/reactiveParamsSlice";
import paginationReducer from '../slice/paginationSlice';

export const store = configureStore({
	reducer: {
		members: memberSlice,
		selectedUser: selectedClientSlice,
		formData: formDataSlice,
		auth: authSlice,
		reactiveParams: reactiveParamsSlice,
		pagination: paginationReducer,
	},
});

// subscribe to auth reducer changes
window.addEventListener('storage', (event) => {
  if (event.key === 'auth'){
		if(event?.oldValue && event?.newValue !== event?.oldValue) {
			store.dispatch(setLogout());
		}
	} 
});