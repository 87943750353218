import { createContext, useState, useContext } from "react";

const SharedFunctionsContext = createContext();

export default SharedFunctionsContext;

const CustomContext = createContext();

export const CustomUseContext = () => useContext(CustomContext);

export const ContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <CustomContext.Provider value={{ loading, setLoading }}>
      {" "}
      {children}{" "}
    </CustomContext.Provider>
  );
};
