import { createSlice } from "@reduxjs/toolkit";
import { clearStorage, getItem } from "../util/helper";
import { clearSocketInstance } from "../services/socketService";
// debugger
const isLoggedIn = getItem("user_persist") || false;
// const isRirected = getItem("user_redirected")
//   ? getItem("user_redirected")
//   : false;
const user = getItem("user") || false;
if (user) {
	var { _id, name, email, password, role, tfaStatus, isTFADone } = user;
}
const initialState = {
	// sessionStorage need to sync with the isLoggedIn property
	// but it is also get updated from the login page when the user get login
	_id: _id ? _id : "",
	name: name ? name.charAt(0).toUpperCase() + name.slice(1) : "",
	email,
	password,
	isLoggedIn: isLoggedIn,
	// isRirected: isRirected,
	token: getItem("auth") ?? null,
	currentPage: "",
	adminName: "",
	activity: "",
	role: role ?? "",
	tfaStatus: tfaStatus ? tfaStatus : false, // for TFA
	isTFADone: isTFADone ? isTFADone : false
};

const authSlice = createSlice({
	name: "auth",
	initialState,

	reducers: {
		setUser: (state, action) => {
			// console.log("From setUser", action.payload);
			const {
				_id = state._id,
				name = state.name,
				email = state.email,
				token = state.token,
				activity = state.activity,
				adminName = state.adminName,
				role = state.role,
				tfaStatus = state.tfaStatus,
				isTFADone = state.isTFADone
			} = action.payload;
			state._id = _id;
			state.name = name.charAt(0).toUpperCase() + name.slice(1);
			state.email = email;
			state.token = token;
			state.activity = activity;
			state.adminName = adminName;
			state.role = role;
			state.tfaStatus = tfaStatus;
			state.isTFADone = isTFADone;
		},
		setLogin: (state, action) => {
			state.isLoggedIn = action.payload.status;
		},
		// setRedirection: (state, action) => {
		// 	state.isRirected = action.payload.status;
		// },
		setLogout: (state, action) => {
			clearStorage();
			clearSocketInstance();
			state._id = "";
			state.name = "";
			state.email = "";
			state.isLoggedIn = false;
			state.token = null;
			state.role = null;
			state.activity = null;
			state.adminName = null;
			state.isTFADone = null;
			state.tfaStatus = null;
		},
		setTFA: (state, action) => {
			state.isTFADone = action.payload;
		},
	},
});

export default authSlice.reducer;
export const { setLogin, setUser, setLogout, setTFA } = authSlice.actions;
