import { createSlice } from "@reduxjs/toolkit";

const selectedClientSlice = createSlice({
  name: "selectedClient",
  initialState: {
    selectedClientId: 1,
    currentTap: "chat",
  },
  reducers: {
    addClientId: (state, action) => ({
      ...state,
      selectedClientId: action.payload.id,
    }),
    setCurrentTab: (state, action) => ({
      ...state,
      currentTap: action.payload.currentTap,
    }),
  },
});

export const { addClientId } = selectedClientSlice.actions;
export default selectedClientSlice.reducer;
