import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiCaller from "../util/apiCaller";
// import { upateMember, getClients } from "../../slice/memberSlice";

export const getClients = createAsyncThunk("getClients", async (userId) => {
	// const authState = useSelector((state) => state.auth);
	// console.log(userId, "from thunk");
	const data = {
		ownerId: userId,
		pageNumber: 1,
		limit: 10,
		search: "",
	};
	const response = await apiCaller({ url: "listClient", data });
	return response;
});

// http://192.168.0.155:3000/user/addClient
// export const getClients = createAsyncThunk('getClients',
//  async (userId) => {
//   console.log(userId);
// const response = await axios.get(`http://192.168.0.155:4682/user/listClient`)
//  return response
// });

const clientSlice = createSlice({
	name: "client",
	initialState: {
		members: [],
		currentMemberId: "",
		loading: false,
	},
	reducers: {
		setCurrentMember: (state, action) => {
			state.currentMemberId = action.payload._id;
		},
		upateMember: (state, action) => {
			const { _id, name, email, phone } = action.payload;
			const existingClient = state.members.find((client) => client._id === _id);
			if (existingClient) {
				// Update an existing client
				state.members = state.members.map((client) => {
					if (client._id === _id) {
						return {
							...client,
							name,
							phone,
							email,
						};
					}
					return client;
				});
			} else {
				// Add a new client
				const newClient = {
					_id,
					name,
					phone,
					email,
				};
				state.members.push(newClient);
			}
		},

		findMembers: (state, action) => {
			state.members = action.payload;
		},
	},

	extraReducers: {
		[getClients.pending]: (state, action) => {
			state.loading = true;
		},
		[getClients.fulfilled]: (state, action) => {
			state.loading = false;
			state.members = [...action.payload.data.response];
		},
		[getClients.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { upateMember, setCurrentMember, findMembers } =
	clientSlice.actions;
export default clientSlice.reducer;
