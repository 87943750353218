import React, { useEffect, useRef, useState } from "react";
import { checkDateAndTime, funDate } from "../../../util/helper";
import Loader from "../../../components/Loader";
import { useParams } from "react-router-dom";
import { useGetAllThread } from "../../../Hooks/tanqueryHooks";
import { setActiveThread } from "../../../slice/reactiveParamsSlice";
import { useDispatch, useSelector } from "react-redux"
import RippleNotify from "../../../components/RippleNotify";
import NotificationCount from "../../../components/NotificationCount";
const SMSList = ({
	setError,
	size,
	newNotification,
}) => {
	var { id: userId } = useParams();
	const dispatch = useDispatch();
	const { activeThread } = useSelector(state => state.reactiveParams)
	const {
		isSuccess,
		isLoading,
		isError,
		isPending,
		data: smsData,
	} = useGetAllThread({ userId })
	const contentRef = useRef(null);
	const [sectionHeight, setSectionHeight] = useState(600);

	useEffect(() => {

		if (isSuccess && smsData?.data?.length > 0) {
			const data = smsData?.data;
			const defaultThreadId = data[0]?.thread_id;
			// console.log("========================================", defaultThreadId);
			handleActiveThread(defaultThreadId);
		}
	}, [isSuccess])


	const handleActiveThread = (defaultThreadId) => {
		dispatch(setActiveThread(defaultThreadId))
	};
	// with resize event listener
  useEffect(() => {
		const chatDataTop = document.querySelector(".chatData")?.getBoundingClientRect().top;
		const chatDisplayBottom = document.querySelector(".displayFunction")?.getBoundingClientRect().bottom;
		setSectionHeight(chatDisplayBottom - chatDataTop);
    const handleResize = () => {
			const chatDataTop = document.querySelector(".chatData")?.getBoundingClientRect().top;
		const chatDisplayBottom = document.querySelector(".displayFunction")?.getBoundingClientRect().bottom;
		setSectionHeight(chatDisplayBottom - chatDataTop);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

	if (isLoading || isPending) {
		<div ref={contentRef}className="chatNameList" style={{ height: sectionHeight-30 }}>
			<div className="loaderDisplay">
				<Loader />
			</div>
		</div>;
	}
	if (isError || (isSuccess && smsData?.data?.length === 0)) {
		return setError({ status: true, type: 1 });
	}

	if (isSuccess && smsData?.data?.length > 0) {
		const data = smsData?.data;
		const jsx = (
			<div ref={contentRef} className="chatNameList" style={{ height: sectionHeight-30 }}>
				<div className="chatNameStyleList">
					{data?.map((data, index) => {
						return (
							<div
								key={data?._id} // Add a unique key prop
								onClick={() => handleActiveThread(data?.thread_id)}
								id={data?.thread_id}
								name={data?.address}
								className={
									data?.thread_id === activeThread
										? newNotification
											? "chatNamesActiveNewMsg"
											: "chatNamesActive"
										: "chatNames"
								}
							>
								<div className="imgDiv">
									<p className="profilePic">{data?.address?.charAt(0)}</p>
								</div>
								<div className="chatName">
									<div className="chatcontent">
										<div className="chatcontentName">
											<p className="cName">{data?.address}</p>
										</div>
										<div></div>

										<div className="chatcontenttime">
											<p className="cTime">{checkDateAndTime(data?.date)}</p>
										</div>
									</div>
									<div className="chatcontentText">
										<p className="cText">{data?.body} </p>
										{data?.new_sms_count > 0 && <NotificationCount count={data?.new_sms_count} />}
									</div>
								</div>
							</div>
					)})}
				</div>
			</div>
		);

		return jsx;
	}
};

export default SMSList;
