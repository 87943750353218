import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ChatHeader = ({ butVisible, visibleSMS, visibleEmail }) => {
	const history = useNavigate();

	return (
		<div className="chatHeader">
			<div>
				<button
					className={butVisible==='chat' ? "smsButton active" : "smsButton"}
					onClick={visibleSMS}
				>
					{" "}
					SMS{" "}
				</button>
				<button
					className={butVisible==='email' ? "smsButton active" : "smsButton"}
					onClick={visibleEmail}
				>
					{" "}
					Emails{" "}
				</button>
			</div>
			<div className="back-btn-container">
				<div className="card_button">
					<button className="primary_button" onClick={() => history(-1)}>
						<div className="back-btn">
							<IoMdArrowBack />
							{"  "} Back
						</div>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ChatHeader;
