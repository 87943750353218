import React from "react";
import "../asset/loader/loader.css";

function Loader() {
  return (
    <div>
      <div className="center_div">
        <span className="circle_loading"></span>
      </div>
    </div>
  );
}

export default Loader;
