import $ from "jquery";
import notify from "../notify.mp3";
import axios from "axios";
import moment from "moment";

export const errorTrigger = (form, dispatch, updateFormData, isValid) => {
	const inputFields = $(`input[data_form=${form}]`);
	inputFields.each((index, element) => {
		const name = $(element).attr("name");
		const value = $(element).val();
		const isValidCurrnetField = isValid[name];
		dispatch(
			updateFormData({
				form,
				value: { [name]: value },
				isValid: { [name]: isValidCurrnetField },
				errorMsg: {
					[name]: isValidCurrnetField``
						? ""
						: name === "email"
							? `Kindly enter a valid ${name.charAt(0).toUpperCase() + name.slice(1)
							} address`
							: name === "OTP"
								? `Kindly enter a valid M-Pin`
								: name === "newPassword"
									? `Kindly enter valid New Password`
									: name === "confirmPassword"
										? `Kindly enter a valid Confirm New Password`
										: `Kindly enter a valid ${name.charAt(0).toUpperCase() + name.slice(1)
										}`,
				},
			})
		);
	});
};
export const checkArray = (onClick, i) => {
	if (
		Array.isArray(onClick) &&
		onClick[i] !== undefined &&
		onClick[i].length > 0
	) {
		return true;
	} else {
		return false;
	}
};
const executer = (e, onClick) => {
	onClick.forEach((handler) => handler(e));
};

export const submitter = (e, onClick) => {
	executer(e, onClick);
};
export const toggle = (e, onClick) => {
	executer(e, onClick);
};

export const timeAndDate = () => {
	const datedetails = new Date().toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});

	const time = new Date().toLocaleTimeString("en-US", {
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: true,
	});
	return {
		time,
		datedetails,
	};
};

export const playSound = () => {
	// console.log("playing");
	const audio = new Audio(notify);
	var playedPromise = audio.play();
	if (playedPromise) {
		playedPromise
			.then(() => { })
			.catch((e) => {
				if (e.name === "NotAllowedError" || e.name === "NotSupportedError") {
				}
			});
	}
};
// Compare the stored and fetched coordinates with that help of distance
// and accuracy difference between them it will determine the refetch want to perform
export const determineRefetchReverseGeoCode = (data) => {
	const localStorageReversedGeoCodes = getItem("coordinates")
	const storedReversedGeoCodes = getItem("reversedCoordinates")
	let shouldRefetch;
	const {
		latitude: newLat,
		longitude: newLng,
		accuracy: newAcc,
	} = data?.coords;

	if (!localStorageReversedGeoCodes || !storedReversedGeoCodes) {
		shouldRefetch = true;
	} else {
		const tolerance = 0.004;
		const distance = Math.sqrt(
			Math.pow(newLat - (localStorageReversedGeoCodes?.lat || 0), 2) +
			Math.pow(newLng - (localStorageReversedGeoCodes?.lng || 0), 2)
		);
		const storedAcc = localStorageReversedGeoCodes?.acc || 0;
		// console.log(newAcc);
		//1.(!localStorageReversedGeoCodes && !storedReversedGeoCodes) :
		// 		if localStorageReversedGeoCodes and storedReversedGeoCodes not yet exist in localstorage then it will trigger a refetch
		//2.(distance < tolerance && newAcc < storedAcc) :
		// 		if the distance is less than the tolerance(approximately 220meters) and also the new accuracy(newAcc) is less than the oldAcc (storedAccuracy) then
		// 		it will refetch the location
		// 3.distance > 0.005 :
		// 		Also if the more than 0.005 (arroung 1km) it'll refetch the locaiton

		shouldRefetch =
			(!localStorageReversedGeoCodes || !storedReversedGeoCodes) ||
			(distance < tolerance && newAcc < storedAcc && storedAcc - newAcc > 250) ||
			distance > 0.005;
	}

	return { shouldRefetch, newLat, newLng, newAcc, storedReversedGeoCodes };
};

export const determineRefetchForGetCurrentPosition = () => {
	const localCoords = getItem("coordinates");
	const storedReversedGeoCodes = getItem("reversedCoordinates");

	//1.(!localStorageReversedGeoCodes && !storedReversedGeoCodes) :
	// 		if localStorageReversedGeoCodes and storedReversedGeoCodes not yet exist in localstorage then it will trigger a refetch

	const shouldRefetch = !localCoords || !storedReversedGeoCodes;

	return { shouldRefetch, localCoords, storedReversedGeoCodes };
};

// Call back for the getCurrentPosition method .
// 1.fetch the current location lat and lon cordinates
// 2.It will check the localstorge for the lcoation details that previously fetched
// 3.Based on determineRefetchReverseGeoCode comparison after either stored value
//   returned or new value fetched after stored it it will returneed
export const onsuccessiveGetCurrent = (resolve, reject, data) => {
	const { shouldRefetch, newLat, newLng, newAcc, storedReversedGeoCodes } =
		determineRefetchReverseGeoCode(data);
	// console.log("before refetchCoords from onsuccessiveGetCurrent", { shouldRefetch, newLat, newLng, newAcc });

	if (shouldRefetch) {
		// Refetch the location
		refetchCoords({
			"accept-language": "en",
			lat: newLat,
			lon: newLng,
		})
			.then(({ data }) => {
				// console.log(data, "from getCurrent ");
				// Update the stored coordinates in the local
				localStorage.setItem(
					"coordinates",
					JSON.stringify({ lat: newLat, lng: newLng, acc: newAcc })
				);
				localStorage.setItem("reversedCoordinates", JSON.stringify(data));
				resolve(data);
			})
			.catch((err) => reject(err));
	} else {
		// Reuse reverse geo coordinates
		// console.log(data, "from getCurrent local");

		resolve(storedReversedGeoCodes);
	}
};
// lcoation option for getCurrentPosition and watchPosition methods
export const locationOptions = {
	enableHighAccuracy: false,
	timeout: 10000,
};
// API options for fetching reverse geo codes details
const options = {
	method: "GET",
	url: "https://forward-reverse-geocoding.p.rapidapi.com/v1/reverse",

	headers: {
		"X-RapidAPI-Key": "c5133980b5msheec46c8f239d2d9p14e685jsn3c938eb5cdeb",
		"X-RapidAPI-Host": "forward-reverse-geocoding.p.rapidapi.com",
	},
};
const refetchCoords = (params) => {
	return axios.request({ ...options, params });
};

// It a callback function for geolocation.watchPosition method on successive fetch
// it will update the location in the localstorge if the lat and lon meets the required logic
// in the determineRefetchReverseGeoCode
export const onSuccessiveWatch = (data) => {
	const { shouldRefetch, newLat, newLng, newAcc } =
		determineRefetchReverseGeoCode(data);
	// console.log("before refetchCoords from onSuccessiveWatch", { shouldRefetch, newLat, newLng, newAcc });
	if (shouldRefetch) {
		// Refetch the location
		refetchCoords({
			"accept-language": "en",
			lat: newLat,
			lon: newLng,
		})
			.then(({ data }) => {
				// Update the stored coordinates in the local
				// console.log(data, "from watch ");

				localStorage.setItem(
					"coordinates",
					JSON.stringify({ lat: newLat, lng: newLng, acc: newAcc })
				);
				localStorage.setItem("reversedCoordinates", JSON.stringify(data));
				// console.log(data);
			})
			.catch((err) => {
				// console.log(err)
			});
	}
};
export const onErrorWatch = (err) => {
	// console.log(err);
};

// Return the proper error message based on the error code
// that happening while trying to fetch location details from the browser
export const geoErrorProvider = (err) => {
	switch (err.code) {
		case 1:
			return {
				message:
					"Kindly enable location permissions in your browser settings to proceed.",
				err,
			};

		case 2:
			return {
				message:
					"Unable to fetch location details,,Please reload the page try again.",
				err,
			};
		case 3:
			return {
				message:
					"Unable to fetch location details,Please reload the page and try again.",
				err,
			};

		default:
			return {
				message:
					"Please ensure proper location permission access, then reload the page and try again..",
				err,
			};
	}
};
export const funcDate = (neDate) => {
	let zz = moment(neDate).format("DD/MM/YYYY");
	return zz;
};

export const funcTime = (neDate) => {
	const dateObj = new Date(neDate);
	const hours = dateObj.getHours();
	const minutes = dateObj.getMinutes();
	const ampm = hours >= 12 ? "PM" : "AM";
	const twelveHours = hours % 12;
	const formattedTime =
		(twelveHours === 0 ? 12 : twelveHours) + ":" + minutes.toString().padStart(2, "0") + " " + ampm;
	return formattedTime;
};
export const checkDateAndTime = (date) => {
	const today = new Date();
	const inputDate = new Date(date);
	const isToday =
		inputDate.getDate() === today.getDate() &&
		inputDate.getMonth() === today.getMonth() &&
		inputDate.getFullYear() === today.getFullYear();

	if (isToday) {
		return funcTime(date);
	} else {
		return funcDate(date);
	}
};

export const nameFunc = (nameData) => {
	let text = nameData;
	let myDat = text.split("<");
	let dat = myDat[0];
	return dat;
};
export function funDate(params) {
	let update = moment(params).format("LT");

	const date = new Date(params);
	// eslint-disable-next-line
	var h = new Date(date).getHours();
	// eslint-disable-next-line
	var m = new Date(date).getMinutes();
	let zz = update;
	return zz;
}

export const dateAndTime = (dateString) => {
	const time = funDate(dateString);
	const dateObject = new Date(dateString);
	// Format the date in the user's local time
	const formattedDate = dateObject.toLocaleString();
	let date = formattedDate.split(",");
	return `${date[0]} , ${time} `;
};

export const url = (segment1) =>
	`${process.env.REACT_APP_API_URL}/user/${segment1}`;

export const setItem = (pairs) => {
	Object.entries(pairs).map((item) =>
		localStorage.setItem(item[0], JSON.stringify(item[1]))
	);
};
export const updateLocalUser = (pairs) => {
	let user = getItem('user');
	if (user) {
		user = { ...user, ...pairs }
		setItem({ user })
	}
}
export const getItem = (key) => {
	let value;
	try {
		value = JSON.parse(localStorage.getItem(key));
	} catch (error) {
		value = null;
	}
	return value;
};

export const clearStorage = () => {
	// Create a set for faster lookup of keys to preserve
	const preserveSet = new Set(['coordinates', 'reversedCoordinates']);

	// Get all keys in local storage
	const allKeys = Object.keys(localStorage);

	// Iterate through all keys
	allKeys.forEach(key => {
		// If the key is not in the preserve set, remove it
		if (!preserveSet.has(key)) {
			localStorage.removeItem(key);
		}
	});

};

export const removeItem = (key) => {
	localStorage.removeItem(key);
};

export function isObjectEmpty(obj) {
	return Object.keys(obj).length === 0;
}