import React, { useState, useEffect, useRef } from "react";
import "../asset/chatBox/resetpasswordoptions.css";
import swal from "sweetalert";
import apiCaller from "../util/apiCaller";
import AutoPasswordResetForAdmin from "./AutoPasswordResetForAdmin";
import Modal from "./Modal";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ResetPasswordFormForAdmin from "./ResetPasswordFormForAdmin";

function ResetPasswordForAdmin({
    handleCloseResetPasswordForm,
    handleResetPasswordToggle,
    adminUserId,
    getValuefromResetAdminPassword,
    adminName,
}) {
    const [selectedButton, setSelectedButton] = useState("button1");
    const paragraphRef = useRef(null);

    const handleRadioChange = async (value) => {
        setSelectedButton(value);
    };


    const manualGenerate = async (childValue) => {
        // console.log(childValue);
        const data = {
            _id: adminUserId,
            activity: "Password udpated",
            newpassword: childValue,
        };
        // console.log("object", data)
        try {
            const responseChat = await apiCaller({
                url: "updatePassword",
                data,
            });
            let displayData = responseChat.data.status;
            if (displayData === true) {
                swal({
                    title: "Success!",
                    text: "Admin Password Reset Successfully",
                    icon: "success",
                    button: false,
                    timer: 2000,
                });
            }
            handleCloseResetPasswordForm();
        } catch (error) {
            // console.log(error);
            swal({
                title: "Warning!",
                text: error.message,
                icon: "warning",
                button: false,
                timer: 2000,
            });
        }
    };



    // const handleChildValueChange = (data, closeData) => {
    //     setChildValue(data);
    //     // console.log("closeData", closeData)

    //     console.log("closeValue", closeValue);
    //     // console.log("data", data)
    //     getValuefromResetAdminPassword();
    //     handleCloseResetPasswordForm();

    //     setCloseValue(closeData);
    //     // console.log("object")
    //     if (data !== childValue) {
    //         // console.log("Data from child:", data, closeData);
    //         manualGenerate(data);
    //     }
    // };

    // useEffect(() => {
    //     // // console.log("dadadadadad", closeValue)
    //     // console.log("Child Value Pass", childValue)
    //     setTimeout(() => {
    //         setCopyContent(true);
    //     }, 7000);
    // }, [closeValue, copyConent, childValue]);



    useEffect(() => {
        const fetchData = async () => { };
        fetchData();
    }, [adminUserId, selectedButton]);





    return (
        <Modal size={selectedButton === "button2" ? 'modal_lg' : 'modal_md'}>
            <ModalHeader header={`Reset Password`} onClick={handleCloseResetPasswordForm} />
            <ModalBody>

                <div>
                    <div>
                        <div className="chart-flex-contain">
                            <label className="input_label">
                                <input
                                    type="radio"
                                    value="button1"
                                    checked={selectedButton === "button1"}
                                    id="radio-button1" // Use a unique id for each radio button
                                    name="radio-colors"
                                    onChange={() => handleRadioChange("button1")}
                                />
                                Auto Generate Password
                            </label>

                            <label className="input_label">
                                <input
                                    type="radio"
                                    value="button2"
                                    checked={selectedButton === "button2"}
                                    id="radio-button2" // Use a unique id for each radio button
                                    name="radio-colors"
                                    onChange={() => handleRadioChange("button2")}
                                />
                                Enter Manual Password
                            </label>
                        </div>

                        <div>
                            {selectedButton === "button1" && (
                                <AutoPasswordResetForAdmin
                                    manualGenerate={manualGenerate}
                                    handleCloseResetPasswordForm={handleCloseResetPasswordForm}
                                    adminName={adminName}
                                    paragraphRef={paragraphRef}
                                />
                            )}
                            {selectedButton === "button2" && (
                                <div className="password-generator">
                                    <div>
                                        <ResetPasswordFormForAdmin adminUserId={adminUserId} handleCloseResetPasswordForm={handleCloseResetPasswordForm} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ResetPasswordForAdmin;
