import React from "react";
import CustomAvatar from "../../../components/CustomAvatar";

const ListHeader = () => {
	return (
		<div className="member_heading">
			<div className="list_of_contact">
				<div className="client_circle">
					<CustomAvatar name="*" className="empty_asterik" />
				</div>

				<div className="member_info">
					<div className="uer_name_view">
						<span className="client_header">Name</span>
					</div>

					<div className="user_phone">
						<span className="client_header">Mobile</span>
					</div>

					<div className="user_email">
						<span className="client_header">Email</span>
					</div>
				</div>

				<div className="user_chatlist">
					<span className="client_header_Actions">Actions</span>
				</div>
			</div>
		</div>
	);
};

export default ListHeader;
