import React, { useState } from "react";
import useWindowSize from "../../../Hooks/useWindowSize";
import SMSList from "./SMSList";
import SMSDetailedView from "./SMSDetailedView";

const SMSContainer = ({ textNotify, newNotification, NoMail }) => {
	//Set message error for 2 states
	const [error, setError] = useState({ status: false, type: 0 });
	const size = useWindowSize();

	return (
		<div>
			{error.status ? (
				<>
					<div className="clientNotSync">
						<img
							src={NoMail}
							alt="Mail Not Synced"
							title="Mail not Synced"
							className="no_mail"
						/>
						{error.type === 0 && <p>Client SMS not synced...</p>}
						{error.type === 1 && <p>SMS data not found...</p>}
					</div>
				</>
			) : (
				<>
					<div className="dataSms">
						<>
							<SMSList
								setError={setError}
								size={size}
								newNotification={newNotification}
							/>
						</>

						<SMSDetailedView
							setError={setError}
							size={size}
							newNotification={newNotification}
							textNotify={textNotify}
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default SMSContainer;
