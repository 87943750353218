import React, { useState } from "react";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { Field, Form, Formik } from "formik";
import { forgetPassSchema } from "../validation/Validation";
import Buttons from "../components/Buttons";
import { CustomUseContext } from "../util/SharedFunctionsContext";
import apiCaller from "../util/apiCaller";

function ForgotPassword(props) {
	const { loading, setLoading } = CustomUseContext();
	const [initialFormValues] = useState({
		email: "",
	});

	const sendEmailForgotPassword = (data) => {
		setLoading(true);
		apiCaller({ url: "adminforgetpassword", data })
			.then((responseChat) => {
				let displayData = responseChat.data.status;
				if (displayData === true) {
					swal({
						title: "The password has been sent to your email address.",
						text: "You will receive an email for resetting your password",
						icon: "info",
						button: false,
						timer: 2000,
					});
					props.handleForgotPassword();
					setLoading(false);
				} else {
					if (data.email === "") {
						swal({
							title: "Alert!",
							text: "Kindly fill Your Email",
							icon: "warning",
							button: false,
							timer: 2000,
						});
						setLoading(false);
					} else {
						swal({
							title: "Email-Id is wrong!",
							text: "Kindly check your previous E-mail Id ",
							icon: "warning",
							button: false,
							timer: 2000,
						});
						setLoading(false);
					}
				}
			})
			.catch((error) => {
				swal({
					title: "Forget Password!",
					text: error.message,
					icon: "warning",
					button: false,
					timer: 2000,
				});
				setLoading(false);
			});
	};

	return (
		<>
			{/* ======================  Meta Tags ======================  */}
			<Helmet>
				<title>autoaudit.ai &#10072; Forget Password Page </title>
			</Helmet>
			{/* ======================  Meta Tags ======================  */}

			<div className="parent_div">
				{/* {loading && <Loader />} */}
				<Formik
					initialValues={initialFormValues}
					validationSchema={forgetPassSchema}
					onSubmit={(values) => {
						sendEmailForgotPassword(values);
					}}
				>
					{({ values, errors, touched }) => (
						<Form>
							<div className={"login_section"}>
								{" "}
								<div className="login_option">
									<p className="forgot_header">Did you forget the password? </p>
									<p className="forgot_para">
										Kindly provide your email address, and{" "}
										<span className="pointerOfForgotPass">
											we will send you instructions on resetting your password.
										</span>{" "}
									</p>

									<div>
										<label className="input_label" name="email">
											<span>
												Email <span style={{ color: "red" }}>*</span>
											</span>
										</label>
										<Field
											name="email"
											maxLength="60"
											className="input_type"
											autoComplete="off"
											// autoFocus={true}
										/>
										<p className="error">
											{errors.email && touched.email ? errors.email : ""}
										</p>
									</div>
								</div>
							</div>
							<div className="login_submit">
								<Buttons />
								<button type="submit" className="submit" disabled={loading}>
									Send Mail
								</button>
								<button
									type="submit"
									className="modal_btn btn_close"
									onClick={props.handleForgotPassword}
								>
									Cancel
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}

export default ForgotPassword;
